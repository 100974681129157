<template>
  <div class="container">
    <h2>Crear profesional</h2>
    <hr>
    <div
      class="main"
      style="text-align:center; width:100%;"
    >
      <table style="">
        <tr>
          <td>Email</td>
          <td>
            <input
              v-model="email"
              type="text"
            >
          </td>
        </tr>
        <tr>
          <td>Nombre</td>
          <td>
            <input
              v-model="nombre"
              type="text"
            >
          </td>
        </tr>
        <tr>
          <td>Apellido</td>
          <td>
            <input
              v-model="apellido"
              type="text"
            >
          </td>
        </tr>
        <tr>
          <td>Rut</td>
          <td>
            <input
              v-model="rut"
              type="text"
            >
          </td>
        </tr>
      </table>
      <br>
      <button
        type="button"
        class="btn btn-dark"
        @click="crear_profesional()"
      >
        <b-icon icon="plus-circle" /> Crear profesional
      </button>
    </div>
    {{ resultado }}
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';

export default {
  name: 'HelloWorld',
  data() {
    return {
      email:'',
      nombre:'',
      apellido:'',
      rut:'',

      resultado:'',

    }
  },
  methods:{
    ...mapActions(['loginAdmin']),
    crear_profesional(){
        axios.post(window.hostname+'/api/admin/crear_profesional', 
        {email:this.email, nombre:this.nombre, apellido:this.apellido, rut:this.rut})
        .then(response => 
        {
          this.resultado = 'Profesional Creado'
        })

        .catch(e=>{
          console.log(e.response)
          if (e.response.data.message)
            this.resultado = e.response.data.message           
        })
    }

  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'tipos', 'admin_datos']),
  }

}
</script>


<style scoped>
td{
  padding: 5px 5px 0px;
  text-align: left;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}

</style>

