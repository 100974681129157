import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Notifications from 'vue-notification'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Notifications) 

Vue.config.productionTip = false;

const ENV = {
  prestador : Number(process.env.VUE_APP_PRESTADOR_ID),
}

if (Number(process.env.VUE_APP_PRESTADOR_ID) == 1) {
  window.hostname = 'https://clinicgobackend.clinicgo.cl';
  ENV.primaryColor = '#1486e7';
  ENV.secondaryColor = '#69bfff';
  ENV.navBgColor = '#ffffff';
  ENV.getAsset = (asset) => { return require('@/assets/clinicgo/' + asset); }
} else if (Number(process.env.VUE_APP_PRESTADOR_ID) == 2) {
  window.hostname = 'https://mainque.backend.clinicgo.cl';
  ENV.primaryColor = '#4a29d3';
  ENV.secondaryColor = '#4a29d3';
  ENV.navBgColor = '#4a29d3';
  ENV.getAsset = (asset) => { return require('@/assets/mainque/' + asset); }
} else if (Number(process.env.VUE_APP_PRESTADOR_ID) == 3) {
  window.hostname = 'https://backend.netmed.clinicgo.cl';
  ENV.primaryColor = '#2791a6';
  ENV.secondaryColor = '#a3d9be';
  ENV.navBgColor = '#ffffff';
  ENV.getAsset = (asset) => { return require('@/assets/netmed/' + asset); }
} else if (Number(process.env.VUE_APP_PRESTADOR_ID) == 4) {
  window.hostname = 'https://clini.backend.clinicgo.cl';
  ENV.primaryColor = '#5b398e';
  ENV.secondaryColor = '#dd3488';
  ENV.navBgColor = '#ffffff';
  ENV.getAsset = (asset) => { return require('@/assets/clini/' + asset); }
} else if (Number(process.env.VUE_APP_PRESTADOR_ID) == 5) {
  window.hostname = 'https://willbloom.backend.clinicgo.cl';
  ENV.primaryColor = '#000000';
  ENV.secondaryColor = '#ffffff';
  ENV.navBgColor = '#ffffff';
  ENV.getAsset = (asset) => { return require('@/assets/willbloom/' + asset); }
} else if (Number(process.env.VUE_APP_PRESTADOR_ID) == 6) {
  window.hostname = 'https://clicsalud.backend.clinicgo.cl';
  ENV.primaryColor = '#00eaff';
  ENV.secondaryColor = '#c8faff';
  ENV.navBgColor = '#ffffff';
  ENV.getAsset = (asset) => { return require('@/assets/clicsalud/' + asset); }
} else if (Number(process.env.VUE_APP_PRESTADOR_ID) == 7) {
  window.hostname = 'https://reley.backend.clinicgo.cl';
  ENV.primaryColor = '#000000';
  ENV.secondaryColor = '#36a9e1';
  ENV.navBgColor = '#ffffff';
  ENV.getAsset = (asset) => { return require('@/assets/reley/' + asset); }
} else if (Number(process.env.VUE_APP_PRESTADOR_ID) == 8) {
  window.hostname = 'https://regonesi.backend.clinicgo.cl';
  ENV.primaryColor = '#008cd5';
  ENV.secondaryColor = '#bf0c0c';
  ENV.navBgColor = '#ffffff';
  ENV.getAsset = (asset) => { return require('@/assets/regonesi/' + asset); }
} else if (Number(process.env.VUE_APP_PRESTADOR_ID) == 9) {
  window.hostname = 'https://backend.integramedica.clinicgo.cl';
  ENV.primaryColor = '#ffffff';
  ENV.secondaryColor = '#0079c8';
  ENV.navBgColor = '#0079c8';
  ENV.getAsset = (asset) => { return require('@/assets/integramedica/' + asset); }
} else if (Number(process.env.VUE_APP_PRESTADOR_ID) == 10) {
  window.hostname = 'https://neomedica.backend.clinicgo.cl';
  ENV.primaryColor = '#3051a0';
  ENV.secondaryColor = '#9235b2';
  ENV.navBgColor = '#ffffff';
  ENV.getAsset = (asset) => { return require('@/assets/neomedica/' + asset); }
} else {
  window.hostname = 'http://localhost:8000';
  ENV.primaryColor = '#1486e7';
  ENV.secondaryColor = '#69bfff';
  ENV.navBgColor = '#ffffff';
  ENV.getAsset = (asset) => { return require('@/assets/clinicgo/' + asset); }
}

window.socket = 'https://socketserver.clinicgo.cl';

Vue.prototype.$ENV = ENV;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
