// 

const backendUrl = process.env.VUE_APP_BACKEND_URL;

function getHeaders() {
  return {
    'Accept': 'application/json',
    'Authorization':  localStorage.getItem('token_admin'),
    'Content-Type': 'application/json',
    'Prestador': Number(process.env.VUE_APP_PRESTADOR_ID),
    'Marketplace': false,
  }
}

function fetchData(path, method='GET', body={}) {
  return new Promise((resolve, reject) => {
    const options = {
      method: method,
      headers: getHeaders(),
    };

    if (body && Object.keys(body).length > 0) {
      options.body = JSON.stringify(body);
    }

    fetch(backendUrl + path, options)
      .then(response => {
        if (response.ok) {
          resolve(response.json());
        } else {
          reject(new Error('Network response was not OK.'));
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export default fetchData;
