import Vue from 'vue'
import Vuex from 'vuex'
import admin from './modules/admin';
import principal from './modules/principal';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    admin,
    principal,  
  }
})
