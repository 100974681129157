<template>
  <div class="container-fluid">
    <div v-if="loaded">
      <h2>Modificar Categorías <b-icon icon="card-text" /></h2>
  
      <div style="text-align:left; padding:7px;">
        <button
          class="btn btn-success"
          @click="agregar_categoria()"
        >
          Agregar Categoria <b-icon icon="plus-circle" />
        </button>
      </div>
    
      <table style="width:100%;">
        <tr style="height:40px; background-color:black; color:white;">
          <td style="text-align:center;">
            Id
          </td>
          <td>Nombre</td>
          <td style="text-align:center; border-right:1px solid black;">
            Editar
          </td>
        </tr>
        <tr
          v-for="e in editando_categorias"
          style="height:40px; background-color:white;"
        >
          <template v-if="!e.modificar">
            <td style="text-align:center; border-left:1px solid black;">
              {{ e.id }}
            </td>
            <td style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width:400px;">
              {{ e.nombre }}
            </td>
            <td style="text-align:center; border-right:1px solid black;">
              <span
                style="color:blue; cursor:pointer; margin-right:6px;"
                @click="e.modificar = true"
              ><b-icon icon="pen" /></span>  
              <span
                style="color:red; cursor:pointer;"
                @click="remover_categoria(e.id)"
              ><b-icon icon="trash" /></span>  
            </td>
          </template>

          <template v-else>
            <td style="border-left:1px solid black; text-align:center;">
              {{ e.id }}
            </td>
            <td style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width:400px;">
              <input
                v-model="e.enombre"
                type="text"
              >
            </td>
            <td style="text-align:center; border-right:1px solid black;">
              <span @click="e.modificar = false; modificar(e.id);"> <b-icon
                style="color:green; cursor:pointer;"
                icon="check-circle"
              /></span>
            </td>
          </template>
        </tr>
        <tr style="height:20px; background-color:black; color:white;">
          <td style="text-align:center;" />
          <td style="text-align:center;" />
          <td style="text-align:center; border-right:1px solid black;" />
        </tr>
      </table>
      <div style="text-align:left; margin-top:10px;">
        <button
          class="btn btn-success"
          @click="agregar_categoria()"
        >
          Agregar Categoria <b-icon icon="plus-circle" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'


export default {
  name: 'HelloWorld',
  data() {
    return {

      loaded:false,
      editando_categorias:[],
      categorias:[],

    }
  },
  created(){
  
    this.initialize_();
  },

  methods:{
    ...mapActions(['initialize']),


  remover_categoria(id){
    axios.post(window.hostname+'/api/admin/remove_categoria',{id:id})
        .then(response => 
        {
          this.initialize().then( ()=>{
          this.initialize_();
          })
          
        })

        .catch(e=>{
            console.log("Error al cargar...")
        })
  },
  agregar_categoria(){
    axios.post(window.hostname+'/api/admin/add_categoria')
        .then(response => 
        {
          this.initialize().then( ()=>{
          this.initialize_();
          })
          
        })

        .catch(e=>{
            console.log("Error al cargar...")
        })
  },

  modificar(id){
    let data = this.editando_categorias.filter(x=>{return x.id == id})[0]
      axios.post(window.hostname+'/api/admin/modificar_categoria', 
        {id: id,
        nombre:data.enombre,
        })
        .then(response => 
        {
          this.initialize().then( ()=>{
          this.initialize_();
          })
          
        })

        .catch(e=>{
            this.loaded = true;
            console.log("Error al cargar...")
        })

  },
    
  initialize_(){
      this.loaded = false;
      axios.get(window.hostname+'/api/admin/init_modificar_examenes')
      .then(response => 
      {

          this.editando_categorias = []
          this.categorias = response.data.categorias
          for(var i = 0; i < this.categorias.length; i++){
            this.editando_categorias.push({

                nombre:this.categorias[i].nombre,
                id:this.categorias[i].id,

                
                enombre:this.categorias[i].nombre,
                eid:this.categorias[i].id,


                modificar:false,
            })
            
          }
          this.loaded = true;
      })

      .catch(e=>{
          this.loaded = true;
          console.log("Error al cargar...!")
      })

  },


  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'examenes']),
  },
  watch:{
    comunas_buscadas(val){
      this.resultado_fuse_comuna = this.fComunas.search(val)

    },
    examenes(){
      this.initialize_()
    }
  },
  mounted() {
    this.fComunas = new Fuse(this.comunas, {  keys: ['Nombre']})
  }

}
</script>


<style scoped>
td{
  text-align: left;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}

</style>

