<template>
  <div class="container-fluid">
    <div v-if="loaded">
      <div v-if="modificando_encuesta == -1">
        <h2> Panel de Encuestas <b-icon icon="card-text" /></h2>
        <div style="text-align:left; padding:7px;">
          <router-link
            tag="button"
            to="/CrearEncuesta"
            style="margin-right:4px;"
            class="btn btn-dark"
          >
            Agregar Encuesta <b-icon icon="plus-circle" />
          </router-link>
        </div>
    
        <table style="width:100%;">
          <tr style="height:40px; background-color:black; color:white;">
            <td>Encuesta</td>
            <td>Descripción</td>
            <td style="text-align:center; border-right:1px solid black;">
              Editar
            </td>
          </tr>
          <tr
            v-for="encuesta in encuestas"
            :key="'A'+encuesta.id"
            style="height:40px; background-color:white;"
          >
            <td style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width:400px;">
              {{ encuesta.titulo }}
            </td>
            <td style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width:400px;">
              {{ encuesta.descripcion }}
            </td>
            <td style="text-align:center; border-right:1px solid black;">
              <span
                style="color:blue; cursor:pointer; margin-right:6px;"
                @click="modificar_encuesta(encuesta.id)"
              ><b-icon icon="pen" /></span>  
              <span
                style="color:red; cursor:pointer;"
                @click="eliminar_encuesta(encuesta.id)"
              ><b-icon icon="trash" /></span>  
            </td>
          </tr>
      
          <tr style="height:20px; background-color:black; color:white;">
            <td style="text-align:center;" />
            <td style="text-align:center;" />
            <td style="text-align:center;" />
            <td style="text-align:center; border-right:1px solid black;" />
          </tr>
        </table>


        <div v-if="eventos_cargados == true">
          <div style="margin:25px;" />
          <h2> Eventos Encuestas <b-icon icon="card-text" /></h2>
          <table style="width:100%;">
            <tr style="height:40px; background-color:black; color:white;">
              <td>Evento</td>
              <td>Encuesta Asociada</td>
              <td style="text-align:center; border-right:1px solid black;">
                Editar
              </td>
            </tr>

            <tr
              v-for="evento in eventos"
              :key="'A'+evento.id"
              style="height:40px; background-color:white;"
            >
              <td style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width:400px;">
                {{ evento.titulo }}
              </td>
              <td
                v-if="editando_eventos[evento.id].state == true"
                style=" text-overflow: ellipsis; white-space: nowrap; max-width:400px;"
              > 
                <select v-model="editando_eventos[evento.id].after">
                  <option
                    :value="null"
                    :selected="editando_eventos[evento.id].after == null"
                  >
                    Sin encuesta asociada
                  </option>
                  <option 
                    v-for="encuesta in encuestas"
                    :key="'option'+encuesta.id"
                    :value="encuesta.id"
                    :selected="editando_eventos[evento.id].after == encuesta.id"
                  >
                    {{ encuesta.titulo }}
                  </option>
                </select>
              </td>
              <td
                v-if="editando_eventos[evento.id].state == true"
                style="text-align:center; border-right:1px solid black;"
              >
                <span
                  style="color:green; cursor:pointer; margin-right:6px;"
                  @click="modificar_evento(evento.id)"
                ><b-icon icon="check-circle" /></span>
              </td>
        
              <td
                v-if="editando_eventos[evento.id].state == false"
                style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width:400px;"
              > 
                <p>{{ evento.encuesta_id ? encuestas[evento.encuesta_id].titulo : "Sin encuesta asociada" }}</p>
              </td>
              <td
                v-if="editando_eventos[evento.id].state == false"
                style="text-align:center; border-right:1px solid black;"
              >
                <span
                  style="color:blue; cursor:pointer; margin-right:6px;"
                  @click="modificar_evento(evento.id)"
                ><b-icon icon="pen" /></span>
              </td>
            </tr>
        
            <tr style="height:20px; background-color:black; color:white;">
              <td style="text-align:center;" />
              <td style="text-align:center;" />
              <td style="text-align:center;" />
              <td style="text-align:center; border-right:1px solid black;" />
            </tr>
          </table>

          <div style="margin:100px;" />
        </div>
      </div>

      <div v-if="modificando_encuesta >= 0">
        <h2> Modificando Encuesta <b-icon icon="card-text" /></h2>




        <div
          v-if="loaded"
          class="container"
          style="text-align:left; padding-bottom:120px; "
        >
          <button
            class="btn btn-dark"
            style="margin-right: 6px;"
            @click="volver_al_panel()"
          >
            <b-icon icon="dash-circle" /> Volver al panel
          </button>
          <span style="float:right; margin-right:10px;">
            <button
              class="btn btn-success"
              style="margin-bottom: 20px;"
              @click="guardar_nueva_version()"
            > <b-icon icon="check-square" /> Guardar Nueva Versión <b-icon icon="check-square" /> </button>
          </span>

          <div class="data-box">
            <div
              class="input_largo"
              style="padding-right:7px;"
            >
              Titulo Encuesta <input
                id=""
                v-model="encuesta.titulo"
                placeholder="Escribe el titulo"
                class="form-control"
                type="text"
                name=""
                @focus="focused_titulo = true;"
                @blur="focused_titulo = false"
                @input="encuesta.titulo = $event.target.value"
              >
            </div>
        
            <div class="input_largo">
              Texto Inicial Encuesta <input
                id=""
                v-model="encuesta.texto_inicial"
                placeholder="Escribe una descripción"
                class="form-control"
                type="text"
                name=""
                @focus="focused_texto_inicial = true;"
                @blur="focused_texto_inicial = false"
                @input="encuesta.texto_inicial = $event.target.value"
              >
            </div>

            <div class="input_largo">
              Descripción Panel Admin <input
                id=""
                v-model="encuesta.descripcion"
                placeholder="Escribe una descripción"
                class="form-control"
                type="text"
                name=""
                @focus="focused_descripcion = true;"
                @blur="focused_descripcion = false"
                @input="encuesta.descripcion = $event.target.value"
              >
            </div>
          </div>

          <div class="data-box">
            <div
              class="input_largo"
              style="padding-right:7px;"
            >
              Titulo Correo <input
                id=""
                v-model="encuesta.titulo_correo"
                placeholder="Escribe el titulo del correo"
                class="form-control"
                type="text"
                name=""
                @focus="focused_titulo_correo = true;"
                @blur="focused_titulo_correo = false"
                @input="encuesta.titulo_correo = $event.target.value"
              >
            </div>
          
            <div class="input_largo">
              Texto Correo <input
                id=""
                v-model="encuesta.texto_correo"
                placeholder="Hola 'CLIENTE', TEXTO"
                class="form-control"
                type="text"
                name=""
                @focus="focused_texto_correo = true;"
                @blur="focused_texto_correo = false"
                @input="encuesta.texto_correo = $event.target.value"
              >
            </div>
          </div>


          <div
            v-for="(pregunta, index) in encuesta.preguntas"
            :key="'B'+pregunta.id+index"
            class="data-box"
          >
            <div style="width:100%; border-bottom:1px solid rgb(214, 214, 214);  padding:15px 15px 15px 30px; background:white;"> 
              <b-icon icon="card-text" /> {{ 'Pregunta '+ (pregunta.posicion+1) + ' - Tipo ' + pregunta.tipo }}

              <span style="float:right; margin-right:10px;">
                <button
                  class="btn btn-danger"
                  style="margin-right: 6px;"
                  @click="remover_pregunta(pregunta)"
                > <b-icon icon="dash-circle" /> Eliminar Pregunta </button>
              </span>

              <span
                v-if="pregunta.tipo == 'alternativa'"
                style="float:right; margin-right:10px;"
              >
                <button
                  class="btn btn-success"
                  style="margin-right: 6px;"
                  @click="agregar_opcion_alternativa(pregunta)"
                > <b-icon icon="plus-circle" /> Opción </button>
                <button
                  class="btn btn-danger"
                  style="margin-right: 200px;"
                  @click="remover_ultima_opcion_alternativa(pregunta)"
                > <b-icon icon="dash-circle" /> Última Opción </button>
              </span>
            </div>

            <div
              class="input_largo"
              style="margin-top:5px; padding-right:7px;"
            >
              <input
                id=""
                v-model="pregunta.titulo"
                placeholder="Escribe la pregunta"
                class="form-control"
                type="text"
                name=""
                @focus="pregunta.focused = true;"
                @blur="pregunta.focused = false"
                @input="pregunta.titulo = $event.target.value"
              >
            </div>

            <div v-if="pregunta.tipo == 'alternativa'">
              <div
                v-for="(opcion, index) in pregunta.opciones"
                :key="'C'+opcion.id"
                class="input-49"
                style="padding:4px; display:inline-block; font-size:13px; padding-right:7px; margin-top:0px; padding-top:0px;"
              >
                Alternativa {{ index+1 }} <input
                  id=""
                  v-model="opcion.titulo"
                  placeholder="Escribe la alternativa"
                  class="form-control"
                  type="text"
                  name=""
                  @focus="opcion.focused = true;"
                  @blur="opcion.focused = false"
                  @input="opcion.titulo = $event.target.value"
                >
              </div>
            </div>
          </div>


          <div style="text-align:left; margin-top:10px;">
            <button
              class="btn btn-success"
              style="margin-right: 6px;"
              @click="agregar_pregunta_puntaje()"
            >
              <b-icon icon="plus-circle" /> Pregunta Puntaje
            </button>
            <button
              class="btn btn-success"
              style="margin-right: 6px;"
              @click="agregar_pregunta_texto()"
            >
              <b-icon icon="plus-circle" /> Pregunta Texto
            </button>
            <button
              class="btn btn-success"
              style="margin-right: 6px;"
              @click="agregar_pregunta_alternativa()"
            >
              <b-icon icon="plus-circle" /> Pregunta Alternativa
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default {
  name: 'ModificarEncuestas',
  data() {
    return {
      
      modificando_encuesta: -1,
      loaded:false,

      encuestas: [],
      encuesta: null,

      eventos: null,
      eventos_cargados: false,
      editando_eventos: {},

      focused_titulo: false,
      focused_descripcion: false,

    }
  },
  created(){
  
    this.initialize_();
  },

  methods:{
    ...mapActions(['initialize']),

    initialize_(){
      this.loaded = false;
      this.cargar_encuestas();
      this.cargar_eventos();
    },

    cargar_encuestas() {
      axios.get(window.hostname+'/encuestas/')
      .then((response) => {
          this.encuestas = response.data.encuestas
          this.loaded = true;
          })
      .catch((error)=>{
        console.log("Error en cargar_encuestas()...!")
        console.log(error);
        alert(error);
        this.loaded = true;
      });
    },

    cargar_eventos() {
      axios.get(window.hostname+'/encuestas/eventos')
      .then((response) => {
          this.eventos = response.data.eventos;
          })
      .then((response) => {
        Object.keys(this.eventos).forEach(key => {
          this.editando_eventos[key] = {
            state: false,
            before: this.eventos[key].encuesta_id,
            after: this.eventos[key].encuesta_id,
            };
          this.eventos_cargados = true;
        });
      })
      .catch((error)=>{
          console.log("Error en cargar_eventos()...!")
          console.log(error);
          alert(error);
      });
    },

    modificar_encuesta(id){
      this.focused_titulo = false,
      this.focused_descripcion = false,
      
      axios.get(
        window.hostname+'/encuestas/por_id',
        { params: { id_encuesta: id } }
      )
      .then((response) => {
        this.encuesta = response.data.encuestas[id];
        this.encuesta.preguntas.forEach(pregunta => {
          pregunta["focused"] = false;
          if (pregunta["tipo"] == 'alternativa') {
            pregunta.opciones.forEach(opcion => {
              opcion["focused"] = false;
            });
          }
        });
        this.modificando_encuesta = id;
      })
      .catch((e)=>{
        console.log("Error al cargar...!");
        console.log(e);
      })
    },


    guardar_nueva_version(){
      axios.put(window.hostname+'/encuestas/', this.encuesta)
      .then( (response) => {
        this.volver_al_panel();
      })
      .catch( (error) => {
        console.log("Error en guardar_nueva_version()...")
        console.log(error)
        alert(error);
      })
    },

    volver_al_panel(){
      this.loaded = false
      this.modificando_encuesta= -1,
      this.encuestas= [],
      this.encuesta= null,
      this.focused_titulo= false,
      this.focused_descripcion= false,
      this.initialize_()
    },

    agregar_pregunta_puntaje(){
      let pregunta = {
        tipo: 'puntaje',
        titulo: '',
        posicion: this.encuesta.preguntas.length,
        focused: false,
      }
      this.encuesta.preguntas.push(pregunta);
    },

    agregar_pregunta_texto(){
      let pregunta = {
        tipo: 'texto',
        titulo: '',
        posicion: this.encuesta.preguntas.length,
        focused: false,
      }
      this.encuesta.preguntas.push(pregunta);
    },

    agregar_pregunta_alternativa(){
      let pregunta = {
        tipo: 'alternativa',
        titulo: '',
        posicion: this.encuesta.preguntas.length,
        opciones: [],
        focused: false,
      }
      this.encuesta.preguntas.push(pregunta);
    },

    remover_pregunta(pregunta){
      this.encuesta.preguntas.splice(pregunta.posicion, 1);
      this.encuesta.preguntas.forEach((pregunta, index) => {
        pregunta.posicion = index;
      });
    },

    agregar_opcion_alternativa(pregunta){
      let opcion = {
        titulo: '',
        focused: false,
      }
      pregunta.opciones.push(opcion)
    },

    remover_ultima_opcion_alternativa(pregunta){
      pregunta.opciones.pop();
    },

    eliminar_encuesta(id_encuesta){
      axios.delete(
        window.hostname+'/encuestas/por_id',
        { params: { id_encuesta: id_encuesta } }
      )
      .then((response) => {
        this.volver_al_panel();
      })
      .catch((e)=>{
        console.log("Error al cargar...!");
        console.log(e);
      })
    },

    modificar_evento(id_evento){
      if ((this.editando_eventos[id_evento].state == true) && 
        (this.editando_eventos[id_evento].before != this.editando_eventos[id_evento].after)) 
      {
        let data = {
          id_evento: id_evento,
          id_encuesta: this.editando_eventos[id_evento].after
        }
        axios.put(window.hostname+'/encuestas/eventos', data)
        .then( (response) => {
          this.refresh_view();
        })
        .catch( (error) => {
          console.log("Error en modificar_evento()...")
          console.log(error)
          alert(error);
        })
      }
      this.editando_eventos[id_evento].state = !this.editando_eventos[id_evento].state;
      this.eventos_cargados = false;
      this.eventos_cargados = true;
    },

    refresh_view(){
      if (this.loaded) {
        this.loaded = false;
        this.eventos_cargados = false;
        this.initialize_();
      }
    },

    metodo_desactivado(){
      console.log("HOLA, METODO DESACTIVADO");
    },

  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'examenes']),
  },

  watch:{
    examenes(){
      this.initialize_()
    }
  }

}
</script>


<style scoped>

td{
  text-align: left;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}

select{
  background-color: white !important;
}


.selector {
  background-color:white;
  width: 130px;
  padding: 6px 6px;
  display: inline-block !important;
  margin: 0 10px 0 0px;
  box-sizing: border-box;
  border-radius: 5px;
  font-size:16px;
  border:1px solid rgba(0,0,0,0.2);
  outline: none;
  color:#111111;
}

.dir1, .dir2, .dir3{
  display:inline-block;
  margin-right:40px;
  margin-top:0px !important;
}
.btn-volver{
  border: 2px solid rgb(254, 177, 53);
  color: rgb(254, 177, 53);
}
.btn-volver:hover{
  border: 2px solid rgb(254, 177, 53);
  color: rgb(254, 177, 53);
}
.btn-bien{
background-color: rgb(254, 177, 53);

}
.btn-bien:hover{
color:white;

}
.btn-mal{
  background-color: rgb(255,222,166);
  color:white;
}
.btn-mal:hover{
  color:white;
  
}
.glow {
  color: #fff;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
  animation-iteration-count: 5;
}

@keyframes glow {
  from {
    box-shadow: 0 0 20px rgb(254, 177, 53), 0 0 20px rgb(254, 177, 53), 0 0 30px rgb(254, 177, 53), 0 0 40px rgb(254, 177, 53), 0 0 50px rgb(254, 177, 53), 0 0 60px rgb(254, 177, 53), 0 0 70px rgb(254, 177, 53);
  }
  to {
    box-shadow: 0 0 10px rgb(254, 177, 53), 0 0 30px rgb(254, 177, 53), 0 0 40px rgb(254, 177, 53) 0 0 50px rgb(254, 177, 53), 0 0 60px rgb(254, 177, 53), 0 0 70px rgb(254, 177, 53), 0 0 80px rgb(254, 177, 53);
  }
}
.spa{
  margin-top:100px;
}
.listado-buscando-container{
  z-index:9997; left:20px; top:35px; width:Min(400px, Calc(100vw - 100px)); position:absolute; background:white; border: 1px solid rgba(20, 134, 231, 1); margin-top:12px; border-radius:20px;padding:15px 0px 10px 0px;
}
.listado-buscando{
   position:relative; cursor:pointer; margin:0 auto; width:100%; text-align:left; padding:5px 10px 5px 10px;  
}
.examen-paciente-container{
border: 1px solid rgb(214, 214, 214); padding-right:40px; border-radius:10px; margin-top:40px; margin-bottom:40px; background:rgba(20, 134, 231, 0.04);
}
.buscar-examen-icon{
  position:absolute; top:10px; right:-5px;
}
.exam-list{
  padding:8px 20px;
}
.input-buscar-examen{
  margin-left:20px; margin-right:20px; border-radius:20x;
}
.btn-agregar-examen{
  border:2px solid rgb(255, 200, 9); 
  border-radius:10px; 
  padding:10px; 
  margin-top:10px; 
  width:200px;
  background:rgb(255, 200, 9);
  color:white; 
  cursor:pointer;
  font-weight: 700;
  margin-bottom:10px;
}
.btn-agregar-examen:hover{
  border:2px solid rgb(255, 200, 9); 
  border-radius:10px; 
  padding:10px; 
  margin-top:10px; 
  width:200px;
  background:white;
  color:rgb(255, 200, 9); 
  cursor:pointer;
  font-weight: 700;
}

.btn-agregar-examen-inverso2{

  border:2px solid rgba(20, 134, 231, 1); 
  border-radius:10px; 
  padding:10px; 
  margin-top:10px; 
  width:190px;
  background:white;
  color:rgba(20, 134, 231, 1);
  cursor:pointer;
  font-weight: 700;


}
.btn-agregar-examen-inverso2:hover{
  border:2px solid rgba(20, 134, 231, 1);
  border-radius:10px; 
  padding:10px; 
  margin-top:10px; 
  width:200px;
  background:rgba(20, 134, 231, 1);
  color:white; 
  cursor:pointer;
  font-weight: 700;
  margin-bottom:10px;
}
.btn-agregar-examen-inverso{

  border:2px solid rgb(108, 193, 252); 
  border-radius:10px; 
  padding:10px; 
  margin-top:10px; 
  width:190px;
  background:white;
  color:rgb(108, 193, 252);
  cursor:pointer;
  font-weight: 700;


}
.btn-agregar-examen-inverso:hover{
  border:2px solid rgb(108, 193, 252);
  border-radius:10px; 
  padding:10px; 
  margin-top:10px; 
  width:200px;
  background:rgb(108, 193, 252);
  color:white; 
  cursor:pointer;
  font-weight: 700;
  margin-bottom:10px;
}
.opcionClicked{
  border:2px solid rgba(20, 134, 231, 1); 
  color:white;
  background: rgba(20, 134, 231, 1);
}
.opcionClicked:hover{
  color:white;

}
.opcionNoClicked{
  border:2px solid rgba(20, 134, 231, 1); 
  color:rgba(20, 134, 231, 1);
}
.opcionNoClicked:hover{
  border:2px solid rgba(20, 134, 231, 1); 
  color:white;
  background: rgba(20, 134, 231, 1);
}
.parent {
  overflow: hidden; /* required */
  width: 50%; /* for demo only */
  height: 250px /* some non-zero number */;
  margin: 25px auto; /* for demo only */
  border:1px solid grey; /* for demo only */
  position: relative; /* required  for demo*/
}

.ribbon {
  margin: 0;
  padding: 0;
  background: rgba(20, 134, 231, 1);
  color:white;
  padding:1em 0;
  position: absolute;
  top:0;
  right:0;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  z-index: 99;
  transform-origin: top left;
}

.data-box{
  margin-bottom:20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color:white;
}

.input_corto, .nombre, .apellido1, .rut, .titulo{
  width:33%
}

.input-49, .descripcion{
  width:49.5%
}

.input_mediano, .descripcion{
  width:66%
}

.input_largo{
  width:99%;
  display:inline-block;
  font-size:13px;
  padding:4px;
  margin-top:0px;
  padding-top:0px;
}

.ribbon:before,
.ribbon:after {
  content: '';
  position: absolute;
  top:0;
  margin: 0 -1px; /* tweak */
  width: 100%;
  height: 100%;
  background: rgba(20, 134, 231, 1);
}
.ribbon:before {
  right:100%;
}
.alerta{
  padding:10px;
  background-color: #dc3545;
  color:white;
  position:absolute;
  
  right:3px;
  font-weight: 700;
  top: -41px;
  font-size: 12px;
  transition-duration: 0.2s;
}
.alerta:after{

  content:'';
  right:30px;
  top:33px;
  width: 10px;
  height: 10px;
  background: #dc3545;
  border-right: 1px solid #dc3545;
  border-bottom: 1px solid #dc3545;
  transform: rotate(45deg);
  position: absolute;
}

.ribbon:after {
  left:100%;
}
.bottom-spacer{
  height:0px;
}
.top-spacer{
  height:130px;
}
.carta{
  overflow: hidden; 
  position:relative; 
  background:white; 
  border:1px solid rgb(220, 220, 220); 
  padding: 40px; 
  border-radius:5px; 
  box-shadow:rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
@media only screen and (max-width: 540px) {
.nombre, .apellido1, .apellido2, .rut, .email, .telefono, .isapre, .fechan{
  width: 100% !important;
  margin:0px;
  margin-top:10px;
  padding:0px !important;
  display: block !important;;
}
.carta{
  padding: 10px; 
}
.isapre{
  margin-bottom: 20px;
}
.telefono, .apellido1, .rut{
  padding:0px !important;
}
.nombre, .apellido2, .apellido1{
  margin-bottom: 10px;
}

.ribbon {
  padding:4px 0;

  transform: translateX(40%) translateY(0%) rotate(45deg);

}




}
.main-c{
  padding:20px 30px;
}
@media only screen and (max-width: 1200px) {
  .top-spacer{
  height:85px;
}
.spa{
  margin-top:55px;

}
.bottom-spacer{
  height:60px;
}




}

@media (max-width: 576px) { 
  
  .main-c{
  padding:20px 10px;
}

.buscar-examen-icon{
  position:absolute; top:10px; right:-5px;
}
.exam-list{
  padding:8px 10px;
}
.input-buscar-examen{
  margin-left:10px; 
  margin-right:10px;
  width:100%;
  border-radius:10x;
}
.examen-paciente-container{
  padding-right:20px;
}
.listado-buscando{
   position:relative; cursor:pointer; margin:0 auto; width:100%; text-align:left; padding:5px 10px 5px 10px; 
}


.listado-buscando-container{
  z-index:9997; left:10px; top:35px; width: Min(400px, Calc(100% + 0px)); position:absolute; background:white; border: 1px solid rgba(20, 134, 231, 1); margin-top:12px; border-radius:20px;padding:15px 0px 10px 0px;
}

}

@media (max-width: 768px) { 
  
 }

@media (max-width: 992px) { 
  .dir1, .dir2, .dir3{
  display:block;
  margin-right:40px;
  margin-top:20px !important;
}
 }

@media (min-width: 1200px) { 
  
 }

@media (min-width: 1400px) { 
  
 }

@media (min-width: 576px) { 
.btn-volver{
  width:180px !important;
}
.btn-bien{
  width:180px !important;
}
.btn-mal{
  width:180px !important;
}
}


@media (min-width: 768px) { 
.btn-volver{
  width:240px !important;
}
.btn-bien{
  width:240px !important;
}
.btn-mal{
  width:240px !important;
}
 }


@media (min-width: 992px) { 
.btn-volver{
  width:240px !important;
}
.btn-bien{
  width:240px !important;
}
.btn-mal{
  width:240px !important;
}
 }


@media (min-width: 1200px) {
.btn-volver{
  width:240px !important;
}
.btn-bien{
  width:240px !important;
}
.btn-mal{
  width:240px !important;
}

}


@media (min-width: 1400px) { 
.btn-volver{
  width:240px !important;
}
.btn-bien{
  width:240px !important;
}
.btn-mal{
  width:240px !important;
}
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black !important;
  opacity: 0.25 !important; /* Firefox */
}

</style>