<script>
import { Pie, mixins } from "vue-chartjs";
export default {
  extends: Pie,
  props: ["data", "options"],
  watch: {


  },
  mounted() {

    this.renderChart(this.data, this.options);
  }
};

</script>