<template>
  <div class="container-fluid">
    <div v-if="loaded">
      <h2>Modificar Descuentos <b-icon icon="card-text" /></h2>


      <table style="width:100%;">
        <tr>
          <td />
          <td v-for="a in aseguradoras">
            {{ a.nombre }}

            <div>
              <select
                id=""
                name=""
                @change="(e)=>{editar_isapre_porcentaje(a.id, e.target.options.selectedIndex)}"
              >
                <option
                  v-for="x in 11"
                  :value="(x-1)*10"
                >
                  {{ (x-1)*10 }}%
                </option>
              </select>
            </div>
          </td>
        </tr>
        <tr v-for="ase, i in editando_descuentos">
          <td style="width:20%;">
            {{ examenes[i].nombre }} (${{ Intl.NumberFormat('de-DE').format(examenes[i].precio) }})
            <b-icon
              v-if="!editando[i]"
              style="cursor:pointer; color:blue;"
              icon="pen"
              @click="cambiarEditando(i)"
            />
            <b-icon
              v-if="editando[i]"
              style="cursor:pointer; color:red;"
              icon="x-circle"
              @click="cambiarEditando(i)"
            />
            <b-icon
              v-if="editando[i]"
              style="cursor:pointer; color:green "
              icon="check-circle"
              @click="editar_descuento(); cambiarEditando(i);"
            />
          </td>
          <td v-for="ex, j in ase">
            <input
              v-if="editando[i]"
              v-model="editando_linea[j].valor"
              style="width:70px;"
              type="text"
            > <span v-else>{{ editando_descuentos[i][j].valor }}</span>
          </td>
        </tr>
      </table>
      <div style="text-align:left; margin-top:10px;" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'


export default {
  name: 'HelloWorld',
  data() {
    return {

      loaded:false,
      editando_descuentos:[],
      modificados:[],
      editando:[],
      editando_linea:[],

    }
  },
  created(){
  
    this.initialize_();
  },

  methods:{
    ...mapActions(['initialize']),

      editar_descuento(){
    axios.post(window.hostname+'/api/admin/editar_descuentos',{descuentos:this.editando_linea})
        .then(response => 
        {
          this.initialize().then(()=>{
          this.initialize_();
          })
          
        })

        .catch(e=>{
            console.log("Error al cargar...")
        })
        
  },

  editar_isapre_porcentaje(ase_id, porcentaje)
  {
    let edit = []
    for(var i = 0; i < this.examenes.length; i++){
      edit.push({examen_id:this.examenes[i].id, aseguradora_id:ase_id, valor:Math.floor((porcentaje/10) * this.examenes[i].precio)})
    }


    axios.post(window.hostname+'/api/admin/editar_descuentos',{descuentos:edit})
    .then(response => 
    {
      this.initialize().then(()=>{
      this.initialize_();
      
      })
      
    })

    .catch(e=>{
        console.log("Error al cargar...")
    })
        
  },
  cambiarEditando(i){
    this.editando_linea = Array.from(this.editando_descuentos[i])
    this.editando[i] = !this.editando[i]

    this.$forceUpdate()
  },




    
  initialize_(){
      this.loaded = false;
      let row = []
      let col = []
      this.editando = []
      this.editando_descuentos = []
      /*
      for (var a of this.aseguradoras)
      {
        col = []
        for(var e of this.examenes){
          col.push({examen_id: e.id, aseguradora_id: a.id, valor:this.descuentos.find(z=>{return z.examen_id == e.id && z.aseguradora_id == a.id}) || 0})
        }
        row.push(col)
      }
      */
        for(var e of this.examenes){
          col = []
          for (var a of this.aseguradoras)
            {
              col.push(this.descuentos.find(z=>{return z.examen_id == e.id && z.aseguradora_id == a.id}) || {examen_id: e.id, aseguradora_id: a.id, valor:0})
            }
           row.push(col)
       }
      for(let z = 0; z < this.examenes.length; z++)
        this.editando.push(false)

      this.editando_descuentos = row
      this.loaded = true;
      this.$forceUpdate()

  },


  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'examenes', 'aseguradoras', 'descuentos']),
  },
  watch:{

  },
  mounted() {
    this.fComunas = new Fuse(this.comunas, {  keys: ['Nombre']})
  }

}
</script>


<style scoped>
td{
  text-align: left;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}

</style>

