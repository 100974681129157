<template>
  <div id="app">
    <div :style="`position:fixed; height:100px; width:100%; background-color:${$ENV.navBgColor}; z-index:1000; border-bottom:1px solid rgb(240,240,240);`">
      <div
        id="nav"
        style="position:relative; width:1400px; margin:0 auto;"
      >
        <img
          :src="$ENV.getAsset('logo.png')"
          style="max-height:60px; position:absolute; left:0px; top:15px; "
          alt="Imagen"
        >
        <div v-if="isLoggedIn">
          <router-link
            tag="button"
            to="/"
            style="margin-right:4px;"
            class="btn btn-dark"
          >
            <b-icon icon="house" /> Inicio
          </router-link>
          <router-link
            tag="button"
            to="/VerOrdenes"
            style="margin-right:4px; "
            class="btn btn-dark"
          >
            <b-icon icon="cart2" /> Órdenes
          </router-link>
          <router-link
            tag="button"
            to="/ModificarExamenes"
            style="margin-right:4px; "
            class="btn btn-dark"
          >
            <b-icon icon="file-medical" /> Exámenes
          </router-link>
  
          <router-link
            v-if="Number(ENV.VUE_APP_SHOW_REPORTES) === 1"
            tag="button"
            to="/Reportes"
            style="margin-right:4px;"
            class="btn btn-dark"
          >
            <b-icon icon="bar-chart" /> Reportes
          </router-link>
          <router-link
            v-if="Number(ENV.VUE_APP_SHOW_MAPA) === 1"
            tag="button"
            to="/Mapa"
            style="margin-right:4px;"
            class="btn btn-dark"
          >
            <b-icon icon="map" /> Mapa
          </router-link>
          <router-link
            v-if="Number(ENV.VUE_APP_SHOW_ENCUESTAS) === 1"
            class="btn btn-dark"
            style="margin-right:4px;"
            to="/PanelEncuestas"
            tag="button"
          >
            <b-icon icon="newspaper" /> Encuestas
          </router-link>
        </div>

        <div
          v-if="isLoggedIn"
          style="position:absolute; top:35px; right:10px; color:white;"
        >
          Identificado como: <b>{{ admin_datos.nombre }}</b> <button
            style="padding:2px 10px;"
            class="btn btn-danger"
            @click="logoutAdmin(); $router.push('/') "
          >
            Salir
          </button>
        </div>
      </div>
    </div>
    <div style="height:140px;" />
    <div
      style="position:relative;"
      class="container-fluid"
    />
    
    <div v-if="isInitialized">
      <router-view />
    </div>
    <notifications
      position="top right"
      group="foo"
    />
  </div>
</template>
<script>

import {mapActions, mapGetters} from 'vuex';
import axios from 'axios';
export default {
    components:{},
    data(){
      return{
        cargando:false,
      }
    },
    methods: {
    ...mapActions(['getAdmin', 'initialize', 'logoutAdmin']),
    },
    computed: {
    ...mapGetters(['admin_datos', 'isLoggedIn', 'regiones', 'comunas']),
    isInitialized(){
      if (this.regiones.length > 0 &&  this.comunas.length > 0)
        return true;
      return false;
    }
    },
    created(){
      this.initialize();
      if(this.admin_datos.length == 0 && typeof(localStorage.token_admin) != "undefined") //Relogear si recarga la pagina
      {
        
        this.cargando = true;
        axios.defaults.headers.common['Authorization'] = localStorage.token_admin
        this.getAdmin().then(e=>{this.cargando = false; this.$router.push('/Admin') }).catch(e=>{this.cargando = false;});
        
      }
    },
    mounted() {
      this.ENV = process.env;
    }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

/* #nav a.router-link-exact-active {
  color: #42b983;
} */

.vue-notification-group{
  margin-top:120px !important;
}

</style>
