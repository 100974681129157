<template>
  <div class="container-fluid">
    <h2 v-if="!loaded">
      Cargando...
    </h2>
    <div v-if="loaded">
      <h2>Modificar precio de prestación por comuna <b-icon icon="card-text" /></h2>
      
      <table class="table">
        <thead>
          <tr>
            <th />
            <th
              v-for="(comuna, index) in comunas"
              scope="col"
            >
              {{ comuna }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(examen_data, examen_id, index) in precios">
            <th
              scope="row"
            >
              {{ examen_data.nombre }}
              <b-icon
                :class="{ 'active-row': editando_examen == examen_id, 'inactive-row': editando_examen != examen_id }"
                :icon="editando_examen === examen_id ? 'check-circle' : 'pen'"
                @click="toggleEditarExamen(examen_id)"
              />
            </th>
            <td v-for="(comuna, comuna_id, index) in comunas">
              <span v-if="editando_examen != examen_id">
                {{ examen_data.por_comuna[comuna_id] > -1 ? `$${examen_data.por_comuna[comuna_id]}` : '-' }}
              </span>
              <input
                v-else
                v-model="nuevos_precios[comuna_id]"
                style="width:70px;"
                type="text"
              >
            </td>
          </tr>
        </tbody>
      </table>

      <div style="text-align:left; margin-top:10px;" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'


export default {
  name: 'ModificarPrecioExamenComuna',
  data() {
    return {

      loaded:false,
      
      precios:{},
      comunas:{},

      editando_examen:-1,
      nuevos_precios:{},

    }
  },
  created(){
  
    this.initialize_();
  },

  methods:{
    ...mapActions(['initialize']),

      editar_descuento(){
    axios.post(window.hostname+'/api/admin/editar_descuentos',{descuentos:this.editando_linea})
        .then(response => 
        {
          this.initialize().then(()=>{
          this.initialize_();
          })
          
        })

        .catch(e=>{
            console.log("Error al cargar...")
        })
        
  },

  toggleEditarExamen(examen_id){
    if (this.editando_examen == examen_id) {
      const data = {
        examen_id,
        nuevos_precios: this.nuevos_precios,
      }
      axios.post(window.hostname+'/api/admin/precio_por_comuna', data)
        .then( (response) => {
          this.get_precios_examenes_por_comuna();
          alert('Precios actualizados');
        })
        .catch((e)=>{
          console.log("Error al cargar...");
          alert(e);
        })
      this.editando_examen = -1
    } else {
      this.editando_examen = examen_id
    }
    this.reset_nuevos_precios();
    this.$forceUpdate();
  },

  async get_precios_examenes_por_comuna() {
    await axios.get(window.hostname+'/api/admin/precio_por_comuna')
    .then( (response) => {
      this.comunas = response.data.comunas;
      this.precios = response.data.precios;
      this.reset_nuevos_precios();
    })
    .then( () => {
      this.loaded = true;
      this.$forceUpdate();
    })
    .catch( e => { console.log(e.response) })
  },

  reset_nuevos_precios() {
    this.nuevos_precios = Object.keys(this.comunas).reduce((obj, key) => {
      obj[key] = '';
      return obj;
    }, {});
  },
    
  initialize_(){
    this.loaded = false;
    this.get_precios_examenes_por_comuna();
  },


  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'examenes', 'aseguradoras', 'descuentos']),
  },
  watch:{

  },
  mounted() {
    this.fComunas = new Fuse(this.comunas, {  keys: ['Nombre']})
  }

}
</script>


<style scoped>

.active-row {
  cursor:pointer;
  color:green;
}

.inactive-row {
  cursor:pointer;
  color:blue;
}

.container {
  max-width: 768px;
}

table
{ 
  margin-left: auto;
  margin-right: auto;
}
.table-holder, .table-responsive {
  position: relative;
}

.table-responsive::-webkit-scrollbar {
  height: 10px;
}

.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #333333;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #333333;
  outline: 1px solid #333333;
}

.info {
  position: absolute;
  width: 200px;
  height: 100%;
  background: linear-gradient(to right, transparent, #ffffff);
  top: 0;
  right: 0;
  padding: 20px;
  text-align: right;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  pointer-events: none;
}

.show {
  opacity: 1;
  visibility: visible;
}

td{
  text-align: left;
}

tr th:first-child, tr td:first-child {
  position: sticky;
  min-width: 50px;
  left: 0;
  background: white;
}
</style>

