// 

const backendUrl = process.env.VUE_APP_BACKEND_URL;

function getHeaders() {
  return {
    'Accept': 'application/json',
    'Authorization':  localStorage.getItem('token_admin'),
    'Prestador': Number(process.env.VUE_APP_PRESTADOR_ID),
    'Marketplace': false,
  }
}

function fetchMultipartData(path, body={}) {
  return new Promise((resolve, reject) => {
    const options = {
      method: 'POST',
      headers: getHeaders(),
      body: body,
    };

    console.log('options', options);

    fetch(backendUrl + path, options)
      .then(response => {
        if (response.ok) {
          resolve(response.json());
        } else {
          reject(new Error('Network response was not OK.'));
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export default fetchMultipartData;
