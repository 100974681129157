<template>
  <div class="container-fluid">
    <h4 style="margin:20px 0px 40px 0px;">
      <b-icon icon="archive" /> Detalles de la orden  (orden {{ $route.params.ordenId }}):
    </h4>
 
    <div v-if="link != ''">
      <img
        :src="link"
        style="max-width:100%; max-height:800px;"
        alt=""
      >
    </div>
    <div style="box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; border-radius:10px;  padding:10px;">
      <table style="width:100%;">
        <tr>
          <td><b>Nombre</b></td>
          <td><b>Apellido</b></td>
          <td><b>Rut</b></td>
          <td><b>Teléfono</b></td>
          <td><b>Sexo Biológico</b></td>
          <td><b>Fecha Nacimiento</b></td>
          <td><b>Aseguradora</b></td>
          <td><b>Exámenes</b></td>
          <td><b>Necesita Orden</b></td>
          <td><b>Orden Médica</b></td>
          <td style="text-align:center;">
            <b>Revisada</b>
          </td>
          <td style="">
            <b>Valor total</b>
          </td>
          <td style="">
            <b>Episodio</b>
          </td>
          <td style="">
            <b>Copago</b>
          </td>
          <td style="">
            <b>Acciones</b>
          </td>
        </tr>
        <tr
          v-for="c in clientes_calculated"
          style="vertical-align:top;"
        >
          <td>{{ c.nombre }} </td>
          <td>{{ c.apellido }}</td>
          <td>{{ c.rut }}</td>
          <td>{{ c.telefono }}</td>

          <td>{{ c.sexo_biologico == 'm' ? 'Masculino' : 'Femenino' }}</td>
          <td>{{ new Date(c.fecha_nacimiento.a, c.fecha_nacimiento.m-1, c.fecha_nacimiento.d).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' }) }}</td>
          <td>{{ c.asegurador.nombre }}</td>
          <td>
            <div v-for="e in items.filter(z=> {return z.cliente_id == c.id})">
              - {{ examenes.filter(x=> {return x.id == e.examen_id}).length > 0 ? examenes.filter(x=> {return x.id == e.examen_id})[0].nombre : 'INDEFINIDO' }}
            </div>
          </td>



          <td>{{ necesitaReceta(c.id) ? 'Si' : 'No' }}</td>
          <td>
            <span v-if="recetas.some(x=>{return x.cliente_id == c .id})">
              <div v-for="r in recetas.filter(x=>{return x.cliente_id == c.id})">
                <a :href="r.link">Descargar Orden de {{ c.nombre }}</a>
              </div>

            </span> 
            <span>
              <span>
                <label                 
                  class="btn btn-subir-foto"
                  :for="'file'+c.id"
                >Subir Receta</label>
                <input
                  :id="'file'+c.id"
                  :ref="'file'+c.id"
                  type="file"
                  accept="image/*, application/pdf"
                  style="visibility:hidden; width:0px;"
                  @change="subir_receta(c.id)"
                >
              </span>
            </span>
          </td>
          <td style="text-align:center;">
            <span v-if="recetas.some(x=>{return x.cliente_id == c .id})">
              <div 
                v-for="r in recetas.filter(x=>{return x.cliente_id == c .id})"
                style="cursor:pointer;"
                @click="marcarRevisada(r.id)"
              >
                <b-icon
                  v-if="r.revisada == 1"
                  style="color:green; cursor:pointer;"
                  icon="check"
                />
                <b-icon
                  v-else
                  icon="x-circle"
                  style="color:red; cursor:pointer;"
                />
              </div>
            </span>
          </td>
          <td>
            <input
              v-model="c.costo_total"
              style="width:100px;"
              onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
              @input="c.costo_total = $event.target.value"
            > 
          </td>
          <td>
            <input
              v-model="c.episodio"
              style="width:100px;"
              onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
            >
          </td>
          <td>
            <input
              v-model="c.copago"
              style="width:100px;"
              onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
            >
          </td>
          <td style="">
            <button
              style="padding:2px 5px;"
              class="btn btn-success"
              @click="eEpCopVtotal(c.id)"
            >
              Guardar
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import $ from 'jquery'

export default {
  name: 'HelloWorld',
  props:['ordenId'],
  data() {
    return {
      link:'',
      clientes:[],
      recetas:[],
      items:[],
      atendidos:[],
      clientes_calculated:[],

    }
  },
  methods:{
    //...mapActions(['loginAdmin']),
    eEpCopVtotal(cid){
      let target = {}
        try{
           target = this.clientes_calculated.filter(x=>{return x.id == cid})[0]
        }
        catch{
          return;
        }


        
        axios.post(window.hostname+'/api/admin/modificar_copago_episodio_ct', 
        {orden_id:this.$route.params.ordenId, cliente_id:cid, copago:target.copago, episodio:target.episodio, costo_total:target.costo_total})
        .then(response => 
        {
          this.initialize()
        })
        .catch(e=>{
          console.log(e)        
        })
        

    },
    subir_receta(cid){
      if (['jpg', 'png', 'jpeg', 'pdf'].includes(this.$refs['file'+cid][0].files[0].name.split('.').pop()))
      {
        const json_ob = JSON.stringify({
          orden_id:this.$route.params.ordenId,
          cliente_id:cid,

        })

        const blob = new Blob([json_ob], {type:'application/json'});
        let data = new FormData();
        data.append("document", blob)
        data.append("file", this.$refs['file'+cid][0].files[0])
        axios({method:'post', url:window.hostname+'/api/admin/agregar_receta', data:data})
        .then(r=>{
          this.initialize();
        })
        .catch(e=>{
          console.log(e)
        })

      }
    },
    necesitaReceta(cliente_id){
      let items_ = this.items.filter(z=>{return z.cliente_id == cliente_id})
      if(items_.length == 0)
        return true;
      let examen_f = []
      for(var v of items_){
        examen_f = this.examenes.filter(l=> {return l.id == v.examen_id})
        if (examen_f.length <= 0)
          return true;
          if(this.examenes.filter(l=> {return l.id == v.examen_id})[0].requiere_orden == 1)
            return true
        
      }
      return false;
    },
    marcarRevisada(receta_id){
        axios({method:'post', url:window.hostname+'/api/admin/receta_marcar_como_revisado', data:{receta_id:receta_id}})
        .then(r=>{
          this.initialize();
        })
        .catch(e=>{
          console.log(e)
        })
    },
    timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },
 
    initialize(){
      
        axios.post(window.hostname+'/api/admin/initialize_recetas', 
        {orden_id:this.$route.params.ordenId})
        .then(response => 
        {
          this.clientes = response.data.clientes;
          this.recetas = response.data.recetas;
          this.items = response.data.items;
          this.atendidos = response.data.atendidos;
        })
        .catch(e=>{
          console.log(e)        
        })
    }
  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'examenes']),

  },
  watch:{
    clientes(){
      let ret = {}
      this.clientes_calculated = []
      for(var i = 0; i < this.clientes.length; i++){
        ret = this.clientes[i]
        for (var j = 0; j < this.atendidos.length; j++)
        {
          if (this.atendidos[j].cliente_id == this.clientes[i].id){
            ret.copago = this.atendidos[j].copago
            ret.costo_total = this.atendidos[j].costo_total
            ret.episodio = this.atendidos[j].episodio
            break; 
          } 
        }
      this.clientes_calculated.push(ret)
      }
    }

  },
  created(){
    this.initialize();
  },
  mounted() {
    /*
  $("#files").change(function() {
    let filename = this.files
    vm.files_list = []
    for (var i = 0; i< filename.length; i++)
      vm.files_list.push({nombre:filename[i].name, size:filename[i].size})
  });*/

  },
  beforeUnmount () {

  }

}
</script>


<style scoped>
.btn-subir-foto{
  background-color: white;
  color:rgba(20, 134, 231, 1);
  font-weight:700;
  border: 3px solid rgba(20, 134, 231, 1);
  padding: 0px 13px;
  border-radius: 10px;
  cursor: pointer;
  transition:0.1s;
  width:130px;


}
.btn-subir-foto:hover{
  color:rgba(20, 134, 231, 1);

}

td{
  padding: 5px 5px 0px;
  text-align: left;
}
/*
 tr:nth-child(odd){
  background-color: rgba(1, 98, 153, 0.8);
  color: #fff;
}
*/
table
{ 
    margin-left: auto;
    margin-right: auto;
}

.shadow-object{
  background-color: white;
  /*margin: 10px 0px 10px 10px;*/
  border-radius: 5px;
  height:auto;
  box-shadow: rgba(0, 0, 0, 0.28) 0px 3px 8px;
  padding:2px;
}

</style>

