import axios from 'axios';

const state = {

    aseguradoras:[],
    descuentos: [],
    comunas:[],
    regiones:[],
    examenes:[],
    comunas_usadas:[],
    categorias:[],
    costo_servicio:0,
    costo_servicio_pendiente:0,


    hasCoords: false,
    myCoords: {
        lat:0,
        lng:0
    },

};

const getters = {
    aseguradoras: state => state.aseguradoras,
    descuentos: state => state.descuentos,
    comunas: state => state.comunas,
    comunas_usadas: state => state.comunas_usadas,
    examenes: state => state.examenes,
    categorias: state => state.categorias,
    instituciones: state => state.instituciones,
    regiones: state => state.regiones,
    tipos: state => state.tipos,
    hasCoords: state => state.hasCoords,
    myCoords: state=> state.myCoords,
    costo_servicio: state=> state.costo_servicio,
    costo_servicio_pendiente: state=> state.costo_servicio_pendiente,
};

const actions = {


    setCoords({commit}, payload){
        commit('setCoordinates', payload)        
    },


    async initialize({commit}){


        /*Funcion recursiva */
        /*
        async function initi()
        {
        axios({url:window.hostname+'/api/initialize', method: 'GET'}).then(response => 
            {
                
                console.log(response.data)
               
                commit('setData', response.data)

            }).catch(err => 
            {
                if (err.response)
                {
                    initi();
                }
            })
        }

    initi(); //se llama a si misma hasta que funcione

    */
    let resp;
    let done = false;
    while (!done){
        try{
            resp = await  axios({url:window.hostname+'/api/initialize_admin', method: 'GET'})
            commit('setData', resp.data)
            done = true;
        }
        catch (e){
            console.log(e)
        }
    }

    }
};

const mutations = {
    setData: (state, data) => {
        state.aseguradoras = data.aseguradoras;
        state.descuentos = data.descuentos;
        state.comunas = data.comunas;
        state.comunas_usadas = data.comunas_usadas;
        state.examenes = data.examenes;
        state.tipos = data.tipos;
        state.regiones = data.regiones;
        state.categorias = data.categorias;
        state.instituciones = data.instituciones;
        state.costo_servicio = data.costo_servicio;
        state.descuentos = data.descuentos;
        state.costo_servicio_pendiente = data.costo_servicio_pendiente;
    },
    setCoordinates: (state, data) =>{
        state.hasCoords = true;
        state.myCoords = data;
    }

};

export default{
    state,
    getters,
    actions,
    mutations,
};