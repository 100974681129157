<template>
  <div class="container">
    <h2>Orden {{ $route.params.ordenId }}:</h2>
    <label
      class="btn btn-success"
      for="files"
    >Seleccione Archivos</label>
    <input
      id="files"
      ref="file"
      style="visibility:hidden; width:0px;"
      type="file"
      multiple="multiple"
      @change="change_file()"
    >
    <button
      class="btn btn-info"
      @click=""
    >
      aaAA
    </button>
    <div v-for="f in files">
      {{ f.nombre }}
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import $ from 'jquery'

export default {
  name: 'HelloWorld',
  props:['ordenId'],
  data() {
    return {
      orden:[],
      files:[],
    }
  },
  methods:{
    change_file() {
    this.files = []    
    for (var i = 0; i< this.$refs.file.files.length; i++)
      this.files.push({nombre:this.$refs.file.files[i].name, size:this.$refs.file.files[i].size})
  },
    timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },
 
    get_ordenes(){
      
        axios.post(window.hostname+'/api/admin/get_ordenes_dia', 
        {dia:this.desde.d, mes:this.desde.m, año:this.desde.a,
         dia2:this.hasta.d, mes2:this.hasta.m, año2:this.hasta.a})
        .then(response => 
        {
          this.ordenes = response.data.ordenes
        })
        .catch(e=>{
          console.log(e)        
        })
    }
  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'examenes']),

  },
  watch:{

  },
  mounted() {},
  beforeUnmount () {},
}
</script>


<style scoped>
td{
  padding: 5px 5px 0px;
  text-align: left;
}
/*
 tr:nth-child(odd){
  background-color: rgba(1, 98, 153, 0.8);
  color: #fff;
}
*/
table
{ 
    margin-left: auto;
    margin-right: auto;
}

.shadow-object{
  background-color: white;
  /*margin: 10px 0px 10px 10px;*/
  border-radius: 5px;
  height:auto;
  box-shadow: rgba(0, 0, 0, 0.28) 0px 3px 8px;
  padding:2px;
}

</style>

