<template>
  <div class="container-fluid">
    <h2>Crear Encuesta <b-icon icon="newspaper" /></h2>
    <button
      class="btn btn-success"
      style="margin-bottom: 20px;"
      @click="guardar_cambios()"
    >
      <b-icon icon="check-square" /> Guardar Cambios <b-icon icon="check-square" />
    </button>

    <div
      v-if="loaded"
      class="container"
      style=" text-align:left; padding-bottom:120px; "
    >
      <div class="data-box">
        <div
          class="input_largo"
          style="padding:4px; display:inline-block; font-size:13px; padding-right:7px; margin-top:0px; padding-top:0px;"
        >
          Titulo Encuesta <input
            id=""
            v-model="encuesta.titulo"
            placeholder="Escribe el titulo"
            class="form-control"
            type="text"
            name=""
            @focus="focused_titulo = true;"
            @blur="focused_titulo = false"
            @input="encuesta.titulo = $event.target.value"
          >
        </div>
      
        <div
          class="input_largo"
          style="display:inline-block;  font-size:13px; padding:4px; margin-top:0px; padding-top:0px;"
        >
          Texto Inicial Encuesta <input
            id=""
            v-model="encuesta.texto_inicial"
            placeholder="Escribe una descripción"
            class="form-control"
            type="text"
            name=""
            @focus="focused_texto_inicial = true;"
            @blur="focused_texto_inicial = false"
            @input="encuesta.texto_inicial = $event.target.value"
          >
        </div>

        <div
          class="input_largo"
          style="display:inline-block;  font-size:13px; padding:4px; margin-top:0px; padding-top:0px;"
        >
          Descripción Panel Admin <input
            id=""
            v-model="encuesta.descripcion"
            placeholder="Escribe una descripción"
            class="form-control"
            type="text"
            name=""
            @focus="focused_descripcion = true;"
            @blur="focused_descripcion = false"
            @input="encuesta.descripcion = $event.target.value"
          >
        </div>
      </div>

      <div class="data-box">
        <div
          class="input_largo"
          style="padding:4px; display:inline-block; font-size:13px; padding-right:7px; margin-top:0px; padding-top:0px;"
        >
          Titulo Correo <input
            id=""
            v-model="encuesta.titulo_correo"
            placeholder="Escribe el titulo del correo"
            class="form-control"
            type="text"
            name=""
            @focus="focused_titulo_correo = true;"
            @blur="focused_titulo_correo = false"
            @input="encuesta.titulo_correo = $event.target.value"
          >
        </div>
      
        <div
          class="input_largo"
          style="display:inline-block;  font-size:13px; padding:4px; margin-top:0px; padding-top:0px;"
        >
          Texto Correo <input
            id=""
            v-model="encuesta.texto_correo"
            placeholder="Hola 'CLIENTE', TEXTO"
            class="form-control"
            type="text"
            name=""
            @focus="focused_texto_correo = true;"
            @blur="focused_texto_correo = false"
            @input="encuesta.texto_correo = $event.target.value"
          >
        </div>
      </div>


      <div
        v-for="(pregunta) in encuesta.preguntas"
        :key="pregunta.id"
        class="data-box"
      >
        <div style="width:100%; border-bottom:1px solid rgb(214, 214, 214);  padding:15px 15px 15px 30px; background:white;"> 
          <b-icon icon="card-text" /> {{ 'Pregunta '+ (pregunta.posicion+1) + ' - Tipo ' + pregunta.tipo }}

          <span style="float:right; margin-right:10px;">
            <button
              class="btn btn-danger"
              style="margin-right: 6px;"
              @click="remover_pregunta(pregunta)"
            > <b-icon icon="dash-circle" /> Eliminar Pregunta </button>
          </span>

          <span
            v-if="pregunta.tipo == 'alternativa'"
            style="float:right; margin-right:10px;"
          >
            <button
              class="btn btn-success"
              style="margin-right: 6px;"
              @click="agregar_opcion_alternativa(pregunta)"
            > <b-icon icon="plus-circle" /> Opción </button>
            <button
              class="btn btn-danger"
              style="margin-right: 200px;"
              @click="remover_ultima_opcion_alternativa(pregunta)"
            > <b-icon icon="dash-circle" /> Última Opción </button>
          </span>
        </div>

        <div
          class="input_largo"
          style="padding:4px; display:inline-block; font-size:13px; padding-right:7px; margin-top:5px; padding-top:0px;"
        >
          <input
            id=""
            v-model="pregunta.titulo"
            placeholder="Escribe la pregunta"
            class="form-control"
            type="text"
            name=""
            @focus="temp_dict[index+1] = true;"
            @blur="temp_dict[index+1] = false"
            @input="pregunta.titulo = $event.target.value"
          >
        </div>

        <div v-if="pregunta.tipo == 'alternativa'">
          <div
            v-for="(opcion, index) in pregunta.opciones"
            :key="opcion.id"
            class="input-49"
            style="padding:4px; display:inline-block; font-size:13px; padding-right:7px; margin-top:0px; padding-top:0px;"
          >
            Alternativa {{ index+1 }} <input
              id=""
              v-model="opcion.titulo"
              placeholder="Escribe la alternativa"
              class="form-control"
              type="text"
              name=""
              @focus="temp_dict[index+1] = true;"
              @blur="temp_dict[index+1] = false"
              @input="opcion.titulo = $event.target.value"
            >
          </div>
        </div>
      </div>


      <div style="text-align:left; margin-top:10px;">
        <button
          class="btn btn-success"
          style="margin-right: 6px;"
          @click="agregar_pregunta_puntaje()"
        >
          <b-icon icon="plus-circle" /> Pregunta Puntaje
        </button>
        <button
          class="btn btn-success"
          style="margin-right: 6px;"
          @click="agregar_pregunta_texto()"
        >
          <b-icon icon="plus-circle" /> Pregunta Texto
        </button>
        <button
          class="btn btn-success"
          style="margin-right: 6px;"
          @click="agregar_pregunta_alternativa()"
        >
          <b-icon icon="plus-circle" /> Pregunta Alternativa
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';

export default {
  name: 'CrearEncuesta',
  data() {
    return {

      encuesta: {
        titulo: '',
        texto_inicial: '',
        descripcion: '',
        titulo_correo: '',
        texto_correo: '',
        preguntas: []
      },
      loaded: false,
      temp_dict: {},

    }
  },
  created(){
    this.initialize_();
  },

  methods:{

    ...mapActions(['initialize']),

    agregar_pregunta_puntaje(){
      let pregunta = {
        tipo: 'puntaje',
        titulo: '',
        posicion: this.encuesta.preguntas.length,
        focused: false,
      }
      this.encuesta.preguntas.push(pregunta);
    },

    agregar_pregunta_texto(){
      let pregunta = {
        tipo: 'texto',
        titulo: '',
        posicion: this.encuesta.preguntas.length,
        focused: false,
      }
      this.encuesta.preguntas.push(pregunta);
    },

    agregar_pregunta_alternativa(){
      let pregunta = {
        tipo: 'alternativa',
        titulo: '',
        posicion: this.encuesta.preguntas.length,
        opciones: [],
        focused: false,
      }
      this.encuesta.preguntas.push(pregunta);
    },

    remover_pregunta(pregunta){
      this.encuesta.preguntas.splice(pregunta.posicion, 1);
      this.encuesta.preguntas.forEach((pregunta, index) => {
        pregunta.posicion = index;
      });
    },

    agregar_opcion_alternativa(pregunta){
      let opcion = {
        titulo: '',
        focused: false,
      }
      pregunta.opciones.push(opcion)
    },

    remover_ultima_opcion_alternativa(pregunta){
      pregunta.opciones.pop();
    },
    
    guardar_cambios(){
      axios.post(window.hostname+'/encuestas/', this.encuesta)
      .then( (response) => {
        console.log(response);
        this.$router.push({ name: 'PanelEncuestas' })
      })
      .catch( (error) => {
        console.log("Error en guardar_cambios()...")
        console.log(error)
        alert(error);
      })
    },

    initialize_(){
        this.loaded = false;
        this.loaded = true;
    },

  },


}
</script>


<style scoped>
select{
  background-color: white !important;
}


.selector {
  background-color:white;
  width: 130px;
  padding: 6px 6px;
  display: inline-block !important;
  margin: 0 10px 0 0px;
  box-sizing: border-box;
  border-radius: 5px;
  font-size:16px;
  border:1px solid rgba(0,0,0,0.2);
  outline: none;
  color:#111111;
}

.dir1, .dir2, .dir3{
  display:inline-block;
  margin-right:40px;
  margin-top:0px !important;
}
.btn-volver{
  border: 2px solid rgb(254, 177, 53);
  color: rgb(254, 177, 53);
}
.btn-volver:hover{
  border: 2px solid rgb(254, 177, 53);
  color: rgb(254, 177, 53);
}
.btn-bien{
background-color: rgb(254, 177, 53);

}
.btn-bien:hover{
color:white;

}
.btn-mal{
  background-color: rgb(255,222,166);
  color:white;
}
.btn-mal:hover{
  color:white;
  
}
.glow {
  color: #fff;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
  animation-iteration-count: 5;
}

@keyframes glow {
  from {
    box-shadow: 0 0 20px rgb(254, 177, 53), 0 0 20px rgb(254, 177, 53), 0 0 30px rgb(254, 177, 53), 0 0 40px rgb(254, 177, 53), 0 0 50px rgb(254, 177, 53), 0 0 60px rgb(254, 177, 53), 0 0 70px rgb(254, 177, 53);
  }
  to {
    box-shadow: 0 0 10px rgb(254, 177, 53), 0 0 30px rgb(254, 177, 53), 0 0 40px rgb(254, 177, 53) 0 0 50px rgb(254, 177, 53), 0 0 60px rgb(254, 177, 53), 0 0 70px rgb(254, 177, 53), 0 0 80px rgb(254, 177, 53);
  }
}
.spa{
  margin-top:100px;
}
.listado-buscando-container{
  z-index:9997; left:20px; top:35px; width:Min(400px, Calc(100vw - 100px)); position:absolute; background:white; border: 1px solid rgba(20, 134, 231, 1); margin-top:12px; border-radius:20px;padding:15px 0px 10px 0px;
}
.listado-buscando{
   position:relative; cursor:pointer; margin:0 auto; width:100%; text-align:left; padding:5px 10px 5px 10px;  
}
.examen-paciente-container{
border: 1px solid rgb(214, 214, 214); padding-right:40px; border-radius:10px; margin-top:40px; margin-bottom:40px; background:rgba(20, 134, 231, 0.04);
}
.buscar-examen-icon{
  position:absolute; top:10px; right:-5px;
}
.exam-list{
  padding:8px 20px;
}
.input-buscar-examen{
  margin-left:20px; margin-right:20px; border-radius:20x;
}
.btn-agregar-examen{
  border:2px solid rgb(255, 200, 9); 
  border-radius:10px; 
  padding:10px; 
  margin-top:10px; 
  width:200px;
  background:rgb(255, 200, 9);
  color:white; 
  cursor:pointer;
  font-weight: 700;
  margin-bottom:10px;
}
.btn-agregar-examen:hover{
  border:2px solid rgb(255, 200, 9); 
  border-radius:10px; 
  padding:10px; 
  margin-top:10px; 
  width:200px;
  background:white;
  color:rgb(255, 200, 9); 
  cursor:pointer;
  font-weight: 700;
}

.btn-agregar-examen-inverso2{

  border:2px solid rgba(20, 134, 231, 1); 
  border-radius:10px; 
  padding:10px; 
  margin-top:10px; 
  width:190px;
  background:white;
  color:rgba(20, 134, 231, 1);
  cursor:pointer;
  font-weight: 700;


}
.btn-agregar-examen-inverso2:hover{
  border:2px solid rgba(20, 134, 231, 1);
  border-radius:10px; 
  padding:10px; 
  margin-top:10px; 
  width:200px;
  background:rgba(20, 134, 231, 1);
  color:white; 
  cursor:pointer;
  font-weight: 700;
  margin-bottom:10px;
}
.btn-agregar-examen-inverso{

  border:2px solid rgb(108, 193, 252); 
  border-radius:10px; 
  padding:10px; 
  margin-top:10px; 
  width:190px;
  background:white;
  color:rgb(108, 193, 252);
  cursor:pointer;
  font-weight: 700;


}
.btn-agregar-examen-inverso:hover{
  border:2px solid rgb(108, 193, 252);
  border-radius:10px; 
  padding:10px; 
  margin-top:10px; 
  width:200px;
  background:rgb(108, 193, 252);
  color:white; 
  cursor:pointer;
  font-weight: 700;
  margin-bottom:10px;
}
.opcionClicked{
  border:2px solid rgba(20, 134, 231, 1); 
  color:white;
  background: rgba(20, 134, 231, 1);
}
.opcionClicked:hover{
  color:white;

}
.opcionNoClicked{
  border:2px solid rgba(20, 134, 231, 1); 
  color:rgba(20, 134, 231, 1);
}
.opcionNoClicked:hover{
  border:2px solid rgba(20, 134, 231, 1); 
  color:white;
  background: rgba(20, 134, 231, 1);
}
.parent {
  overflow: hidden; /* required */
  width: 50%; /* for demo only */
  height: 250px /* some non-zero number */;
  margin: 25px auto; /* for demo only */
  border:1px solid grey; /* for demo only */
  position: relative; /* required  for demo*/
}

.ribbon {
  margin: 0;
  padding: 0;
  background: rgba(20, 134, 231, 1);
  color:white;
  padding:1em 0;
  position: absolute;
  top:0;
  right:0;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  z-index: 99;
  transform-origin: top left;
}

.data-box{
  margin-bottom:20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color:white;
}

.input_corto, .nombre, .apellido1, .rut, .titulo{
  width:33%
}

.input-49, .descripcion{
  width:49.5%
}

.input_mediano, .descripcion{
  width:66%
}

.input_largo, .descripcion{
  width:99%
}

.ribbon:before,
.ribbon:after {
  content: '';
  position: absolute;
  top:0;
  margin: 0 -1px; /* tweak */
  width: 100%;
  height: 100%;
  background: rgba(20, 134, 231, 1);
}
.ribbon:before {
  right:100%;
}
.alerta{
  padding:10px;
  background-color: #dc3545;
  color:white;
  position:absolute;
  
  right:3px;
  font-weight: 700;
  top: -41px;
  font-size: 12px;
  transition-duration: 0.2s;
}
.alerta:after{

  content:'';
  right:30px;
  top:33px;
  width: 10px;
  height: 10px;
  background: #dc3545;
  border-right: 1px solid #dc3545;
  border-bottom: 1px solid #dc3545;
  transform: rotate(45deg);
  position: absolute;
}

.ribbon:after {
  left:100%;
}
.bottom-spacer{
  height:0px;
}
.top-spacer{
  height:130px;
}
.carta{
  overflow: hidden; 
  position:relative; 
  background:white; 
  border:1px solid rgb(220, 220, 220); 
  padding: 40px; 
  border-radius:5px; 
  box-shadow:rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
@media only screen and (max-width: 540px) {
.nombre, .apellido1, .apellido2, .rut, .email, .telefono, .isapre, .fechan{
  width: 100% !important;
  margin:0px;
  margin-top:10px;
  padding:0px !important;
  display: block !important;;
}
.carta{
  padding: 10px; 
}
.isapre{
  margin-bottom: 20px;
}
.telefono, .apellido1, .rut{
  padding:0px !important;
}
.nombre, .apellido2, .apellido1{
  margin-bottom: 10px;
}

.ribbon {
  padding:4px 0;

  transform: translateX(40%) translateY(0%) rotate(45deg);

}




}
.main-c{
  padding:20px 30px;
}
@media only screen and (max-width: 1200px) {
  .top-spacer{
  height:85px;
}
.spa{
  margin-top:55px;

}
.bottom-spacer{
  height:60px;
}




}

@media (max-width: 576px) { 
  
  .main-c{
  padding:20px 10px;
}

.buscar-examen-icon{
  position:absolute; top:10px; right:-5px;
}
.exam-list{
  padding:8px 10px;
}
.input-buscar-examen{
  margin-left:10px; 
  margin-right:10px;
  width:100%;
  border-radius:10x;
}
.examen-paciente-container{
  padding-right:20px;
}
.listado-buscando{
   position:relative; cursor:pointer; margin:0 auto; width:100%; text-align:left; padding:5px 10px 5px 10px; 
}


.listado-buscando-container{
  z-index:9997; left:10px; top:35px; width: Min(400px, Calc(100% + 0px)); position:absolute; background:white; border: 1px solid rgba(20, 134, 231, 1); margin-top:12px; border-radius:20px;padding:15px 0px 10px 0px;
}

}

@media (max-width: 768px) { 
  
 }

@media (max-width: 992px) { 
  .dir1, .dir2, .dir3{
  display:block;
  margin-right:40px;
  margin-top:20px !important;
}
 }

@media (min-width: 1200px) { 
  
 }

@media (min-width: 1400px) { 
  
 }

@media (min-width: 576px) { 
.btn-volver{
  width:180px !important;
}
.btn-bien{
  width:180px !important;
}
.btn-mal{
  width:180px !important;
}
}


@media (min-width: 768px) { 
.btn-volver{
  width:240px !important;
}
.btn-bien{
  width:240px !important;
}
.btn-mal{
  width:240px !important;
}
 }


@media (min-width: 992px) { 
.btn-volver{
  width:240px !important;
}
.btn-bien{
  width:240px !important;
}
.btn-mal{
  width:240px !important;
}
 }


@media (min-width: 1200px) {
.btn-volver{
  width:240px !important;
}
.btn-bien{
  width:240px !important;
}
.btn-mal{
  width:240px !important;
}

}


@media (min-width: 1400px) { 
.btn-volver{
  width:240px !important;
}
.btn-bien{
  width:240px !important;
}
.btn-mal{
  width:240px !important;
}
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black !important;
  opacity: 0.25 !important; /* Firefox */
}

</style>