import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Admin from '../components/Admin.vue'
import CrearProfesional from '../components/CrearProfesional.vue'
import ModificarProfesional from '../components/ModificarProfesional.vue'
import VerOrdenes from '../components/VerOrdenes.vue'
import ModificarExamenes from '../components/ModificarExamenes.vue'
import ModificarCategorias from '../components/ModificarCategorias.vue'
import ModificarOrden from '../components/ModificarOrden.vue'
import ModificarHorario from '../components/ModificarHorario.vue'
import SubirResultado from '../components/SubirResultado.vue'
import Mapa from '../components/Mapa.vue'
import Reportes from '../components/Reportes.vue'
import VerReceta from '../components/VerReceta.vue'
import BloqueosHorario from '../components/BloqueosHorario.vue'
import ModificarHorarioPro from '../components/ModificarHorarioPro.vue'
import ModificarDescuentos from '../components/ModificarDescuentos.vue'
import ModificarPrecioExamenComuna from '../components/ModificarPrecioExamenComuna.vue'
import ModificarEncuestas from '../components/encuestas/ModificarEncuestas.vue'
import CrearEncuesta from '../components/encuestas/CrearEncuesta.vue'
import ModificarCodigos from '../components/ModificarCodigos.vue'
import EditarCodigo from '../components/EditarCodigo.vue'
import store from '../store/index.js'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Login,
    meta: { 
      requiresNotAuth: true
    }
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: Admin,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/SubirResultado/:orden_id',
    name: 'SubirResultado',
    component: SubirResultado,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/VerOrdenes',
    name: 'VerOrdenes',
    component: VerOrdenes,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/BloqueosHorario',
    name: 'BloqueosHorario',
    component: BloqueosHorario,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/Reportes',
    name: 'Reportes',
    component: Reportes,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/ModificarExamenes',
    name: 'ModificarExamenes',
    component: ModificarExamenes,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/ModificarCategorias',
    name: 'ModificarCategorias',
    component: ModificarCategorias,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/ModificarDescuentos',
    name: 'ModificarDescuentos',
    component: ModificarDescuentos,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/ModificarPrecioExamenComuna',
    name: 'ModificarPrecioExamenComuna',
    component: ModificarPrecioExamenComuna,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/ModificarCodigos',
    name: 'ModificarCodigos',
    component: ModificarCodigos,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/EditarCodigo/:id',
    name: 'EditarCodigo',
    component: EditarCodigo,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/Mapa',
    name: 'Mapa',
    component: Mapa,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/ModificarProfesional/:id',
    name: 'ModificarProfesional',
    component: ModificarProfesional,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/VerReceta/:ordenId',
    name: 'VerReceta',
    component: VerReceta,
    meta: { 
      requiresAuth: true
    }
  },

  {
    path: '/ModificarOrden/:id',
    name: 'ModificarOrden',
    component: ModificarOrden,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/ModificarHorario/:id',
    name: 'ModificarHorario',
    component: ModificarHorario,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/ModificarHorarioPro/:id',
    name: 'ModificarHorarioPro',
    component: ModificarHorarioPro,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/CrearProfesional',
    name: 'CrearProfesional',
    component: CrearProfesional,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/PanelEncuestas',
    name: 'PanelEncuestas',
    component: ModificarEncuestas,
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/CrearEncuesta',
    name: 'CrearEncuesta',
    component: CrearEncuesta,
    meta: { 
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/') 
  }else if(to.matched.some(record => record.meta.requiresNotAuth)){
    if (!store.getters.isLoggedIn) {
      next()
      return
    }
    next('/Admin')
  } else {
    next() 
  }
})
export default router
