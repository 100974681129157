<template>
  <div>
    <div class="container">
      <h2 style="margin-bottom:40px;">
        Inicio <b-icon icon="house" />
      </h2>
      <div
        class="containre"
        style="padding:40px; position:relative; background-color:white; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; margin-bottom:80px;"
      >
        <h4>Profesionales</h4>
        <br>

        <table style="margin-left: auto; margin-right: auto;">
          <tr>
            <td style="text-align:left; border-bottom: 1px solid black;">
              <b>Nombre</b>
            </td>
            <td style="text-align:left; border-bottom: 1px solid black; border-left:1px solid black;">
              <b>Email</b>
            </td>
            <td style="text-align:left; border-left:1px solid black; border-bottom: 1px solid black;">
              <b>Comunas</b>
            </td>
            <td style="text-align:left; border-left:1px solid black; border-bottom: 1px solid black;">
              <b>Categorías</b>
            </td>
            <td style="border-left:1px solid black; border-bottom: 1px solid black;">
              <b>Eliminar</b>
            </td>
          </tr>
          <tr v-for="p in profesionales">
            <td style="text-align:left;">
              <router-link
                tag="span"
                style="cursor:pointer;"
                class=""
                :to="'/ModificarProfesional/'+p.id"
              >
                <b-icon
                  style="color:green;"
                  icon="eye"
                />  {{ p.nombre }} {{ p.apellido }}
              </router-link>
            </td> 
            <td style="text-align:left; border-left:1px solid black;">
              {{ p.email }}
            </td> 
            <td style="text-align:left; border-left:1px solid black;">
              <span v-for="c, u in p.comunas">{{ comunas.filter(x=>{return x.id == c})[0].Nombre }}{{ (p.comunas.length - 1 > u) ? ',': '.' }} </span>
            </td>
            <td style="text-align:left; border-left:1px solid black;">
              <div v-for="c in p.categorias">
                - {{ categorias.filter(x=>{return x.id == c})[0].nombre }}
              </div>
            </td>
            <td style="border-left:1px solid black;">
              <b-icon
                style="color:red; cursor:pointer;"
                icon="trash"
                @click="deleteProfesionalDialogue(p.id)"
              />
              <confirm-dialogue :ref="'confirmDialogueProfesional' + p.id" />
            </td>
          </tr>
        </table>

        <br>
        <br>
        <router-link
          tag="button"
          class="btn btn-success"
          to="/CrearProfesional"
        >
          Crear profesional <b-icon icon="plus-circle" />
        </router-link>
        <router-link
          style="margin-left:4px;"
          tag="button"
          class="btn btn-info"
          to="/BloqueosHorario"
        >
          Bloqueos Horario <b-icon icon="clock" />
        </router-link>
        <br>
        <br>
        <hr>
        <h4>Comunas cubiertas</h4>
        <div
          v-for="c, v in comunas_usadas"
          style="display:inline-block;"
        >
          {{ c.Nombre }}{{ comunas_usadas.length-1 == v ? '.': ', &nbsp;' }}
        </div>
        <br>
        <br>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

import ConfirmDialogue from './utils/ConfirmDialogue.vue';
import fetchData from '@/helpers/fetchData.js'

export default {
  name: 'Admin',
  components: {
    ConfirmDialogue
  },
  data() {
    return {
      profesionales:[],
      cargado:false,
      categorias:[],
    }
  },
  methods:{
    ...mapActions(['loginAdmin', 'logoutAdmin']),

    async deleteProfesionalDialogue(profesional_id) {
      const profesional = this.profesionales.filter(x=>{return x.id == profesional_id})[0];
      const confirmDialogue = this.$refs["confirmDialogueProfesional" + profesional_id][0];
      const ok = await confirmDialogue.show({
        title: `¿Eliminar profesional ${profesional.nombre} ${profesional.apellido}?`,
        message: 'Se eliminará el profesional y no podrá recuperarse.',
        okButton: 'Eliminar definitivamente',
      })
      // If you throw an error, the method will terminate here unless you surround it wil try/catch
      if (ok) {
        await this.eliminar_profesional(profesional_id);
      } else {
        alert('Se canceló la eliminación.')
      }
    },

    async getProfesionales(){
      await fetchData('/api/admin/getprofesionales')
      .then((data)=>{
        this.profesionales = data.profesionales
        this.categorias = data.categorias
      })
      .catch((error)=> {
        console.log(error);
      })
    },
    async eliminar_profesional(hid){
      await fetchData('/api/admin/eliminar_profesional', 'POST', {profesional_id: hid})
      .then((response) => {
        this.getProfesionales();
        alert('Se eliminó el profesional.')
      })
      .catch((e)=>{
        alert('Error al eliminar el profesional.')
        console.log("Error al cargar...")
      })
    }

  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'comunas_usadas']),
  },
  created(){
    this.getProfesionales()
  }

}
</script>


<style scoped>
td{
  padding: 5px 10px 0px 5px;
}

</style>

