<template>
  <div class="container">
    <div v-if="loaded">
      <h4 style="margin-bottom:20px;">
        Bloqueos de horario <b-icon icon="alarm" />
      </h4>
    
      <div style=" width:100%;box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; display:inline-block; padding:20px 0px; border-radius:10px;">
        Profesional
        <select
          v-model="pro_id"
          style="padding:4px;"
        >
          <option :value="0">
            Todos
          </option>
          <option
            v-for="p in profesionales"
            :value="p.id"
          >
            {{ p.nombre+' '+p.apellido }}
          </option>
        </select>
        Dia <input
          id="x2"
          v-model="dt_dia"
          type="date"
        >
        Desde
        <select
          v-model="desde_general"
          style="padding:4px;"
        >
          <option
            v-for="v in Math.round(1440/5)"
            :value="(v-1)*5"
          >
            {{ timeConvert((v-1)*5) }}
          </option>
        </select>
        Hasta
        <select
          v-model="hasta_general"
          style="padding:4px;"
        >
          <option
            v-for="v in Math.round(1440/5)"
            v-if="(v)*5 > desde_general"
            :value="(v)*5"
          >
            {{ timeConvert((v)*5) }}
          </option>
        </select>
        <button
          style="margin-left:8px;"
          class="btn btn-info"
          @click="agregar_bloqueo()"
        >
          Agregar Bloqueo <b-icon
            style="transform:translateY(2px);"
            icon="plus-circle"
          />
        </button>
      </div>
      <h4 style="margin:60px 0px 20px 0px; ">
        Bloqueos existentes
      </h4>
      <div style="box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; border-radius:10px;  padding:10px;">
        <table style=" border-radius:5px; width:100%;">
          <tr>
            <td style="padding:10px;">
              <b>Nombre</b>
            </td>
            <td style="padding:10px;">
              <b>Fecha</b>
            </td>
            <td style="padding:10px;">
              <b>Horario</b>
            </td>
            <td style="padding:10px; text-align:center;">
              <b>Eliminar</b>
            </td>
          </tr>
          <tr v-for="b in bloqueos">
            <td style="padding:10px;">
              <span v-if="profesionales.some(z=>{return z.id == b.profesional})">{{ profesionales.filter(x=>{return x.id == b.profesional})[0].nombre+' '+profesionales.filter(x=>{return x.id == b.profesional})[0].apellido }}</span>
            </td>
            <td style="padding:10px;">
              {{ new Date(b.fecha.a, b.fecha.m-1, b.fecha.d).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' }) }}
            </td>
            <td style="padding:10px;">
              {{ timeConvert(b.desde)+' - '+timeConvert(b.hasta) }} hrs
            </td>
            <td style="padding:10px; text-align:center;">
              <b-icon
                icon="trash"
                style="color:red; cursor:pointer;"
                @click="borrar_bloqueo(b.id)"
              />
            </td>
          </tr>
        </table>
      </div>             
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'


export default {
  name: 'HelloWorld',
  //props:['id'],
  data() {
    return {
      dias:['Lu','Ma','Mi','Ju','Vi','Sa','Dom'],
      dias2:['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],
      loaded:false,
      
      dt_dia:'',
      pro_id:0,
      dia_general:{d:0, m:0, a:0},
      profesionales:[],
      bloqueos:[],
      periodo:60,
      desde_general:0,
      hasta_general:0,
      profesional_id:0,

    }
  },
  created(){
  
    this.initialize_();
  },

  methods:{
    ...mapActions(['initialize']),
   
    agregar_bloqueo(){
      axios.post(window.hostname+'/api/admin/admin_agregar_bloqueos', 
        {id: this.pro_id, 
        desde:this.desde_general, 
        hasta:this.hasta_general, 
        d:this.dia_general.d, 
        m:this.dia_general.m, 
        a: this.dia_general.a})
        .then(response => 
        {
            
            this.initialize_();
        })

        .catch(e=>{

            console.log("Error al cargar...")
        })

    },
    borrar_bloqueo(bid){
      axios.post(window.hostname+'/api/admin/admin_borrar_bloqueos', 
        {id: bid})
        .then(response => 
        {
            
            this.initialize_();
        })

        .catch(e=>{

            console.log("Error al cargar...")
        })

    },
   
        timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },
    
      initialize_(){
        this.loaded = false;
      axios.get(window.hostname+'/api/admin/initialize_bloqueos')
      .then(r=>{
        this.loaded = true
        this.profesionales = r.data.profesionales
        this.bloqueos = r.data.bloqueos

      })
      .catch(r=>{
        this.loaded = true
        
        })
      
    },

        agregar_horario(agenda_id, inicio, termino, repeticion){

      var error_msg = ''
      if(agenda_id == 0)
          error_msg = 'Por favor, ingrese una agenda. '
      if(inicio == -1)
        error_msg += 'Por favor, ingrese una hora de inicio. '
      if(termino == -1)
        error_msg += 'Por favor, ingrese una hora de término. '

      if(inicio > termino)
        error_msg += 'La hora de inicio no puede ser mayor a la de término. '  
      var x = '';
      for (var i = 0; i < repeticion.length; i++)
        x += repeticion[i].value.toString();
      
      if(x == '0000000')
         error_msg += 'Debes especificar al menos un día. '  

      if(error_msg == ''){

        axios.post(window.hostname+'/api/admin/addHorario', 
        {profesional_id: agenda_id,
          inicio: inicio,
          termino: termino,
          repeticion:x,
          } )
        .then(resp=>{

          this.$notify({duration:5000, title: 'Horario agregado', text: '¡Se ha añadido el horario a la base de datos!', type: 'success'})
          this.initialize_(this.$route.params.id);
        })
        .catch(e=>{ 
          this.$notify({duration:5000, title: 'Error!', text: e.response.data.message, type: 'error'})
        })
      }
      else{
        this.$notify({ title: 'Error', text: error_msg, type: 'error'})
      }

    },

  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'examenes']),
  },
  watch:{
    dt_dia(val){

        var datos = val.split('-')
        this.dia_general = {a:Number(datos[0]), m:Number(datos[1]), d:Number(datos[2])}
      
    },
  },
  mounted() {
    var hoy = new Date()
    this.dt_dia = hoy.toISOString().split('T')[0]
  }

}
</script>


<style scoped>
td{
  text-align: left;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}

</style>

