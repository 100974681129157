<template>
  <div class="container-fluid">
    <div v-if="loaded">
      <h2>Modificar Código con ID {{ $route.params.id }} <b-icon icon="card-text" /></h2>


      <table style="width:100%;">
        <tr>
          <td />
          <td>
            <div>
              <select
                id=""
                name=""
                @change="(e)=>{editar_por_porcentaje(e.target.options.selectedIndex)}"
              >
                <option
                  v-for="x in 11"
                  :value="(x-1)*10"
                >
                  {{ (x-1)*10 }}%
                </option>
              </select>
            </div>
          </td>
        </tr>
        <tr v-for="edit, i in editando_codigo">
          <td style="width:500px;">
            {{ examenes[i].nombre }} (${{ Intl.NumberFormat('de-DE').format(examenes[i].precio) }})
        
            <b-icon
              v-if="!edit.editando"
              style="cursor:pointer; color:blue;"
              icon="pen"
              @click="edit.editando = true; $forceUpdate();"
            />
            <b-icon
              v-if="edit.editando"
              style="cursor:pointer; color:green;"
              icon="check-circle"
              @click="editar_valores_codigos({examen_id:edit.id, valor:edit.valor}); $forceUpdate();"
            />
            <b-icon
              v-if="edit.editando"
              style="cursor:pointer; color:red;"
              icon="x-circle"
              @click="edit.editando = false; $forceUpdate();"
            />
            <!--
        <b-icon v-if="editando[i]" style="cursor:pointer; color:red;" v-on:click="cambiarEditando(i)" icon="x-circle"></b-icon>
        <b-icon v-if="editando[i]" style="cursor:pointer; color:green " v-on:click="editar_descuento(); cambiarEditando(i);"  icon="check-circle"></b-icon>
      -->
          </td>
          <td>
            <div v-if="!edit.editando">
              {{ edit.valor }}
            </div>
            <div v-else>
              <input
                id=""
                v-model="edit.valor"
                type="text"
                name=""
              >
            </div>
          </td>
          <!--
      <td v-for="ex, j in ase">
        <input style="width:70px;"  v-if="editando[i]" v-model="editando_linea[j].valor" type="text"> <span v-else>{{editando_descuentos[i][j].valor}}</span>

      </td>
      -->
        </tr>
      </table>
      <div style="text-align:left; margin-top:10px;" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';


export default {
  name: 'HelloWorld',
  props:['id'],
  data() {
    return {

      loaded:false,
      codigo:[],
      editando_codigo:[],

    }
  },
  created(){
  
    this.initialize_();
  },

  methods:{
    ...mapActions(['initialize']),

    editar_valores_codigos(d){
    axios.post(window.hostname+'/api/admin/editar_valores_codigos',{valores:[d], codigo_id:this.$route.params.id})
        .then(response => 
        {
          this.initialize().then(()=>{
          this.initialize_();
          })
          
        })

        .catch(e=>{
            console.log("Error al cargar...")
        })
        
  },

  editar_por_porcentaje(porcentaje)
  {
    let edit = []
    for(var i = 0; i < this.examenes.length; i++){
      edit.push({examen_id:this.examenes[i].id, valor:Math.floor((porcentaje/10) * this.examenes[i].precio)})
    }


    axios.post(window.hostname+'/api/admin/editar_valores_codigos',{valores:edit, codigo_id:this.$route.params.id})
    .then(response => 
    {
      this.initialize().then(()=>{
      this.initialize_();
      
      })
      
    })

    .catch(e=>{
        console.log("Error al cargar...")
    })
        
  },





    
  initialize_(){
    
    //this.loaded = false;
    
      axios.post(window.hostname+'/api/admin/init_editar_codigo', {codigo_id: this.$route.params.id})
      .then(response => 
      {

        
        this.codigo = response.data.codigo;
        let edit = this.examenes
        console.log(edit)
        for(var i = 0; i < edit.length; i++){
          edit[i].editando = false;
          edit[i].valor = (this.codigo.valores.find(x=>{return x.examen_id == edit[i].id}) || {valor:0}).valor
        }

        this.editando_codigo = edit;
        this.loaded = true;
      })         
      .catch(e=>
      {
          this.loaded = true;
      })

  },


  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'examenes', 'aseguradoras', 'descuentos']),
  },
  watch:{

  },
  mounted() {

  }

}
</script>


<style scoped>
td{
  text-align: left;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}

</style>

