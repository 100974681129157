<template>
  <div class="">
    <div class="container-fluid">
      <div
        v-if="popup"
        class="shadow-object"
        style="position:fixed; text-align:left; padding:3px;  z-index:9999; width: 200px;"
        :style="{top: mouseY+'px', left: mouseX-100+'px'}"
        @mouseover="popup = true;"
        @mouseleave="popup = false;"
      >
        <div>
          <span style="font-weight:700;">Opciones</span>
          <div
            style="cursor:pointer;"
            @click="copiar_link_pago()"
          >
            Copiar link de pago
          </div>
          <div
            style="cursor:pointer;"
            @click="marcar_pagado()"
          >
            Marcar como pagado
          </div>
          <div
            style="cursor:pointer;"
            @click="goto_modificar()"
          >
            Modificar Orden
          </div>
          <div
            style="cursor:pointer;"
            @click="goto_receta()"
          >
            Detalles de la orden
          </div>

          <div
            style="cursor:pointer;"
            @click="goto_modificar_hora()"
          >
            Modificar Horario
          </div>
          <div
            class="cmt"
            style="cursor:pointer;"
            @click="comentando = true"
          >
            Comentar
          </div>
          <div
            class="result"
            style="cursor:pointer;"
            @click="subiendo_resultados.subiendo = true"
          >
            Subir resultados
          </div>
          <div
            class="result"
            style="cursor:pointer;"
            @click="cambiar_profesional_abrir()"
          >
            Cambiar profesional
          </div>
          <div
            style="cursor:pointer;"
            @click="eliminarOrden()"
          >
            Eliminar orden
          </div>
        </div>
      </div>
      <h2 style="margin-bottom:20px;">
        Órdenes <b-icon icon="cart2" />
      </h2>
      <div
        class=""
        style="position:relative; padding-top:40px;"
      >
        <div style="position:absolute; right:450px; top:7px;">
          <button
            class="menuCol btn btn-dark"
            @click="menuCol = !menuCol"
          >
            Ver Columnas
          </button>
        </div>
        <div
          v-if="menuCol"
          class="menuCol"
          style="position:absolute; right:380px; top:47px; background-color:white; width:200px; border: 1px solid rgb(140, 140, 140); border-radius:5px; z-index:100;"
        >
          <div
            v-for="(c, key) in cols"
            :key="key"
            class="menuCol"
            style="cursor:pointer; width:100%; text-align:left; padding-left:5px;"
            @click=" setCol(c.var)"
          >
            {{ c.nombre }}
        
            <b-icon
              v-if="getVal(c.var) == 'true'"
              class="menuCol"
              style="color:green;"
              icon="check"
            />
            <b-icon
              v-else
              class="menuCol"
              style="color:red;"
              icon="x-circle"
            />
          </div>
        </div>
    
        <div style="position:absolute; right:0px; top:10px;">
          Desde <input
            id="x2"
            v-model="dt_dia"
            type="date"
          > Hasta: <input
            id="x2"
            v-model="dt_dia2"
            type="date"
          >
        </div>
        <div
          style="position:absolute; left:0px; top: 10px; border:2px solid; border-radius:10px; padding:5px 10px; cursor:pointer; color:#1D6F42;"
          @click="descargarCSV()"
        >
          Descargar EXCEL <b-icon icon="cloud-download" />
        </div>
        <div
          style="position:absolute; left:390px; top: 10px; border:2px solid; border-radius:10px; padding:5px 10px; cursor:pointer; color:#1D6F42;"
          @click="descargarOrdenesMedicas()"
        >
          Descargar Ordenes medicas <b-icon icon="cloud-download" />
        </div>
        <div
          style="position:absolute; left:180px; top: 10px; border:2px solid; border-radius:10px; padding:5px 10px; cursor:pointer; color:#1D6F42;"
          @click="descargarCSVPago()"
        >
          Descargar CSV Pagos <b-icon icon="cloud-download" />
        </div>
        <table style="width:100%; margin-top:20px; color:white; margin-bottom:270px;">
          <tr style="background-color:black; ">
            <td
              v-if="c1 == 'true'"
              style="border-bottom: 1px solid black; border-left: 1px solid black;"
            >
              id
            </td>
            <td
              v-if="c1 == 'true'"
              style="border-bottom: 1px solid black; border-left: 1px solid black;"
            >
              Prestador Dueño
            </td>
            <td
              v-if="c1 == 'true'"
              style="border-bottom: 1px solid black; border-left: 1px solid black;"
            >
              Prestador Tomador
            </td>
            <td
              v-if="c1 == 'true'"
              style="border-bottom: 1px solid black; border-left: 1px solid black;"
            >
              De Marketplace
            </td>
            <td
              v-if="c18 == 'true'"
              style="border-bottom: 1px solid black; border-left: 1px solid black;"
            >
              Profesional
            </td>
            <td
              v-if="c2 == 'true'"
              style="border-bottom: 1px solid black; border-left: 1px solid black;"
            >
              Fecha
            </td>
            <td
              v-if="c3 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              Nombre
            </td>
            <td
              v-if="c4 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              Rut
            </td>
            <td
              v-if="c5 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              Email
            </td>
            <td
              v-if="c6 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              Teléfono
            </td>
            <td
              v-if="c7 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              Monto
            </td>
            <td
              v-if="c8 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              Descuento
            </td>
            <td
              v-if="c19 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              Recargo
            </td>
            <td
              v-if="c9 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              Horario
            </td>
            <td
              v-if="c10 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              Estado
            </td>
            <td
              v-if="c11 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              Pagado
            </td>
            <td
              v-if="c20 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              Resultados
            </td>
            <td
              v-if="c12 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              Receta Médica
            </td>
            <td
              v-if="c13 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              Comuna
            </td>
            <td
              v-if="c14 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              Dirección
            </td>
            <td
              v-if="c15 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              Comentarios
            </td>
            <td
              v-if="c16 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              Historial
            </td>
            <td
              v-if="c17 == 'true'"
              style="border-bottom: 1px solid black; border-right: 1px solid black;"
            >
              Exámenes
            </td>
          </tr>
          <tr
            v-for="(o, idx) in ordenes_calculated"
            :key="idx"
            :style="{'background-color': o.urgente && o.estado == 1 ? '#FFDC00': 'white', 'color': o.urgente ? 'black': 'black', 'opacity':o.estado == 0 ? '0.3' : '1'}"
            style="vertical-align:top; cursor:pointer;"
            @click="mouseEnter($event, o.id, timeConvert(o.desde), timeConvert(o.hasta), o.profesional)"
          >
            <td
              v-if="c1 == 'true'"
              style="border-left: 1px solid black; border-bottom: 1px solid black;"
            >
              {{ o.id }}
            </td>
            <td
              v-if="c1 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              <span
                :style="o.orden_propia ? 'color:blue;' : 'color:red;'"
              >
                {{ o.prestador }}
              </span>
            </td>
            <td
              v-if="c1 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              <span
                :style="o.orden_tomada ? 'color:blue;' : 'color:red;'"
              >
                {{ o.prestador_tomador }}
              </span>
            </td>
            <td
              v-if="c1 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              <span
                :style="o.markeplace ? 'color:blue;' : 'color:red;'"
              >
                {{ o.markeplace ? 'SI' : 'NO' }}
              </span>
            </td>
            <td
              v-if="c18 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              <span
                :style="`
                  color: ${o.profesional != null ? 'black' : 'red'};
                `"
              >
                {{
                  o.profesional != null ?
                    o.profesional.nombre + ' ' + o.profesional.apellido : 'SIN ASIGNAR'
                }}
              </span>
            </td>
            <td
              v-if="c2 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              {{ new Date(o.fecha.a, o.fecha.m-1, o.fecha.d).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' }) }}
            </td>
            <td
              v-if="c3 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              <div v-for="cl in o.clientes">
                {{ cl.nombre }} {{ cl.apellido1 }} {{ cl.apellido2 }}
              </div>
            </td>
            <td
              v-if="c4 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              <div v-for="cl in o.clientes">
                {{ cl.rut }}
              </div>
            </td>
            <td
              v-if="c5 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              <div v-for="cl in o.clientes">
                {{ cl.email }}
              </div>
            </td>
            <td
              v-if="c6 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              <div v-for="cl in o.clientes">
                {{ cl.telefono }}
              </div>
            </td>
            <td
              v-if="c7 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              ${{ Intl.NumberFormat('de-DE').format(o.monto) }}
            </td>
            <td
              v-if="c8 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              ${{ Intl.NumberFormat('de-DE').format(o.descuento) }}
            </td>
            <td
              v-if="c19 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              ${{ Intl.NumberFormat('de-DE').format(o.monto_recargo) }}
            </td>
            <td
              v-if="c9 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              {{ timeConvert(o.desde) }}-{{ timeConvert(o.hasta) }}
            </td>
            <td
              v-if="c10 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              <select
                id=""
                v-model="o.estado"
                name=""
                style="padding:5px;"
                class="no-menu form-select form-select-lg mb-3"
                @change="cambiarEstado2(o.id, o.estado)"
              >
                <option
                  v-for="e, z in estados"
                  :value="z"
                >
                  {{ e }}
                </option>
              </select>
            </td>
            <td
              v-if="c11 == 'true'"
              style="border-bottom: 1px solid black; text-align:center;"
            >
              <b-icon
                v-if="o.pagado == 1"
                style="color:green; height:50px; width:50px;"
                icon="check"
              /> <b-icon
                v-else
                style="color:red; height:40px; width:40px;"
                icon="x-circle"
              />
            </td>
            <td
              v-if="c20 == 'true'"
              style="border-bottom: 1px solid black; text-align:center;"
            >
              <b-icon
                v-if="o.clientes.reduce((x, y)=>{return x + y.resultados.length},0) > 0"
                style="color:green; height:50px; width:50px;"
                icon="check"
              /> <b-icon
                v-else
                style="color:red; height:40px; width:40px;"
                icon="x-circle"
              />
            </td>
            <td
              v-if="c12 == 'true'"
              style="border-bottom: 1px solid black; text-align:center;"
            >
              <b-icon
                v-if="!requierenReceta(o.examenes, o.clientes, o.recetas)"
                style="color:green; height:50px; width:50px;"
                icon="check"
              /> <b-icon
                v-else
                style="color:red; height:40px; width:40px;"
                icon="x-circle"
              />
            </td>
            <td
              v-if="c13 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              {{ comunas.filter(x=>{return x.id == o.comuna_id})[0].Nombre }}
            </td>
            <td
              v-if="c14 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              {{ o.direccion }} ({{ o.departamento == 1 ? 'Departamento '+o.n_depto : 'Casa' }})
            </td>
            <td
              v-if="c15 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              <div v-for="c in o.comentarios.slice().filter(x=>{return x.tipo == 2}).sort(compareDt)">
                <b-icon
                  v-if="admin_datos.id == c.autor.id"
                  class="no-menu"
                  style="color:red"
                  icon="trash"
                  @click="eliminarComentario(c.id)"
                /> <b>{{ new Date(c.fecha.a, c.fecha.m-1, c.fecha.d, c.fecha.h, c.fecha.min).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' }) }} por {{ c.autor.nombre }}:</b> {{ c.texto }}
              </div>
            </td>
            <td
              v-if="c16 == 'true'"
              style="border-bottom: 1px solid black;"
            >
              <div
                v-for="c, i in o.comentarios.slice().filter(x=>{return x.tipo == 1}).sort(compareDt)"
                v-if="(i < 2 || o.viendoHistorial)"
              >
                <b>{{ new Date(c.fecha.a, c.fecha.m-1, c.fecha.d).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' }) }}:</b> {{ c.texto }}
              </div><div style="width:100%; text-align:center; padding:10px 0px;">
                <button
                  v-if="o.comentarios.slice().filter(x=>{return x.tipo == 1}).length > 2"
                  style="margin: 0 auto; min-width:110px;"
                  class=" btn btn-info no-menu"
                  @click="o.viendoHistorial = !o.viendoHistorial; $forceUpdate();"
                >
                  {{ o.viendoHistorial ? 'Esconder historial': 'Mostrar Historial' }}
                </button>
              </div>
            </td>
            <td
              v-if="c17 == 'true'"
              style="border-right: 1px solid black; border-bottom: 1px solid black;"
            >
              <div v-for="e in o.examenes">
                -{{ e.cantidad }} x {{ examenes.filter(x=> {return x.id == e.examen_id}).length > 0 ? examenes.filter(x=> {return x.id == e.examen_id})[0].nombre : 'INDEFINIDO' }}
              </div>
            </td>
          </tr>
          <tr style="border-top: 1px solid black;" />
        </table>
      </div>
    </div>


    <!-- modal de comentarios -->
    <div v-if="comentando">
      <div style="position:fixed; background-color:black; height:100vh; width:100vw; top:0px; z-index:10000; opacity:0.5;" />

      <div
        class="cmt" 
        style="position:fixed; border-radius:40px; padding:20px 30px 30px 30px; background:white; top:Calc(50% - 200px); height:400px; width:800px; max-width:90%; margin: 0px auto; left: 0; right: 0; opacity:1; z-index:10001;"
      >
        <div class="cmt">
          <h4
            class="cmt"
            style="margin-bottom:20px;"
          >
            Comenta la orden {{ id_orden }}
          </h4>
          <b-icon
            class="cmt"
            style="position:absolute; cursor:pointer; height:30px; width:30px; top:15px; right:15px;"
            icon="x-circle"
            @click="comentando = false;"
          />
          <div
            class="cmt"
            style="padding:0px 10px 0px 0px;"
          >
            <textarea
              id="w3review"
              v-model="comentario"
              name="w3review"
              class="cmt"
              style="resize: none; width:100%; height:250px;"
              rows="4"
              cols="50"
            />   
          </div>

          <div
            class="cmt"
            style="position:absolute; right:20px;"
          >
            ({{ comentario.length }}/500)
          </div>
          <div
            class="cmt"
            style="width:100%;"
          >
            <button
              class="btn btn-dark"
              style="margin: 10px;"
              @click="comentar()"
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>


    <!-- modal subir resultados -->

    <div v-if="subiendo_resultados.subiendo">
      <div style="position:fixed; background-color:black; height:100vh; width:100vw; top:0px; z-index:10000; opacity:0.5;" />

      <div
        class="result" 
        style="position:fixed; border-radius:40px; padding:20px 30px 30px 30px; background:white; top:Calc(50% - 200px); height:400px; width:800px; max-width:90%; margin: 0px auto; left: 0; right: 0; opacity:1; z-index:10001;"
      >
        <div class="result">
          <h4
            class="result"
            style="margin-bottom:20px;"
          >
            Subiendo resultados para la orden: {{ id_orden }}
          </h4>

          <div
            v-for="o in ordenes_calculated.find(z=>{ return z.id == id_orden}).clientes"
            :key="o.id"
            class="result"
          >
            {{ o.nombre }} {{ o.apellido1 }} {{ o.apellido2 }}
            <input
              :id="'file'+o.id"
              :ref="'file'+o.id"
              type="file"
              class="w-px h-px opacity-0 overflow-hidden absolute result"
              style="display:none;"
              accept=".pdf,.jpg,.jpeg,.png"
              @change="onChange(o.id)"
            >
            <div class="result">
              <label
                :for="'file'+o.id"
                class="block cursor-pointer result"
              >
                        
                <div
                  class="result"
                  style="width:100%;"
                >
                  <div
                    :for="'file'+o.id"
                    class="btn btn-info result"
                  >Examinar <b-icon
                    icon="search"
                    class="result"
                    style="transform:translateY(3px);"
                  /></div>
                </div>                        
                        
              </label>
            </div>
            <div v-for="x in subiendo_resultados.resultados.filter(y=>{return y.cliente_id == o.id})">
              {{ x.resultado.name }} <b-icon
                class="result"
                icon="x-circle"
                style="color:red;"
                @click=""
              />
            </div>
          </div> 
          <b-icon
            class="result"
            style="position:absolute; cursor:pointer; height:30px; width:30px; top:15px; right:15px;"
            icon="x-circle"
            @click="limpiar_subiendo_resultados();"
          />
          <div
            class="result"
            style="padding:0px 10px 0px 0px;"
          />

    
          <div
            class="result"
            style="width:100%;"
          >
            <button
              class="btn btn-dark"
              style="margin: 10px;"
              @click="subir_resultados()"
            >
              Subir Resultados <b-icon
                class="result"
                icon="reply"
                style="color:white;"
              />
            </button>
          </div>
        </div>
      </div>
    </div>


    <!-- modal cambiar profesional -->
    <div v-if="cambiar_profesional.cambiando">
      <div style="position:fixed; background-color:black; height:100vh; width:100vw; top:0px; z-index:10000; opacity:0.5;" />

      <div
        class="result" 
        style="position:fixed; border-radius:40px; padding:20px 30px 30px 30px; background:white; top:Calc(50% - 200px); height:400px; width:800px; max-width:90%; margin: 0px auto; left: 0; right: 0; opacity:1; z-index:10001;"
      >
        <div class="result">
          <h4
            class="result"
            style="margin-bottom:20px;"
          >
            Cambiando profesional para la orden: {{ id_orden }}
          </h4>

          <b-icon
            class="result" 
            style="position:absolute; cursor:pointer; height:30px; width:30px; top:15px; right:15px;"
            icon="x-circle"
            @click="cambiar_profesional_cerrar();"
          />

          <div v-if="cambiar_profesional.cargado == true">
            <div
              class="result"
            >
              <select
                v-model="cambiar_profesional.profesional"
                style="padding:5px;"
                class="no-menu form-select form-select-lg mb-3"
                @change="cambiar_profesional_seleccionar_profesional"
              >
                <option 
                  v-for="(profesional) in cambiar_profesional.profesionales"
                  :key="profesional.id"
                  :value="profesional"
                >
                  {{ profesional.nombre }} {{ profesional.apellido }}
                </option>
              </select>
              <div v-if="cambiar_profesional.profesionales.length == 0">
                No se encontraron profesionales disponibles
              </div>
              <div v-if="cambiar_profesional.profesional && cambiar_profesional.horarios.length > 0">
                <select
                  v-model="cambiar_profesional.horario"
                  style="padding:5px;"
                  class="no-menu form-select form-select-lg mb-3"
                  @change="cambiar_profesional_seleccionar_horario"
                >
                  <option 
                    v-for="(horario) in cambiar_profesional.horarios"
                    :key="horario.id"
                    :value="horario"
                  >
                    Horario {{ horario.id }} | {{ minutes_to_time(horario.inicio) }} - {{ minutes_to_time(horario.termino) }} | Prioridad {{ horario.prioridad }}
                  </option>
                </select>
              </div>
              <div v-else-if="cambiar_profesional.profesional && cambiar_profesional.horario.length == 0">
                No se encontraron horarios disponibles
              </div>
              <div v-if="cambiar_profesional.profesional && cambiar_profesional.horario && cambiar_profesional.horas.length > 0">
                <select
                  v-model="cambiar_profesional.hora"
                  style="padding:5px;"
                  class="no-menu form-select form-select-lg mb-3"
                >
                  <option 
                    v-for="(hora, key) in cambiar_profesional.horas"
                    :key="key"
                    :value="{ desde: hora.start_time, hasta: hora.end_time }"
                  >
                    {{ hora.start_time }} - {{ hora.end_time }}
                  </option>
                </select>
              </div>
              <div v-else-if="cambiar_profesional.profesional && cambiar_profesional.horario && cambiar_profesional.horas.length == 0">
                No se encontraron horas disponibles
              </div>
            </div>
            <div v-if="cambiar_profesional.profesional && cambiar_profesional.horario && cambiar_profesional.horas.length > 0 && cambiar_profesional.hora">
              <div>
                <table>
                  <tr>
                    <th> Apartado </th>
                    <th> Nuevo </th>
                    <th> Antiguo </th>
                  </tr>
                  <!-- <tr>
                    <td> <strong> Fecha </strong>  </td>
                    <td> {{ dt_dia }} </td>
                    <td> {{ dt_dia }} </td>
                  </tr> -->
                  <tr>
                    <td> <strong> Profesional </strong> </td>
                    <td> {{ cambiar_profesional.profesional.nombre }} {{ cambiar_profesional.profesional.apellido }} </td>
                    <td> {{ profesional }} </td>
                  </tr>
                  <tr>
                    <td> <strong> Hora inicio </strong> </td>
                    <td> {{ cambiar_profesional.hora.desde }} </td>
                    <td> {{ orden_desde }} </td>
                  </tr>
                  <tr>
                    <td> <strong> Hora fin </strong> </td>
                    <td> {{ cambiar_profesional.hora.hasta }} </td>
                    <td> {{ orden_hasta }} </td>
                  </tr>
                </table> 
              </div>
              <button
                class="btn btn-dark"
                style="margin: 10px;"
                @click="cambiar_orden_a_profesional"
              >
                Cambiar profesional <b-icon
                  class="result"
                  icon="reply"
                  style="color:white;"
                />
              </button>
            </div>
          </div>
          <div v-else>
            Buscando profesionales disponibles...
          </div>

          <div
            class="result"
            style="padding:0px 10px 0px 0px;"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { NavigationFailureType } from 'vue-router';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'VerOrdenes',
  data() {
    return {

      subiendo_resultados:{
        orden_id: 0,
        resultados:[],
        subiendo:false,
      },

      cambiar_profesional: {
        cambiando: false,
        profesional: null,
        profesionales: [],
        horario: null,
        horarios: [],
        horas: [],
        hora: null,
        cargado: false,
      },

      email:'',
      nombre:'',
      apellido:'',
      rut:'',

      profesional: '',
      orden_desde: '',
      orden_hasta: '',

      dt_dia:'',
      dt_dia2:'',

      desde:{d:0, m:0, a:0},
      hasta:{d:0, m:0, a:0},

      ordenes:[],
      ordenes_calculated:[],
      id_orden:0,

      popup: false,
      mouseX:0,
      mouseY:0,

      call_n:0,

      comentando:false,
      comentario:'',

      resultado:'',
      estados : ['Anulado', 'Citado',  'Confirmado', 'Atendido', 'No Atendido'],
      menuCol:false,
      cols:[
        {nombre:'Id', var:'c1'},        
        {nombre:'Fecha', var:'c2'},
        {nombre:'Nombre', var:'c3'},
        {nombre:'Rut', var:'c4'},
        {nombre:'Email', var:'c5'},
        {nombre:'Teléfono', var:'c6'},
        {nombre:'Monto', var:'c7'},
        {nombre:'Descuento', var:'c8'},
        {nombre:'Horario', var:'c9'},
        {nombre:'Estado', var:'c10'},
        {nombre:'Pagado', var:'c11'},
        {nombre:'Receta Médica', var:'c12'},
        {nombre:'Comuna', var:'c13'},
        {nombre:'Dirección', var:'c14'},
        {nombre:'Comentarios', var:'c15'},
        {nombre:'Historial', var:'c16'},
        {nombre:'Exámenes', var:'c17'},
        {nombre:'Profesional', var:'c18'},
        {nombre:'Recargo', var:'c19'},
        {nombre:'Resultados', var:'c20'},
      ],

      c1: localStorage.getItem('c1') || 'true',
      c2: localStorage.getItem('c2') || 'true',
      c3: localStorage.getItem('c3') || 'true',
      c4: localStorage.getItem('c4') || 'true',
      c5: localStorage.getItem('c5') || 'true',
      c6: localStorage.getItem('c6') || 'true',
      c7: localStorage.getItem('c7') || 'true',
      c8: localStorage.getItem('c8') || 'true',
      c9: localStorage.getItem('c9') || 'true',
      c10: localStorage.getItem('c10') || 'true',
      c11: localStorage.getItem('c11') || 'true',
      c12: localStorage.getItem('c12') || 'true',
      c13: localStorage.getItem('c13') || 'true',
      c14: localStorage.getItem('c14') || 'true',
      c15: localStorage.getItem('c15') || 'true',
      c16: localStorage.getItem('c16') || 'true',
      c17: localStorage.getItem('c17') || 'true',
      c18: localStorage.getItem('c18') || 'true',
      c19: localStorage.getItem('c19') || 'true',
      c20: localStorage.getItem('c20') || 'true',


    }
  },
  methods:{

    ...mapActions(['loginAdmin']),
    copiar_link_pago(){
        axios.post(window.hostname+'/api/admin/obtener_link_pago', 
        {orden_id:this.id_orden})
        .then(response => 
        {

          navigator.clipboard.writeText(response.data.link);
          this.popup = false;
          this.$notify({
          group: 'foo',
          title: '¡Éxito!',
          text: '¡Se ha copiado el link de pago!',
          type:'success'
          });
        })

        .catch(e=>{
          console.log(e.response)
          this.popup = false;
         
        })
    },
    onChange(cliente_id){

          console.log(this.$refs['file'+cliente_id][0].files[0], "LAA")
         if(["jpg", "png", "jpeg", "pdf"].includes(this.$refs['file'+cliente_id][0].files[0].name.split(".")[1].toLowerCase()))
            this.subiendo_resultados.resultados.push({cliente_id: cliente_id, resultado:this.$refs['file'+cliente_id][0].files[0]});

    },
    getVal(x){
      return this[x]
    },
    setCol(x){
      
      if(this[x] == 'true')
        this[x] = 'false'
      else
        this[x] = 'true'

      localStorage.setItem(x, this[x])

      

    },
    descargarCSV(){
      axios({
        url: window.hostname+'/api/admin/exportar_ordenes',
        method: 'POST',
        responseType: 'blob',
        data: {
          dia:this.desde.d, mes:this.desde.m, año:this.desde.a,
          dia2:this.hasta.d, mes2:this.hasta.m, año2:this.hasta.a
        }
      })
      .then( (response) =>  {
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'exportar.xlsx'); 
        document.body.appendChild(link);
        link.click();          
      })
      .catch(e=>{
        console.log(e);
      })

    },
      descargarCSVPago(){
        axios({
          url: window.hostname+'/api/admin/exportar_excel_pago',
          method: 'POST',
          responseType: 'blob',
          data: {
            dia:this.desde.d, mes:this.desde.m, año:this.desde.a,
            dia2:this.hasta.d, mes2:this.hasta.m, año2:this.hasta.a
          }
        })
        .then(response =>{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'exportar_pago.csv'); 
            document.body.appendChild(link);
            link.click();          
        })
        .catch( (e) =>{
          console.log(e)
        })

    },

    descargarOrdenesMedicas(){
        axios({
          url: window.hostname+'/api/admin/descargar_ordenes_medicas',
          method: 'POST',
          data: {
            day_from:this.desde.d, month_from:this.desde.m, year_from:this.desde.a,
            day_to:this.hasta.d, month_to:this.hasta.m, year_to:this.hasta.a
          },
          responseType: 'blob', // Important
        })
        .then(response =>{
          if(response.data.type != 'application/json')
          {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'ordenes_medicas.zip'); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
          else{
            this.$notify({
          group: 'foo',
          title: '¡Error!',
          text: 'No hay ordenes médicas para el periodo seleccionado.',
          type:'error'
          });
          }
  
        })
        .catch( (e) =>{
          console.log(e)
        })

    },
    eliminarComentario(cid){
        axios.post(window.hostname+'/api/admin/eliminar_cometnario', 
        {comentario_id:cid})
        .then(response => 
        {
          this.get_ordenes()
          this.popup = false;
        })

        .catch(e=>{
          console.log(e.response)
         
        })

    },
    eliminarOrden(cid){
        axios.post(window.hostname+'/api/admin/borrar_orden', 
        {orden_id:this.id_orden})
        .then(response => 
        {
          this.get_ordenes()
          this.popup = false;
        })

        .catch(e=>{
          console.log(e.response)
          this.popup = false;
         
        })

    },
    requierenReceta(examenes, clientes, recetas){
      let requieren = []
      let examen_f = []
      for (var c of clientes){
        for(var e of examenes.filter(x=>{return x.cliente_id == c.id}))
        {
          examen_f = this.examenes.filter(x=>{return x.id == e.examen_id})
          if (examen_f.length > 0)
          {
            if (this.examenes.filter(x=>{return x.id == e.examen_id})[0].requiere_orden == 1)
            {
              requieren.push(c.id)
              break
            }
          }
     
        }
        //console.log(this.examenes.filter(x=>{return x.id == e.examen_id}))
      }
      for(var c of requieren)
      {
        if(!recetas.some(l=>{return l.cliente_id == c}))
          return true
      }
    return false;
    },
    cambiarEstado(e){
        axios.post(window.hostname+'/api/admin/cambiarEstado', 
        {orden_id:this.id_orden, estado_id:e})
        .then(response => 
        {
          this.get_ordenes()
          this.popup = false;
        })

        .catch(e=>{
          console.log(e.response)
          if (e.response.data.message)
            this.resultado = e.response.data.message           
        })

    },
      cambiarEstado2(o, e){
        axios.post(window.hostname+'/api/admin/cambiarEstado', 
        {orden_id:o, estado_id:e})
        .then(response => 
        {
          this.get_ordenes()
          this.popup = false;
          this.$notify({
          group: 'foo',
          title: '¡Éxito!',
          text: '¡Se ha cambiado el estado exitosamente!',
          type:'success'
          });
        })

        .catch(e=>{
          console.log(e.response)
          this.$notify({
          group: 'foo',
          title: '¡Error!',
          text: '¡Ha ocurrido un error!',
          type:'error'
          });
          if (e.response.data.message)
            this.resultado = e.response.data.message           
        })

    },
    comentar(){
        if(this.comentario.length <= 500)
        {
        axios.post(window.hostname+'/api/admin/hacer_comentario', 
        {orden_id:this.id_orden, texto:this.comentario})
        .then(response => 
        {
          this.get_ordenes()
          this.comentando = false;
          this.comentario = ''
        })

        .catch(e=>{
          console.log(e.response)
          if (e.response.data.message)
            this.resultado = e.response.data.message           
        })
        }

    },
    limpiar_subiendo_resultados(){
      this.subiendo_resultados.resultados = []
      this.subiendo_resultados.subiendo = false;
    },

    minutes_to_time(min)
    {
      let hora = Math.floor(min / 60);
      let minutos = min % 60;
      if (minutos < 10)
        minutos = `0${minutos}`;
      return `${hora}:${minutos}`;
    },

    async cambiar_profesional_abrir()
    {
      await this.cambiar_profesional_obtener_profesionales_disponibles()
        .then(this.cambiar_profesional.cambiando = true)
    },

    cambiar_profesional_cerrar()
    {
      this.cambiar_profesional = {
        cambiando: false,
        profesionales: [],
        profesional: null,
        horarios: [],
        horario: null,
        horas: [],
        hora: null,
        cargado: false,
      }
    },

    async cambiar_profesional_obtener_profesionales_disponibles()
    {
      await axios.get(window.hostname+'/api/admin/profesional')
      .then( (response) => {
        this.cambiar_profesional.profesionales = response.data
      })
      .then( () => { this.cambiar_profesional.cargado = true })
      .catch( e => { console.log(e.response) })
    },

    async cambiar_profesional_seleccionar_profesional()
    {
      this.cambiar_profesional.horario = null;
      this.cambiar_profesional.horarios = [];
      this.cambiar_profesional.horas = [];
      this.cambiar_profesional.hora = null;
      await this.cambiar_profesional_obtener_ordenes_disponibles();
    },

    async cambiar_profesional_obtener_ordenes_disponibles()
    {
      const profesional = this.cambiar_profesional.profesional.id
      await axios.get(window.hostname+`/api/admin/horario?profesional_id=${profesional}`)
      .then( (response) => {
        this.cambiar_profesional.horarios = response.data;
      })
      .catch( e => { console.log(e.response) } );
    },

    async cambiar_profesional_seleccionar_horario()
    {
      this.cambiar_profesional.horas = [];
      this.cambiar_profesional.hora = null;
      await this.cambiar_profesional_obtener_horas_disponibles();
    },

    async cambiar_profesional_obtener_horas_disponibles()
    {  
      const date = this.dt_dia;
      const horario_id = this.cambiar_profesional.horario.id
      await axios.get(window.hostname+`/api/admin/horario/bloque?date=${date}&selection=available&horario_id=${horario_id}`)
      .then( (response) => {
        this.cambiar_profesional.horas = response.data;
      })
      .catch( e => { console.log(e.response) } );
    },

    async cambiar_orden_a_profesional()
    {
      const orden_id = this.id_orden;
      const profesional_id = this.cambiar_profesional.profesional.id;
      const horario_id = this.cambiar_profesional.horario.id;
      let desde = this.cambiar_profesional.hora.desde;
      desde = parseInt(desde.split(':')[0]) * 60 + parseInt(desde.split(':')[1]);
      await axios.post(window.hostname+'/api/admin/schedule/change_order', {
        orden_id: orden_id,
        profesional_id: profesional_id,
        horario_id: horario_id,
        desde: desde,
      })
      .then( (response) => { alert(response.data.message) })
      .then( async () => { await this.get_ordenes() })
      .then( () => { this.cambiar_profesional_cerrar() })
      .catch( e => { console.log(e.response) })
    },

    subir_resultados(){
        let data = new FormData()
        data.append('orden_id', this.id_orden)

        let res = []
        for(var i = 0; i < this.subiendo_resultados.resultados.length; i++)
        {
          data.append(i, this.subiendo_resultados.resultados[i].resultado)
          res.push({cliente_id:this.subiendo_resultados.resultados[i].cliente_id, key:i})
        }
        const documento = new Blob([JSON.stringify({results:res})], {type:'application/json'});
        data.append('resultados', documento)
        if(this.subiendo_resultados.resultados.length > 0)
        {
          axios({
          method:'post', 
          url:window.hostname+'/api/admin/subir_resultado', 
          data: data,
          })
          .then(response => 
          {
            this.get_ordenes()
          this.$notify({
            group: 'foo',
            title: '¡Éxito!',
            text: '¡Se han subido los resultados exitosamente!',
            type:'success'
            });
            this.limpiar_subiendo_resultados()
          })

          .catch(e=>{
            console.log(e.response)
            if (e.response.data.message)
              this.resultado = e.response.data.message           
          })
        }

    },
    goto_resultados(){
      this.$router.push({ name: 'SubirResultado', params: { ordenId: this.id_orden } })
    },
    goto_modificar(){
      this.$router.push({ path: '/ModificarOrden/'+this.id_orden})
    },
    goto_receta(){
      this.$router.push({ path: '/VerReceta/'+this.id_orden})
    },

    goto_modificar_hora(){
      this.$router.push({ path: '/ModificarHorario/'+this.id_orden})
    },
    marcar_pagado(e){
        axios.post(window.hostname+'/api/admin/marcar_pagado', 
        {orden_id:this.id_orden})
        .then(response => 
        {
          this.get_ordenes()
          this.popup = false;
        })

        .catch(e=>{
          if (e.response.data.message)
            this.resultado = e.response.data.message           
        })

    },
     compareDt(a, b)
        {
          let da = new Date(a.fecha.a, a.fecha.m, a.fecha.d, a.fecha.h, a.fecha.min, a.fecha.seg)
          let db = new Date(b.fecha.a, b.fecha.m, b.fecha.d, b.fecha.h, b.fecha.min, b.fecha.seg)
          if(da > db){
            return -1
          }
          if (da < db){       
            return 1
          }
          return 0

        },
    timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },
    mouseEnter(event, order_id, orden_desde, orden_hasta, profesional) {
        if (profesional == null) {
          profesional = 'No asignado'
        } else {
          profesional = `${profesional.nombre} ${profesional.apellido}`
        }
        console.log(`Mouse enter ${order_id}`)
        if (!event.target.classList.contains('no-menu')){
          this.popup = true;
          this.id_orden = order_id;
          this.orden_desde = orden_desde;
          this.orden_hasta = orden_hasta;
          this.profesional = profesional;
          //this.$el.addEventListener('mousemove', this.mouseMove, false);
          this.mouseX = event.clientX;
          this.mouseY = event.clientY;
        }
    },
    mouseLeave(event) {
      if (!(event.clientX >= this.mouseX && event.clientY >= this.mouseY))
      {

        this.popup = false;
      }

    },
    close (e){
      /*Si clickeo (e.target) fuera de este componente (this.$el), se cierra*/
      if (!(e.target.classList.contains('dontClose')))
        this.showinFuncionesList = false

      if (!e.target.classList.contains('cmt') && this.comentando)
        this.comentando = false;

      if (!e.target.classList.contains('result') && this.subiendo_resultados.subiendo)
          this.limpiar_subiendo_resultados()

      if (!e.target.classList.contains('menuCol'))
        this.menuCol = false;
    },
    async get_ordenes(){
      this.call_n += 1;
      let call_n = this.call_n
      if (this.desde.a != 0 && this.hasta.a != 0)
      {
        axios.post(window.hostname+'/api/admin/get_ordenes_dia', 
          {
            dia:this.desde.d, mes:this.desde.m, año:this.desde.a,
            dia2:this.hasta.d, mes2:this.hasta.m, año2:this.hasta.a
          }
        )
        .then(response => 
        {
          if(call_n == this.call_n) {
            this.ordenes = response.data.ordenes
            this.ordenes = this.ordenes.filter(orden => orden.orden_propia == true || orden.orden_tomada == true)
            function compare(a, b) {
              let da = new Date(a.fecha.a, a.fecha.m, a.fecha.d, Math.floor(a.desde/60))
              let db = new Date(b.fecha.a, b.fecha.m, b.fecha.d, Math.floor(b.desde/60))
              if ((a.urgente && a.estado == 1)  && !(b.urgente && b.estado == 1)){
                return -1                
              }
              if (!(a.urgente && a.estado == 1) && (b.urgente && b.estado == 1)){
                return 1
              }
              if(da > db){
                return 1
              }
              if (da < db){       
                return -1
              }
              return 0
            }

            this.ordenes_calculated = []
            var ins = {}
            var hoy = new Date();
            var dia = new Date();
            for (var i = 0; i < this.ordenes.length; i++){
              ins = this.ordenes[i]
              dia = new Date(ins.fecha.a, ins.fecha.m-1, ins.fecha.d)
              if ((((dia.getTime()+(1000 * 60 * ins.desde))-hoy.getTime())/(1000 * 60 * 60)) < 2*24)
                ins.urgente = true;
              else
                ins.urgente = false;
              ins.viendoHistorial = false;
              this.ordenes_calculated.push(ins)
            }
            this.ordenes_calculated.sort(compare)
            console.log(this.ordenes_calculated)
          }
        })
        .catch(e=>{
          console.log(e)        
        })
      }
    }

  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'examenes']),

  },
  watch:{
    dt_dia(val){

        var datos = val.split('-')
        this.desde = {a:Number(datos[0]), m:Number(datos[1]), d:Number(datos[2])}
        this.get_ordenes()
      
    },
    dt_dia2(val){

        var datos = val.split('-')
        this.hasta = {a:Number(datos[0]), m:Number(datos[1]), d:Number(datos[2])}
        this.get_ordenes()
      
    },

  },
  mounted() {
    console.log("c1", localStorage.getItem('c1'))
      this.$forceUpdate()
    document.addEventListener('click', this.close)   
    var hoy = new Date()
    this.dt_dia = hoy.toISOString().split('T')[0]
    hoy.setDate(hoy.getDate());
    this.dt_dia2 = hoy.toISOString().split('T')[0]
  },
  beforeUnmount () {
    document.removeEventListener('click',this.close)
  }

}
</script>


<style scoped>
td{
  padding: 5px 5px 0px;
  text-align: left;
}
/*
 tr:nth-child(odd){
  background-color: rgba(1, 98, 153, 0.8);
  color: #fff;
}
*/
table
{ 
    margin-left: auto;
    margin-right: auto;
}

.shadow-object{
  background-color: white;
  /*margin: 10px 0px 10px 10px;*/
  border-radius: 5px;
  height:auto;
  box-shadow: rgba(0, 0, 0, 0.28) 0px 3px 8px;
  padding:2px;
}

</style>
