<template>
  <div v-if="loaded">
    <h3 style="margin-bottom:20px; margin-top:20px;">
      Gráficos Encuestas
    </h3>
    <div style="display: flex; justify-content: center; gap: 10px;">
      <b-button
        variant="success"
        @click="agregarGrafico()"
      >
        Agregar
      </b-button>
      <b-button
        variant="danger"
        @click="eliminarGrafico()"
      >
        Eliminar
      </b-button>
    </div>
    <div
      v-for="this_graph in Array(cantidad_graficos).keys()"
      :key="this_graph"
    >
      <div style="padding: 20px;  position:relative; ">
        <h4 style="margin-bottom:10px;">
          Gráfico {{ this_graph + 1 }}
        </h4>
        <formulario-grafico
          :encuestas="encuestas"
          :profesionales="profesionales"
        />
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
import axios from 'axios';
import FormularioGrafico from './FormularioGrafico.vue';

export default {
  name: "GraficosDinamicos",
  components: {
    'formulario-grafico': FormularioGrafico
  },
  data() {
    return {
      encuestas: {},
      profesionales: {},
      cantidad_graficos: 1,
      loaded: false,
    }
  },
  created(){
    this.initialize_();
  },

  methods: {
    initialize_(){
      this.cargarEncuestas()
      .then(() => {
        this.cargarProfesionales()
        .then(() => {this.loaded = true;});
      })
      
    },

    agregarGrafico(){ this.cantidad_graficos += 1; },
    eliminarGrafico(){ if (this.cantidad_graficos > 0)  { this.cantidad_graficos -= 1; } },
  
    async cargarEncuestas(){
      axios.get(window.hostname+'/encuestas/')
      .then((response) => { this.encuestas = response.data.encuestas })
      .catch((error)=>{
        console.log("Error en cargarEncuestas()...!")
        console.log(error);
        alert(error);
      });
    },

    async cargarProfesionales(){
      axios.get(window.hostname+'/api/admin/getprofesionales')
      .then((response) => { this.profesionales = response.data.profesionales; })
      .catch((error)=>{
        console.log("Error en cargarProfesionales()...!")
        console.log(error);
        alert(error);
      });
    },

  },

  
}
</script>
