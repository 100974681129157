<template>
  <div>
    <button
      class="btn btn-success"
      @click="openModal"
    >
      Masivo
    </button>

    <!-- Modal -->
    <div
      v-if="showModal"
      class="modal-upload"
    >
      <div class="modal-content">
        <span
          class="close"
          @click="closeModal"
        >&times;</span>
        <h2>Cargar Masivo</h2>
        <button
          class="btn btn-dark"
          style="margin-bottom: 10px;"
          @click="$refs.fileMasivo.click()"
        >
          {{ selectedExcel ? selectedExcel.name : 'Subir archivo' }}
        </button>
        <input
          id="file-masivo"
          ref="fileMasivo"
          type="file"
          style="display: none;"
          accept=".xlsx, .xls"
          @change="handleFileUpload"
        >
        <button
          v-if="selectedExcel"
          class="btn btn-dark"
          @click="uploadExcel"
        >
          Upload
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import fetchMultipartData from '@/helpers/fetchMultipartData.js'

export default {
  data() {
    return {
      showModal: false,
      selectedExcel: null, // store the selected Excel file
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedExcel = null;
    },
    handleFileUpload(event) {
      this.selectedExcel = event.target.files[0];
    },
    async uploadExcel() {
      if (this.selectedExcel) {
        const formData = new FormData();
        formData.append("excel", this.selectedExcel);

        await fetchMultipartData('/api/admin/uploadExcel', formData)
          .then((data) => {
            console.log("Response:", data);
            // Handle the response data as needed
          })
          .catch(error => {
            console.error("Error:", error);
            // Handle any errors that occurred during the request
          });

        this.closeModal();
      }
    },
  },
};
</script>

<style>
/* Styles for the modal */
.modal-upload {
  display: inline;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
