<template>
  <div class="container">
    <div v-if="loaded">
      <h2>Modificar horario: {{ $route.params.id }}</h2>

      <hr>

      <br>
      <h4 style="margin-bottom:20px; text-align:left;">
        Horario
      </h4>
      <div
        v-if="loaded"
        class="container"
        style="text-align:left;"
      >
        <div style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; padding:20px; border-radius:15px;">
          <div style="display:inline-block; width:40%;">
            <div style="margin-bottom:20px;">
              Nombre:
              <input v-model="nombre">
            </div>
            <div style="margin-bottom:20px;">
              Intervalo:

              <select v-model="periodo">
                <option
                  v-for="o in periodos_posibles"
                  :value="o"
                >
                  {{ o }} minutos
                </option>
              </select>
            </div>
            <table style="margin: 0; margin-bottom:20px">
              <td style="vertical-align:middle; padding-right:20px;">
                <tr>
                  Inicio:
                </tr>
                <tr>
                  <select v-model="agregando_horario.inicio">
                    <option
                      v-for="v in Math.round(1440/5)"
                      :value="(v-1)*5"
                    >
                      {{ timeConvert((v-1)*5) }}
                    </option>
                  </select>
                </tr>
              </td>
              <td style="vertical-align:middle; padding-right:20px;">
                <tr>
                  Termino: 
                </tr>
                <select v-model="agregando_horario.termino">
                  <option
                    v-for="v in Math.round((1440-agregando_horario.inicio)/periodo)"
                    :value="(v)*periodo + agregando_horario.inicio"
                  >
                    {{ timeConvert((v)*periodo + agregando_horario.inicio) }}
                  </option>
                </select>
              </td>
                      
              <td style="vertical-align:middle;">
                <table>
                  <tr>
                    <td
                      v-for="x in dias"
                      style="text-align:center; width:30px;"
                    >
                      {{ x }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      v-for="x in agregando_horario.repeticion"
                      style="text-align:center;"
                    >
                      <input
                        v-model="x.value"
                        type="checkbox"
                        :true-value="1"
                        :false-value="0"
                      >
                    </td>
                  </tr>
                </table>
              </td>
            </table>
            <div style="margin-bottom:20px">
              Anticipación necesaria (en horas):
              <select v-model="anticipacion">
                <option
                  v-for="v in 25"
                  :value="(v-1)*60"
                >
                  {{ timeConvert((v-1)*60) }}
                </option>
              </select>
            </div>    
            <div style="margin-bottom:20px">
              Anticipación mínima (en horas):
              <select v-model="anticipacion_minima">
                <option
                  v-for="v in 25"
                  :value="(v-1)*60"
                >
                  {{ timeConvert((v-1)*60) }}
                </option>
              </select>
            </div>    
            <div style="margin-bottom:20px">
              Anticipación autoconfirmación (en horas): 
              <select v-model="anticipacion_autoconfirmacion">
                <option
                  v-for="v in 25"
                  :value="v-1"
                >
                  {{ timeConvert(((v-1)*60)) }}
                </option>
              </select>
            </div>
            <div style="margin-bottom:20px">
              Solo se puede agendar hasta (días antes y hora):
              <select v-model="anticipacion_fija_minima_dias">
                <option
                  v-for="day in [0,1,2,3]"
                  :value="day"
                >
                  {{ day }} Días antes
                </option>
              </select>
              <select v-model="anticipacion_fija_minima_hora">
                <option
                  v-for="v in 25"
                  :value="v-1"
                >
                  A las {{ timeConvert(((v-1)*60)) }}
                </option>
              </select>
            </div>
            <div style="margin-bottom:20px">
              Solo se puede agendar desde(días antes y hora):
              <select v-model="disponible_desde_dias">
                <option
                  v-for="day in [0,1,2,3]"
                  :value="day"
                >
                  {{ day }} Días antes
                </option>
              </select>
              <select v-model="disponible_desde_hora">
                <option
                  v-for="v in 25"
                  :value="v-1"
                >
                  A las {{ timeConvert(((v-1)*60)) }}
                </option>
              </select>
            </div> 
            <div style="margin-bottom:20px">
              Prioridad:
              <select v-model="prioridad">
                <option
                  v-for="v in prioridades"
                  :value="v"
                >
                  {{ v }}
                </option>
              </select>
            </div>    
            <div style="margin-bottom:20px">
              Recargo (%):
              <select v-model="recargo_porcentaje">
                <option
                  v-for="v in recargos"
                  :value="v"
                >
                  {{ v }}
                </option>
              </select>
            </div>
            <div style="margin-bottom:20px">
              Recargo (monto):
              <input
                id=""
                v-model="recargo_suma"
                name=""
              >
            </div>
            <div style="margin-bottom:20px">
              Color:
              <div style="display:inline-block; transform:translateY(8px);">
                <InputColorPicker v-model="color" />
              </div>
              {{ color }}
            </div>
            <div style="display:inline-block; width:40%; vertical-align:top;">
              Genera bloqueo
              <input
                v-model="genera_bloqueo"
                type="checkbox"
              >
            </div>
          </div>  
          <div style="display:inline-block; width:30%; vertical-align:top;">
            Comunas
            <div>
              <input
                v-model="comunas_buscadas"
                type="text"
              >
                      
              <div style="margin:0; position:relative; text-align:center; width:200px;">
                <div
                  v-if="comunas_buscadas != ''"
                  style="background:white; position:absolute; left:1px; border: 1px solid black; margin: 0; width:202px;"
                >
                  <div
                    v-for="r in resultado_fuse_comuna.slice(0,10)"
                    style="cursor:pointer;"
                  >
                    <span @click="agregar_comunas(r.item.id)">{{ r.item.Nombre }}<b-icon
                      v-if="comunas_selected.includes(r.item.id)"
                      icon="check"
                    /> </span>
                  </div>
                </div>
              </div>
            </div>
            <div v-for="c in comunas_selected">
              - {{ comunas.filter(x=>{return x.id == c})[0].Nombre }}
            </div>
          </div>
          <div style="display:inline-block; width:30%; vertical-align:top;">
            Categorías
                                        
            <table style="width:100%; margin: 0px 0px;">
              <tr style="background:black; color:white;">
                <td>Id</td>
                <td>Nombre</td>
                <td>Puede?</td>
              </tr>
              <tr v-for="c in editando_categorias">
                <td style="padding:5px;">
                  {{ c.id }}
                </td>
                <td style="padding:5px;">
                  {{ c.nombre }}
                </td>
                <td style="text-align:center; padding:5px;">
                  <input
                    v-model="c.puede"
                    type="checkbox"
                  >
                </td>
              </tr>
            </table>
          </div>
          <div style="text-align:center;">
            <button
              class="btn btn-success"
              style="margin: 10px auto; "
              @click="agregar_horario(profesional.id, agregando_horario.inicio, agregando_horario.termino, agregando_horario.repeticion)"
            >
              Guardar Horario
            </button>
          </div>
        </div>
      </div>

      <br>
      <h4 style="margin-bottom:20px; text-align:left;">
        Atención a prestadores <b-icon icon="plus" />
      </h4>
      <div
        class="container"
        style="text-align:left;"
      >
        <div style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; padding:20px; border-radius:15px;">
          <div style="display:inline-block; width:40%; vertical-align:top;">
            Prestadores
            <table style="width:100%; margin: 0px 0px;">
              <tr style="background:black; color:white;">
                <td>Id</td>
                <td>Nombre</td>
                <td>¿Atiende?</td>
              </tr>
              <tr v-for="prestador in prestadores">
                <td style="padding:5px;">
                  {{ prestador.id }}
                </td>
                <td style="padding:5px;">
                  {{ prestador.nombre }}
                </td>
                <td style="text-align:center; padding:5px;">
                  <input
                    v-model="prestador.activo"
                    type="checkbox"
                  >
                </td>
              </tr>
            </table>
          </div>
          <div style="text-align:center;">
            <button
              class="btn btn-success"
              style="margin: 10px auto; "
              @click="post_atencion_horario_prestador()"
            >
              <b-icon
                icon="plus-circle"
                style="margin-right:6px;"
              />Guardar cambios de atención a prestadores
            </button>
          </div>
        </div>
      </div>
      <br>
      <h4 style="margin-bottom:20px; text-align:left;">
        Eliminar horario <b-icon icon="trash" />
      </h4>
      <button
        class="btn btn-danger"
        style="margin: 10px auto; "
        @click="eliminar_horario($route.params.id)"
      >
        <b-icon
          icon="trash"
          style="margin-right:6px;"
        />
        Eliminar horario
      </button>
      <br>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'
import InputColorPicker from 'vue-native-color-picker'

export default {
  name: 'HelloWorld',
  components: {
    InputColorPicker,
  },
  props:['id'],
  data() {
    return {
      profesional:{},
      agregando_horario:{inicio:-1, termino:-1, repeticion:[{value:0},{value:0},{value:0},{value:0},{value:0},{value:0},{value:0}]},
      dias:['Lu','Ma','Mi','Ju','Vi','Sa','Dom'],
      dias2:['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],
      periodo:60,
      periodos_posibles:[15,30,45,60,90,120],
      loaded:false,
      comunas_selected:[],
      resultado_fuse_comuna:[],
      comunas_buscadas:'',
      color:'#000000',
      anticipacion:0,
      anticipacion_minima:0,
      anticipacion_autoconfirmacion:0,
      anticipacion_fija_minima_dias:0,
      anticipacion_fija_minima_hora:0,
      disponible_desde_dias:0,
      disponible_desde_hora:0,
      nombre: '',
      editando_examenes:[],
      editando_categorias:[],
      genera_bloqueo: false,

      prioridades:[1,2,3,4,5],
      prioridad:1,
      recargo_porcentaje:0,
      recargo_suma:0,
      recargos:[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100],
  
      prestadores_activos: [],
      prestadores: [],

    }
  },
  created(){
  
    this.initialize_(this.$route.params.id);
  },

  methods:{
    ...mapActions(['initialize']),
    agregar_comunas(cid){
        this.comunas_buscadas = ''
        if(!this.comunas_selected.includes(cid))
          this.comunas_selected.push(cid)
        else
          this.comunas_selected.splice(this.comunas_selected.indexOf(cid), 1)

    },
   timeConvert2 (n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return  + rhours + " h " + rminutes + " m.";
    },
    modificar_anticipacion(){
      axios.post(window.hostname+'/api/admin/modificar_anticipacion', 
        {profesional_id: this.profesional.id, anticipacion:this.anticipacion})
        .then(response => 
        {            
          this.initialize_(this.$route.params.id);
          this.initialize()
        })
        .catch((e)=> console.log("Error al cargar..."));

    },
    cambiarPuedeCategorias(){
      var ret = []
      for(var i = 0; i < this.categorias.length; i++){
        if (this.profesional.categorias.some(x=>{return x == this.categorias[i].id}) != this.editando_categorias.filter(x=>{return x.id == this.categorias[i].id})[0].puede)
          ret.push({id:this.categorias[i].id, puede: this.editando_categorias.filter(x=>{return x.id == this.categorias[i].id})[0].puede})
      }
      
      axios.post(window.hostname+'/api/admin/modificar_categorias_pro', 
      {id: this.profesional.id,
      categorias:ret})
      .then(response => 
      {          
        this.initialize_(this.$route.params.id);
      })

      .catch(e=>{

          console.log("Error al cargar...")
      })
      
    },
    eliminar_horario(hid){
      axios.post(window.hostname+'/api/admin/eliminar_horario', 
        {horario_id: hid})
        .then(() => {
          alert("Horario eliminado");
          this.$router.push({name:'Admin'});
        })
        .catch((e) => console.log("Error al cargar..."));
    },
    eliminar_profesional(hid){
      axios.post(window.hostname+'/api/admin/eliminar_profesional', 
        {profesional_id: hid})
        .then(response => 
        {
            
            this.initialize_(this.$route.params.id);
        })

        .catch(e=>{

            console.log("Error al cargar...")
        })

    },
    timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },

    initialize_(pid){
      if (typeof(this.$route.params.id === 'number'))
      {
        this.loaded = false;
        axios.post(window.hostname+'/api/admin/init_modificar_horario_pro', 
        {id: pid})
        .then((response) => 
        {
          this.loaded = true;
          this.periodo = response.data.horario.intervalo
          this.agregando_horario.inicio =  response.data.horario.inicio
          this.agregando_horario.termino =  response.data.horario.termino
          this.agregando_horario.repeticion[0].value =  response.data.horario.repeticion[0]
          this.agregando_horario.repeticion[1].value =  response.data.horario.repeticion[1]
          this.agregando_horario.repeticion[2].value =  response.data.horario.repeticion[2]
          this.agregando_horario.repeticion[3].value =  response.data.horario.repeticion[3]
          this.agregando_horario.repeticion[4].value =  response.data.horario.repeticion[4]
          this.agregando_horario.repeticion[5].value =  response.data.horario.repeticion[5]
          this.agregando_horario.repeticion[6].value =  response.data.horario.repeticion[6]
          this.anticipacion = response.data.horario.anticipacion
          this.anticipacion_minima = response.data.horario.anticipacion_minima
          this.anticipacion_autoconfirmacion = response.data.horario.anticipacion_autoconfirmacion
          this.anticipacion_fija_minima_dias = response.data.horario.anticipacion_fija_minima_dias
          this.anticipacion_fija_minima_hora = response.data.horario.anticipacion_fija_minima_hora
          this.disponible_desde_dias = response.data.horario.disponible_desde_dias
          this.disponible_desde_hora = response.data.horario.disponible_desde_hora
          this.color = response.data.horario.color
          this.nombre = response.data.horario.nombre

          this.prioridad = response.data.horario.prioridad
          this.recargo_porcentaje = response.data.horario.recargo_porcentaje
          this.recargo_suma = response.data.horario.recargo_suma
          this.comunas_selected = response.data.horario.comunas
          this.genera_bloqueo = response.data.horario.genera_bloqueo
          this.editando_categorias = []
          for (let i = 0; i < this.categorias.length; i++) {
            this.editando_categorias.push({id:this.categorias[i].id, nombre:this.categorias[i].nombre, puede: response.data.horario.categorias.some(x=>{return x == this.categorias[i].id}) ? 1 : 0})
          }
        })

        .catch(e=>{
          this.loaded = true;
          console.log("Error al cargar...", e)
        })
      }
    },

    agregar_horario(agenda_id, inicio, termino, repeticion){

      var error_msg = ''
      if(agenda_id == 0)
          error_msg = 'Por favor, ingrese una agenda. '
      if(inicio == -1)
        error_msg += 'Por favor, ingrese una hora de inicio. '
      if(termino == -1)
        error_msg += 'Por favor, ingrese una hora de término. '

      if(inicio > termino)
        error_msg += 'La hora de inicio no puede ser mayor a la de término. '  
      var x = '';
      for (var i = 0; i < repeticion.length; i++)
        x += repeticion[i].value.toString();
      
      if(x == '0000000')
         error_msg += 'Debes especificar al menos un día. '

      let cat = []
      for(var j = 0; j < this.editando_categorias.length; j++){
        if (this.editando_categorias[j].puede){
          cat.push(this.editando_categorias[j].id)
        }

      }
      if(cat.length == 0)
        error_msg = 'Debes agregar al menos una categoría'
      if(this.comunas_selected.length == 0)
        error_msg = 'Debes agregar al menos una comuna'

      if(error_msg == ''){

        axios.post(window.hostname+'/api/admin/modificar_horario', {
          horario_id: this.$route.params.id,
          inicio: inicio,
          termino: termino,
          repeticion:x,
          categorias:cat,
          comunas:this.comunas_selected,
          anticipacion:this.anticipacion,
          prioridad:this.prioridad,
          recargo_porcentaje:this.recargo_porcentaje,
          recargo_suma:this.recargo_suma,
          intervalo:this.periodo,
          anticipacion_minima:this.anticipacion_minima,
          anticipacion_autoconfirmacion:this.anticipacion_autoconfirmacion,
          anticipacion_fija_minima_dias:this.anticipacion_fija_minima_dias,
          anticipacion_fija_minima_hora:this.anticipacion_fija_minima_hora,
          disponible_desde_dias:this.disponible_desde_dias,
          disponible_desde_hora:this.disponible_desde_hora,
          color:this.color,
          nombre:this.nombre,
          genera_bloqueo:this.genera_bloqueo,
          } )
        .then(resp=>{

          this.$notify({duration:5000, title: 'Horario agregado', text: '¡Se ha añadido el horario a la base de datos!', type: 'success'})
          this.initialize_(this.$route.params.id);
        })
        .catch(e=>{ 
          this.$notify({duration:5000, title: 'Error!', text: e.response.data.message, type: 'error'})
        })
      }
      else{
        console.log("LOL", error_msg)
        this.$notify({duration:5000, title: 'Error!', text: error_msg, type: 'error'})
      }

    },

    get_atencion_horario_prestador(){
      const horario_id = this.$route.params.id;
      axios.get(window.hostname + `/api/admin/horarios/${horario_id}/atencion_prestador`)
      .then( (resp) => {
        this.prestadores = resp.data.prestadores;
        this.prestadores_activos = resp.data.prestadores_activos;
        this.prestadores.forEach( (prestador) => {
          if (this.prestadores_activos.includes(prestador.id)) {
            prestador.activo = true;
          } else {
            prestador.activo = false;
          }
        });
        console.log(this.prestadores);
      })
      .catch( (e) => { 
        this.$notify({duration:5000, title: 'Error!', text: e.response.data.message, type: 'error'})
      })
    },

    post_atencion_horario_prestador() {
      const horario_id = this.$route.params.id;
      let prestadores_activos = [];
      this.prestadores.forEach( (prestador) => {
        if (prestador.activo) {
          prestadores_activos.push(prestador.id);
        }
      });
      this.prestadores_activos = prestadores_activos;
      axios.post(window.hostname+ `/api/admin/horarios/${horario_id}/atencion_prestador`, {prestadores_activos})
      .then( (resp) => {
        this.$notify({duration:5000, title: 'Prestadores actualizados', text: '¡Se han realizado los cambios con éxito!', type: 'success'});
        this.get_atencion_horario_prestador();
      })
      .then( () => alert('Se han realizado los cambios con éxito!'))
      .catch( (e) => { 
        this.$notify({duration:5000, title: 'Error!', text: e.response.data.message, type: 'error'});
        alert('Ha ocurrido un error al actualizar los prestadores');
      })
    },

  },
  computed:{
    ...mapGetters(['comunas', 'categorias', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'examenes']),
  },
  watch:{
    comunas_buscadas(val){
      this.resultado_fuse_comuna = this.fComunas.search(val)

    },
  },
  mounted() {
    this.fComunas = new Fuse(this.comunas, {  keys: ['Nombre']});
    this.get_atencion_horario_prestador();
  }

}
</script>


<style scoped>
td{
  text-align: left;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}

</style>

