<template>
  <div class="container-fluid">
    <div v-if="loaded">
      <h2>Modificar Códigos <b-icon icon="card-text" /></h2>
      <div style="box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; display:inline-block; padding:10px; border-radius:4px;">
        <table>
          <tr>
            <td style="padding-right:10px;">
              Nombre
            </td>
            <td>
              <input
                id=""
                v-model="nombre"
                type="text"
                name=""
              >
            </td>
            <td style="padding:0px 10px;">
              Código
            </td>
            <td>
              <input
                id=""
                v-model="codigo"
                type="text"
                name=""
              >
            </td>

            <td>
              Desde <input
                id="x2"
                v-model="dt_dia"
                type="date"
              > Hasta: <input
                id="x2"
                v-model="dt_dia2"
                type="date"
              >
            </td>
            <td style="padding-left:10px;">
              <button
                class="btn btn-success"
                @click="agregar_codigo()"
              >
                Agregar Codigo <b-icon icon="plus-circle" />
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div style="box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; max-width:1200px; margin:0 auto;">
        <table style="width:100%; margin-top:30px;">
          <tr style="background-color:black; color:white;">
            <td>Id</td>
            <td>Nombre</td>
            <td>Código</td>
            <td>Fecha Inicio</td>
            <td>Fecha Termino</td>
            <td style="width:7%">
              Acciones
            </td>
          </tr>
          <tr v-for="c in codigos">
            <td>{{ c.id }}</td> 
            <td>{{ c.nombre }}</td> 
            <td>{{ c.codigo }}</td> 
            <td>{{ new Date(c.fecha_inicio.a, c.fecha_inicio.m-1, c.fecha_inicio.d).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' }) }}</td> 
            <td>{{ new Date(c.fecha_termino.a, c.fecha_termino.m-1, c.fecha_termino.d).toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric' }) }}</td> 
        
            <td style="text-align:center;">
              <b-icon
                icon="trash"
                style="color:red; cursor:pointer;"
                @click="eliminar_codigo(c.id)"
              />
              <router-link :to="{ path: '/EditarCodigo/'+c.id }">
                <b-icon
                  icon="pen"
                  style="cursor:pointer;"
                />
              </router-link>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';


export default {
  name: 'HelloWorld',
  data() {
    return {
      dt_dia:'',
      dt_dia2:'',
      loaded:false,
      codigos:[],
      nombre:'',
      codigo:'',
      desde:{d:0, m:0, a:0},
      hasta:{d:0, m:0, a:0},

    }
  },
  created(){
  
    this.initialize_();
  },

  methods:{
    ...mapActions(['initialize']),







    
  initialize_(){
    //this.loaded = false;
      axios.get(window.hostname+'/api/admin/initialize_codigos')
      .then(response => 
      {
        this.loaded = true;
        this.codigos = response.data.codigos;
      })         
      .catch(e=>
      {
          this.loaded = true;
      })
  },


  agregar_codigo(){
      axios.post(window.hostname+'/api/admin/agregar_codigo',
      {nombre:this.nombre, codigo:this.codigo,
        dia:this.desde.d, mes:this.desde.m, año:this.desde.a,
         dia2:this.hasta.d, mes2:this.hasta.m, año2:this.hasta.a}
         )
      .then(response => 
      {
        this.initialize_()
      })         
      .catch(e=>
      {

      })
  },


  eliminar_codigo(id){
      axios.post(window.hostname+'/api/admin/eliminar_codigo',
      {codigo_id:id}
         )
      .then(response => 
      {
        this.initialize_()
      })         
      .catch(e=>
      {

      })
  },


  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'examenes', 'aseguradoras', 'descuentos']),
  },
  watch:{
    dt_dia(val){

        var datos = val.split('-')
        this.desde = {a:Number(datos[0]), m:Number(datos[1]), d:Number(datos[2])}
      
    },
    dt_dia2(val){

        var datos = val.split('-')
        this.hasta = {a:Number(datos[0]), m:Number(datos[1]), d:Number(datos[2])}
      
    },
  },
  mounted() {
  }

}
</script>


<style scoped>
td{
  text-align: left;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}

</style>

