<template>
  <div class="container-fluid">
    <div v-if="loaded">
      <h2>Modificar Exámenes <b-icon icon="file-medical" /></h2>
      <div style="text-align:left; padding:7px;">
        <button
          v-if="Number(ENV.VUE_APP_PRESTADOR_ID) == 1"
          class="btn btn-success"
          @click="agregar_examen()"
        >
          Agregar Examen <b-icon icon="plus-circle" />
        </button>
        <router-link
          to="/ModificarDescuentos"
          style="margin-left:5px;"
          tag="button"
          class="btn btn-success"
        >
          Modificar Descuentos <b-icon icon="plus-circle" />
        </router-link>
        <router-link
          to="/ModificarPrecioExamenComuna"
          style="margin-left:5px;"
          tag="button"
          class="btn btn-success"
        >
          Modificar Precios Por Comuna <b-icon icon="plus-circle" />
        </router-link>
        <router-link
          tag="button"
          to="/ModificarCategorias"
          style="margin-left:5px;"
          class="btn btn-success"
        >
          Modificar Categorías <b-icon icon="card-text" />
        </router-link>
        <router-link
          tag="button"
          to="/ModificarCodigos"
          style="margin-left:5px;"
          class="btn btn-success"
        >
          Modificar Códigos <b-icon icon="card-text" />
        </router-link>
        <div
          tag="button"
          style="margin-left:5px;"
          class="btn btn-success"
          @click="DownloadExamsExcel()"
        >
          Descargar EXCEL <b-icon icon="card-text" />
        </div>
        <div
          style="margin-left:5px; padding: 0px;"
          class="btn btn-success"
        >
          <excel-uploader />
        </div>
      </div>
    
      <table style="width:100%; ">
        <tr style="height:40px; background-color:black; color:white;">
          <td style="text-align:center;">
            Servicio Domiciliario
          </td>
          <td>Valor</td>
          <td style="text-align:center; border-right:1px solid black;">
            Editar
          </td>
        </tr>
        <tr style="height:40px; background-color:white;">
          <td style="text-align:center;">
            Recargo Base (Por orden)
          </td>
          <td style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width:400px;">
            $<input
              v-model="costos.costo_servicio_base"
              type="text"
              :disabled="modificar_costos ? disabled : ''"
            >
          </td>
          <td style="text-align:center; border-right:1px solid black;">
            <span @click="post_costos_servicio().then(() => {modificar_costos = !modificar_costos;});"> 
              <b-icon
                :style="modificar_costos ? 'color:green; cursor:pointer;' : 'color:blue; cursor:pointer;'"
                :icon="modificar_costos ? 'check-circle' : 'pen'"
              />
            </span>
          </td>
        </tr>
        <tr style="height:40px; background-color:white;">
          <td style="text-align:center;">
            Recargo Variable (Por paciente)
          </td>
          <td style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width:400px;">
            $<input
              v-model="costos.costo_servicio_variable"
              type="text"
              :disabled="modificar_costos ? disabled : ''"
            >
          </td>
          <td style="text-align:center; border-right:1px solid black;">
            <span @click="modificar_costos = !modificar_costos;"> <b-icon
              :style="modificar_costos ? 'color:green; cursor:pointer;' : 'color:blue; cursor:pointer;'"
              :icon="modificar_costos ? 'check-circle' : 'pen'"
            /></span>
          </td>
        </tr>

        <tr style="height:40px; background-color:white;">
          <td style="text-align:center;">
            Recargo Festivos (Extra por fecha)
          </td>
          <td style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width:400px;">
            $<input
              v-model="costos.costo_servicio_festivos"
              type="text"
              :disabled="modificar_costos ? disabled : ''"
            >
          </td>
          <td style="text-align:center; border-right:1px solid black;">
            <span @click="modificar_costos = !modificar_costos;"> <b-icon
              :style="modificar_costos ? 'color:green; cursor:pointer;' : 'color:blue; cursor:pointer;'"
              :icon="modificar_costos ? 'check-circle' : 'pen'"
            /></span>
          </td>
        </tr>
      </table>

      <table style="width:100%; ">
        <tr style="height:40px; background-color:black; color:white;">
          <td style="text-align:center;">
            Id
          </td>
          <td>Nombre</td>
          <td>Categoria</td>
          <td>Requiere receta</td>
          <td>Requiere pago inmediato</td>
          <td style="width:200px;">
            Información
          </td>
          <td style="width:200px;">
            Código
          </td>
          <td style="width:200px;">
            Keywords
          </td>
          <td>Precio</td>
          <td>Activo</td>
          <td>Sug</td>
          <td style="text-align:center; border-right:1px solid black;">
            Editar
          </td>
        </tr>
        <tr
          v-for="e in editando_examenes"
          style="height:40px; background-color:white; "
        >
          <template v-if="!e.modificar">
            <td style="text-align:center; border-left:1px solid black;">
              {{ e.id }}
            </td>
            <td style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width:400px;">
              {{ e.nombre }}
            </td>
            <td style="max-width:200px;">
              {{ categorias.filter(x=>{ return x.id == e.categoria})[0].nombre }}
            </td>
            <td style="max-width:200px;">
              {{ e.requiere_orden == 0 ? 'No':'Si' }}
            </td>
            <td style="max-width:200px;">
              {{ e.requiere_pago == 0 ? 'No':'Si' }}
            </td>
            <td>{{ e.informacion }}</td>
            <td>{{ e.codigo }}</td>
            <td>{{ e.keywords }}</td>
            <td style="width:80px">
              {{ e.precio }}
            </td>
            <td>{{ e.activo == true ? 'Si':'No' }}</td>
            <td>{{ e.hasta == 1 ? 'Si':'No' }}</td>
            <td style="text-align:center; border-right:1px solid black;">
              <span
                style="color:blue; cursor:pointer; margin-right:6px;"
                @click="e.modificar = true"
              ><b-icon icon="pen" /></span>  
              <span
                style="color:red; cursor:pointer;"
                @click="remover_examen(e.id)"
              ><b-icon icon="trash" /></span>  
            </td>
          </template>

          <template v-else>
            <td style="border-left:1px solid black; text-align:center;">
              {{ e.id }}
            </td>
            <td style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width:400px;">
              <input
                v-model="e.enombre"
                type="text"
              >
            </td>
            <td>
              <select v-model="e.ecategoria">
                <option
                  v-for="o in categorias"
                  :value="o.id"
                >
                  {{ categorias.filter(x=>{ return x.id == o.id})[0].nombre }}
                </option>
              </select>
            </td>
            <td>
              <select v-model="e.erequiere_orden">
                <option :value="1">
                  Si
                </option>
                <option :value="0">
                  No
                </option>
              </select>
            </td>
            <td>
              <select v-model="e.erequiere_pago">
                <option :value="1">
                  Si
                </option>
                <option :value="0">
                  No
                </option>
              </select>
            </td>
            <td>
              <input
                v-model="e.einformacion"
                style="width:170px"
                type="text"
              >
            </td>
            <td>
              <input
                v-model="e.ecodigo"
                style="width:110px"
                type="text"
              >
            </td>
            <td>
              <input
                v-model="e.ekeywords"
                style="width:110px"
                type="text"
              >
            </td>
            <td style="width:80px">
              <input
                v-model="e.eprecio"
                style="width:70px"
                type="text"
              >
            </td>
            <td style="width:80px">
              <select v-model="e.edesde">
                <option :value="true">
                  Si
                </option>
                <option :value="false">
                  No
                </option>
              </select>
            </td>
            <td style="width:80px">
              <select v-model="e.ehasta">
                <option :value="1">
                  Si
                </option>
                <option :value="0">
                  No
                </option>
              </select>
            </td>
            <td style="text-align:center; border-right:1px solid black;">
              <span @click="e.modificar = false; modificar(e.id);"> <b-icon
                style="color:green; cursor:pointer;"
                icon="check-circle"
              /></span>
            </td>
          </template>
        </tr>
        <tr style="height:20px; background-color:black; color:white;">
          <td style="text-align:center;" />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td style="text-align:center; border-right:1px solid black;" />
        </tr>
      </table>
      <div style="text-align:left; margin-top:10px;">
        <button
          v-if="Number(ENV.VUE_APP_PRESTADOR_ID) == 1"
          class="btn btn-success"
          @click="agregar_examen()"
        >
          Agregar Examen <b-icon icon="plus-circle" />
        </button>
        <router-link
          to="/ModificarDescuentos"
          tag="button"
          style="margin-left:5px;"
          class="btn btn-success"
        >
          Modificar Descuentos <b-icon icon="plus-circle" />
        </router-link>
        <router-link
          tag="button"
          to="/ModificarCategorias"
          style="margin-left:5px;"
          class="btn btn-success"
        >
          Modificar Categorías <b-icon icon="card-text" />
        </router-link>
        <router-link
          tag="button"
          to="/ModificarCodigos"
          style="margin-left:5px;"
          class="btn btn-success"
        >
          Modificar Códigos <b-icon icon="card-text" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import Fuse from 'fuse.js'

import ExcelUploader from './utils/ExcelUploader.vue';


export default {
  name: 'HelloWorld',
  components: {
    ExcelUploader,
  },
  data() {
    return {

      loaded:false,
      editando_examenes:[],
      categorias:[],
      editando_categorias:[],
      costos: {
        'costo_servicio_base': -1,
        'costo_servicio_variable': -1,
        'costo_servicio_festivos': -1,
      },
      modificar_costos : false,
    }
  },
  created(){
  
    this.initialize_();
    this.get_costos_servicio();
  },

  methods:{
    ...mapActions(['initialize']),

    DownloadExamsExcel(){
      axios({
        url: window.hostname+'/api/admin/exportar/examenes',
        method: 'GET',
        responseType: 'blob',
      })
      .then( (response) =>  {
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'examenes.xlsx'); 
        document.body.appendChild(link);
        link.click();          
      })
      .catch( (e) => {
        console.log(e);
      })
    },

  get_costos_servicio(){
    axios.get(window.hostname+'/api/admin/costo_servicio')
        .then(response => {
          this.costos = response.data;
        })
        .then(() => {
          console.log(this.costos);
        })
        .catch(e=>{
            console.log("Error al cargar costos servicios...")
        })
  },

  async post_costos_servicio(){
    if (this.modificar_costos) {
      axios.post(window.hostname+'/api/admin/costo_servicio',this.costos)
          .then(response => { this.get_costos_servicio(); })
          .catch( (e) =>{ console.log("Error al cargar...") })
    }
  },

  remover_examen(id){
    axios.post(window.hostname+'/api/admin/remove_examen',{id:id})
        .then(response => 
        {
          this.initialize().then( ()=>{
          this.initialize_();
          })
          
        })

        .catch(e=>{
            console.log("Error al cargar...")
        })
  },
  agregar_examen(){
    axios.post(window.hostname+'/api/admin/add_examen')
        .then(response => 
        {
          this.initialize().then( ()=>{
          this.initialize_();
          })
          
        })

        .catch(e=>{
            console.log("Error al cargar...")
        })
  },

  modificar(id){
    let data = this.editando_examenes.filter(x=>{return x.id == id})[0]
      axios.post(window.hostname+'/api/admin/modificar_examen', 
        {id: id,
        categoria_id:data.ecategoria,
        precio:data.eprecio,
        desde:data.edesde,
        activo:data.edesde,
        hasta:data.ehasta,
        sugerido:data.ehasta,
        informacion:data.einformacion,
        requiere_orden:data.erequiere_orden,
        requiere_pago:data.erequiere_pago,
        nombre:data.enombre,
        keywords:data.ekeywords,
        codigo:data.ecodigo,
        })
        .then(response => 
        {
          this.initialize().then( ()=>{
          this.initialize_();
          })
          
        })

        .catch(e=>{
            this.loaded = true;
            console.log("Error al cargar...")
        })

  },
    
  initialize_(pid){
      this.loaded = false;
      axios.get(window.hostname+'/api/admin/init_modificar_examenes')
      .then(response => 
      {
          this.editando_examenes = []
          this.categorias = response.data.categorias
          for(var i = 0; i < this.examenes.length; i++){
            this.editando_examenes.push({

                nombre:this.examenes[i].nombre,
                id:this.examenes[i].id,
                precio:this.examenes[i].precio,
                informacion:this.examenes[i].informacion,
                keywords:this.examenes[i].keywords,
                categoria:this.examenes[i].categoria_id,
                requiere_orden:this.examenes[i].requiere_orden,
                requiere_pago:this.examenes[i].requiere_pago,
                desde:this.examenes[i].desde,
                activo:this.examenes[i].activo,
                hasta:this.examenes[i].hasta,
                sugerido:this.examenes[i].hasta,
                codigo:this.examenes[i].codigo,
                
                enombre:this.examenes[i].nombre,
                eid:this.examenes[i].id,
                eprecio:this.examenes[i].precio,
                einformacion:this.examenes[i].informacion,
                ekeywords:this.examenes[i].keywords,
                ecategoria:this.examenes[i].categoria_id,
                erequiere_orden:this.examenes[i].requiere_orden,
                erequiere_pago:this.examenes[i].requiere_pago,
                edesde:this.examenes[i].activo,
                ehasta:this.examenes[i].hasta,
                ecodigo:this.examenes[i].codigo,

                modificar:false,
            })
            this.loaded = true;
          }
      })

      .catch(e=>{
          this.loaded = true;
          console.log("Error al cargar...")
      })

  },


  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'prestaciones', 'tipos', 'admin_datos', 'examenes']),
  },
  watch:{
    comunas_buscadas(val){
      this.resultado_fuse_comuna = this.fComunas.search(val)

    },
    examenes(){
      this.initialize_()
    }
  },
  mounted() {
    this.ENV = process.env;
    this.fComunas = new Fuse(this.comunas, {  keys: ['Nombre']});
  }

}
</script>


<style scoped>
td{
  text-align: left;
}
table
{ 
    margin-left: auto;
    margin-right: auto;
}

</style>

