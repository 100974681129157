<template>
  <div v-if="loaded">
    <div style="display: flex; flex-direction: column; margin-bottom:10px;">
      <div style="margin-bottom:2px;">
        Desde <input
          id="x2"
          v-model="desde"
          class="btn btn-dark"
          type="date"
        > Hasta: <input
          id="x2"
          v-model="hasta"
          class="btn btn-dark"
          type="date"
        >
      </div>
    
      <select
        v-model="data.solicitud"
        class="btn btn-light"
      >
        <option
          v-for="solicitud in posibles_solicitudes"
          :key="solicitud.id"
          :value="solicitud.value"
        >
          {{ solicitud.name }}
        </option>
      </select>

      <select
        v-model="data.filtro_encuestas"
        class="btn btn-dark"
        @change="data.id_encuesta = $event.target.value"
      >
        <option
          v-if="data.solicitud!='estadisticas_encuesta'"
          value="get_all"
        >
          Todas las encuestas
        </option>
        <option
          v-for="(encuesta, item) in encuestas"
          :key="encuesta+item"
          :value="encuesta.id"
        >
          {{ encuesta.titulo }}
        </option>
      </select>

      <select
        v-model="data.filtro_profesional"
        class="btn btn-light"
        @change="data.id_profesional = $event.target.value"
      >
        <option value="get_all">
          Todos los profesionales
        </option>
        <option
          v-for="(profesional, item) in profesionales"
          :key="profesional+item"
          :value="profesional.id"
        >
          {{ profesional.nombre + ' ' + profesional.apellido }}
        </option>
      </select>

      <select
        v-model="selected_chart"
        class="btn btn-dark"
      >
        <option
          v-for="grafico in posible_graficos"
          :key="grafico.id"
          :value="grafico.value"
        >
          {{ grafico.name }}
        </option>
      </select>
    </div>
    <b-button
      class="btn btn-secondary"
      style="margin-bottom:10px;"
      @click="actualizarGrafico()"
    >
      Actualizar
    </b-button>

    <div
      v-if="data.solicitud!='estadisticas_encuesta' && grafico_loaded==true"
      class="graficos-box"
    >
      <div style="flex-grow: 1;">
        <component
          :is="selected_chart"
          :key="refresherGrafico"
          :data="chartData"
          :options="chartOptions"
          style="width:70%;"
        />
      </div>
      <div
        class="cardContainer"
        style="flex-grow: 1;"
      >
        <div
          style="background: rgba(0, 116, 217, 0.85);"
          class="cardS"
        >
          <div class="cardSText">
            {{ texto_numero }}<h2>{{ valor_numero }}</h2>
          </div>
        </div>
      </div>
    </div>

    <div v-if="mostrar_encuesta">
      <h2> {{ encuesta.titulo }} <b-icon icon="card-text" /></h2>

      <div style="padding-bottom:120px; ">
        <div
          v-for="(pregunta, index) in encuesta.preguntas"
          :key="'B'+pregunta.id+index"
          class="data-box"
        >
          <div style="width:100%; border-bottom:1px solid rgb(214, 214, 214);  padding:15px 15px 15px 30px; background:white;"> 
            <b-icon icon="card-text" /> {{ 'Pregunta '+ (pregunta.posicion+1) + ' - ' + pregunta.titulo }}
          </div>

          <div
            v-if="pregunta.tipo == 'alternativa' || pregunta.tipo == 'puntaje'"
            class="graficos-box"
          >
            <div style="flex-grow: 1;">
              <component
                :is="selected_chart"
                :key="refresherGrafico"
                :data="preguntas[pregunta.posicion].chartData"
                :options="chartOptions"
                style="width:70%;"
              />
            </div>
            <div style="flex-grow: 1;">
              <div style="display: flex; flex-direction:row; width: 100%;">
                <div
                  class="cardContainer"
                  style="width:60%;"
                >
                  <div
                    v-for="(kpi, kpi_index) in preguntas[pregunta.posicion].kpis"
                    :key="kpi_index"
                    style="background: rgba(0, 116, 217, 0.85); width: 100%;"
                    class="cardS"
                  >
                    <div class="cardSText">
                      {{ kpi.label }}<h2>{{ kpi.value }}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <table
            v-if="pregunta.tipo == 'texto'"
            style="width:90%; padding: 10px;"
          >
            <tr style="height:40px; background-color:black; color:white;">
              <td>Respuestas</td>
            </tr>
            <template v-for="(respuesta, respuesta_id) in preguntas[pregunta.posicion].respuestas">
              <tr
                v-if="pregunta.tipo == 'texto' && respuesta != null"
                :key="respuesta_id"
                style="height:40px; background-color:white;"
              >
                <td style="text-align:left; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width:400px;">
                  {{ respuesta }}
                </td>
              </tr>
            </template>

            <tr style="height:20px; background-color:black; color:white;">
              <td style="text-align:center;" />
              <td style="text-align:center;" />
              <td style="text-align:center;" />
              <td style="text-align:center; border-right:1px solid black;" />
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import LineChart from "../LineChart.vue";
import PieChart from "../PieChart.vue";
import BarChart from "../BarChart.vue";

export default {
  name: "FormularioGrafico",
  components: {
    LineChart,
    PieChart,
    BarChart,
  },
  props: ['encuestas', 'profesionales'],
  data() {
    return {
      arrayFalso: [1,2,3,4],
      loaded: false,
      refresherGrafico: false,
      grafico_loaded: false,

      data: {
        filtro_encuestas: 'get_all',
        id_encuesta: 'get_all',
        
        filtro_profesional: 'get_all',
        id_profesional: 'get_all',

        solicitud: 'puntajes_correos',

        fecha: {
          desde:{d:0, m:0, a:0},
          hasta:{d:0, m:0, a:0},
        },

      },

      fecha:{inicio:'', fin:''},
      desde: '',
      hasta: '',
      texto_numero: "Esperando...",
      valor_numero: 0,

      encuesta: {},
      preguntas: {},
      mostrar_encuesta: false,

      selected_chart: "bar-chart",

      posibles_solicitudes: [
        {name: 'Puntajes correos', value: 'puntajes_correos'},
        {name: 'Estadísticas encuestas', value: 'estadisticas_encuesta'},
      ],

      posible_graficos: [
        {name:'Gráfico de barra', value: 'bar-chart'},
        {name:'Gráfico de torta', value: 'pie-chart'},
        {name:'Gráfico de línea', value: 'line-chart'},
      ],


      chartOptions: {
        responsive:true,
        hoverBorderWidth: 20,
        title: { display: false, text: 'Actividad en la próxima' },
        pieceLabel: { mode: 'value' },
        legend: { display:false, position:'right' },
      },

      chartData: {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: [],
        datasets: [
          {
            label: "Data One",
            backgroundColor: [
              'rgba(255, 99, 132, 0.8)',
              'rgba(255, 159, 64, 0.8)',
              'rgba(255, 205, 86, 0.8)',
              'rgba(75, 192, 192, 0.8)',
              'rgba(54, 162, 235, 0.8)',
              'rgba(153, 102, 255, 0.8)',
              'rgba(201, 203, 207, 0.8)'
            ].reverse(),
            data: []
          }
        ]
      }

    }
  },
  watch:{
    desde(val){
      let data = val.split('-');
      this.data.fecha.desde = {a:Number(data[0]), m:Number(data[1]), d:Number(data[2])};
      console.log(this.data.fecha);
    },
    hasta(val){
      let data = val.split('-');
      this.data.fecha.hasta = {a:Number(data[0]), m:Number(data[1]), d:Number(data[2])};
      console.log(this.data.fecha);
    },
  },
  mounted(){
    this.loaded = true;
    this.initDate();
  },
  methods: {
    round(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    },
    initDate() {
      var hoy = new Date()
      this.hasta = hoy.toISOString().split('T')[0]
      hoy.setDate(hoy.getDate() - 1);
      this.desde = hoy.toISOString().split('T')[0]
    },
    actualizarGrafico() {
      this.mostrar_encuesta = false;
      this.grafico_loaded = false;
      if (this.data.solicitud == 'estadisticas_encuesta') {
        this.obtenerEncuesta().then(() => {this.obtenerDatos();})
      } else {this.obtenerDatos();}
    },

    async obtenerDatos() {
      let data = {
        filtro_encuestas: this.data.filtro_encuestas == 'get_all' ? 'get_all':'get_one',
        filtro_profesional: this.data.filtro_profesional == 'get_all' ? 'get_all':'get_one',
        id_encuesta: this.data.id_encuesta,
        id_profesional: this.data.id_profesional,
        solicitud: this.data.solicitud,
        desde_dia: this.data.fecha.desde.d,
        desde_mes: this.data.fecha.desde.m,
        desde_ano: this.data.fecha.desde.a,
        hasta_dia: this.data.fecha.hasta.d,
        hasta_mes: this.data.fecha.hasta.m,
        hasta_ano: this.data.fecha.hasta.a,
      }
      axios.get( window.hostname+'/encuestas/estadisticas', { params: data } )
      .then((response) => {
        this.actualizarDatosGrafico(response.data);
      })
      .catch((error)=>{
        console.log("Error al cargar...!");
        console.log(error);
        alert(error);
      })
    },

    actualizarDatosGrafico(estadisticas) {
      this.chartData.labels = [];
      this.chartData.datasets[0].label = "Puntaje";
      this.chartData.datasets[0].data = [];
      let valor_total = 0;
      let cantidad_total = 0;
      
      if (this.data.solicitud == 'puntajes_correos') {
        Object.keys(estadisticas.puntajes).forEach((puntaje) => {
          let cantidad = estadisticas.puntajes[puntaje];
          this.chartData.labels.push(`${puntaje}★`);
          this.chartData.datasets[0].data.push(cantidad);
          valor_total += puntaje*cantidad;
          cantidad_total += cantidad;
        })
        this.texto_numero = "Puntaje promedio:"
        this.valor_numero = `${this.round(valor_total/cantidad_total, 2)}★`
        this.refresherGrafico = !this.refresherGrafico;
        this.grafico_loaded = true;
        
      } else if (this.data.solicitud == 'estadisticas_encuesta') {
        this.preguntas = estadisticas.preguntas;
        Object.entries(this.preguntas).forEach((entry) => {
          let [position, pregunta] = entry;
          if (pregunta.tipo == 'puntaje' || pregunta.tipo == 'alternativa') {
            let temp_labels = [];
            let temp_values = [];
            let total_values = 0;
            let kpis = [];
            pregunta.respuestas.forEach((respuesta) => {
              temp_labels.push(respuesta.label);
              temp_values.push(respuesta.value);
              total_values += respuesta.value;
            });
            this.preguntas[position].chartData = {
              hoverBackgroundColor: "red",
              hoverBorderWidth: 10,
              labels: temp_labels,
              datasets: [
                {
                  label: pregunta.tipo == 'puntaje' ? 'Puntaje':'Alternativa',
                  backgroundColor: [
                    'rgba(255, 99, 132, 0.8)',
                    'rgba(255, 159, 64, 0.8)',
                    'rgba(255, 205, 86, 0.8)',
                    'rgba(75, 192, 192, 0.8)',
                    'rgba(54, 162, 235, 0.8)',
                    'rgba(153, 102, 255, 0.8)',
                    'rgba(201, 203, 207, 0.8)'
                  ].reverse(),
                  data: temp_values
                }
              ]
            }
            if (pregunta.tipo == 'puntaje') {
              let final_value = 0;
              temp_values.forEach((value, index) => {
                final_value += value * temp_labels[index];
              })
              final_value = final_value / total_values;
              kpis.push({
                label: `Puntaje promedio`,
                value: `${this.round(final_value, 2)} ★`,
              });
            }
            temp_values.forEach((value, index) => {
              kpis.push({
                label: `${temp_labels[index]}★`,
                value: `${this.round(value/total_values, 3)*100}%`,
              });
            })
            this.preguntas[position].kpis = kpis
          }
        });
      }
    },

    async obtenerEncuesta() {
      axios.get(window.hostname+'/encuestas/por_id',
        { params: { id_encuesta: this.data.id_encuesta } }
      )
      .then((response) => {
        this.encuesta = response.data.encuestas[this.data.id_encuesta];
        this.mostrar_encuesta = true;
      })
      .catch((error)=>{
        console.log("Error en obtenerEncuesta()...!");
        console.log(error);
        alert(error);
      })
    },

  }
}
</script>

<style scoped>

  table
  { 
    margin-left: auto;
    margin-right: auto;
  }

  .graficos-box {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
  }

  .cardContainer{
    width:25%;
    display:inline-block;
    padding:10px;
  }

  .data-box{
    margin-bottom:20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color:white;
  }

</style>