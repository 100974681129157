<template>
  <div>
    <h2 style="margin-bottom:40px;">
      Reportes <b-icon icon="bar-chart" />
    </h2>
    <div
      class="container-fluid"
      style="max-width: 1500px; padding:40px; position:relative; background-color:white; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; margin-bottom:80px;"
    >
      <div style="position:absolute; right:0px; top:10px; padding:20px; ">
        Desde <input
          id="x2"
          v-model="dt_dia"
          type="date"
        > Hasta: <input
          id="x2"
          v-model="dt_dia2"
          type="date"
        >
      </div>

      <!-- <div
        style="position:absolute; left:170px; top: 10px; border:2px solid; border-radius:10px; padding:5px 10px; cursor:pointer; color:#1D6F42;"
        @click="descargarCSVPago()"
      >
        Descargar CSV Pagos <b-icon icon="cloud-download" />
      </div> -->
  
      <div style="padding:40px 0px; text-align:left;" />
      <h4 style="">
        Ingresos diarios
      </h4>
      <line-chart
        v-if="ordenes.length != 0"
        :key="key+keyMain"
        :height="430"
        style=""
        :data="graficoLineaSumaPagos"
        :options="optionsLinea"
      />

      <div style="padding:80px 0px;">
        <div class="cardContainer">
          <div
            style="background: rgba(0, 116, 217, 0.85);"
            class="cardS"
          >
            <div class="cardSIconDiv">
              <b-icon
                icon="cash-stack"
                class="cardSIcon"
              />
            </div>
            <div class="cardSText">
              Total Ingresos: <h2>${{ Intl.NumberFormat('de-DE').format(ordenes.filter(z=>{return z.estado != 0}).reduce((a, b)=>{ return (a+b.monto || 0)}, 0)) }}</h2>
            </div>
          </div>
        </div>

        <div class="cardContainer">
          <div
            style="background: rgba(255, 133, 27, 0.85);"
            class="cardS"
          >
            <div class="cardSIconDiv">
              <b-icon
                icon="clipboard"
                class="cardSIcon"
              />
            </div>
            <div class="cardSText">
              Ordenes Totales: <h2>{{ ordenes.length }}</h2>
            </div>
          </div>
        </div>

        <div class="cardContainer">
          <div
            style="background: rgba(57, 204, 204, 0.85);"
            class="cardS"
          >
            <div class="cardSIconDiv">
              <b-icon
                icon="person"
                class="cardSIcon"
              />
            </div>
            <div class="cardSText">
              Pacientes: <h2>{{ ordenes.reduce((a, b)=>{return (a +b.clientes.length || 0)}, 0) }}</h2>
            </div>
          </div>
        </div>

        <div class="cardContainer">
          <div
            style="background: rgba(255, 65, 54, 0.9);"
            class="cardS"
          >
            <div class="cardSIconDiv">
              <b-icon
                icon="file-excel"
                class="cardSIcon"
              />
            </div>
            <div class="cardSText">
              Ordenes Canceladas: <h2>{{ ordenes.filter(x=>{return x.estado == 0}).length }}</h2>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <h3 style="margin-bottom:60px; margin-top:40px;">
        Gráficos por Cantidad #
      </h3>


      <div style="padding-top:0px;">
        <div class="graphContainerTop">
          <div
            class=""
            style="padding: 20px;  position:relative; "
          >
            <h4 style="margin-bottom:10px;">
              # Ordenes Pagadas / No Pagadas
            </h4>
            <pie-chart
              v-if="ordenes.length != 0"
              :key="key"
              :height="300"
              style="transform:translateY(-2px);"
              :data="graficoPagadoNoPagado"
              :options="chartOptions"
            />
            <div
              v-if="ordenes.length == 0"
              style="top: 140px; width:550px;"
            >
              No hay ordenes para el intervalo de tiempo
            </div>
          </div> 
        </div>


                
        <div class="graphContainerTop">
          <div
            class=""
            style="padding: 20px;  position:relative; "
          >
            <h4 style="margin-bottom:10px;">
              # Ordenes por Estados
            </h4>
            <pie-chart
              v-if="ordenes.length != 0"
              :key="key"
              :height="300"
              style="transform:translateY(-2px);"
              :data="graficoEstados"
              :options="chartOptions"
            />
            <div
              v-if="ordenes.length == 0"
              style="top: 140px; width:550px;"
            >
              No hay ordenes para el intervalo de tiempo
            </div>
          </div> 
        </div>

        <div class="graphContainerTop">
          <div
            class=""
            style="padding: 20px;  position:relative; "
          >
            <h4 style="margin-bottom:10px;">
              # Examenes por Categoría
            </h4>
            <pie-chart
              v-if="ordenes.length != 0"
              :key="key"
              :height="300"
              style="transform:translateY(-2px);"
              :data="graficoCategorias"
              :options="chartOptions"
            />
            <div
              v-if="ordenes.length == 0"
              style="top: 140px; width:550px;"
            >
              No hay ordenes para el intervalo de tiempo
            </div>
          </div> 
        </div>
      </div>

      <hr>


      <h3 style="margin-bottom:60px; margin-top:40px;">
        Gráficos por Venta $
      </h3>
      <div>
        <div class="graphContainerTop">
          <div
            class=""
            style="padding: 20px;  position:relative; "
          >
            <h4 style="margin-bottom:10px;">
              Venta Ordenes Pagadas / No Pagadas
            </h4>
            <pie-chart
              v-if="ordenes.length != 0"
              :key="key"
              :height="300"
              style="transform:translateY(-2px);"
              :data="graficoPagadoNoPagadoVenta"
              :options="chartOptions"
            />
            <div
              v-if="ordenes.length == 0"
              style="top: 140px; width:550px;"
            >
              No hay ordenes para el intervalo de tiempo
            </div>
          </div> 
        </div>
        <div class="graphContainerTop">
          <div
            class=""
            style="padding: 20px;  position:relative; "
          >
            <h4 style="margin-bottom:10px;">
              Ventas por Comuna
            </h4>
            <pie-chart
              v-if="ordenes.length != 0"
              :key="key"
              :height="300"
              style="transform:translateY(-2px);"
              :data="graficoComunas"
              :options="chartOptions"
            />
            <div
              v-if="ordenes.length == 0"
              style="top: 140px; width:550px;"
            >
              No hay ordenes para el intervalo de tiempo
            </div>
          </div> 
        </div>
                
        <div class="graphContainerTop">
          <div
            class=""
            style="padding: 20px;  position:relative; "
          >
            <h4 style="margin-bottom:10px;">
              Ventas por Categoría
            </h4>
            <pie-chart
              v-if="ordenes.length != 0"
              :key="key"
              :height="300"
              style="transform:translateY(-2px);"
              :data="graficoCategoriasVenta"
              :options="chartOptions"
            />
            <div
              v-if="ordenes.length == 0"
              style="top: 140px; width:550px;"
            >
              No hay ordenes para el intervalo de tiempo
            </div>
          </div> 
        </div>
      </div>

      <hr>

      <div
        class="graphContainerTop"
        style="width:100%;"
      >
        <div style="padding: 20px; position:relative;">
          <graficos-encuestas />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';
import LineChart from "./LineChart.vue";
import PieChart from "./PieChart.vue";
import BarChart from "./BarChart.vue";
import GraficosDinamicos from "./encuestas/GraficosDinamicos.vue";

export default {
  name: 'Reportes',
  components:{
    LineChart,
    PieChart,
    // BarChart,
    'graficos-encuestas': GraficosDinamicos,
  },
  data() {
    return {
      email:'',
      nombre:'',
      apellido:'',
      rut:'',

      dt_dia:'',
      dt_dia2:'',

      desde:{d:0, m:0, a:0},
      hasta:{d:0, m:0, a:0},

      ordenes:[],
      ordenes_calculated:[],
      id_orden:0,

      popup: false,
      mouseX:0,
      mouseY:0,

      resultado:'',
      estados : ['Anulado', 'Citado',  'Confirmado', 'Atendido', 'No Atendido'],

      key:0,
      keyMain:0,
      
      chartOptions: {
        responsive:true,
        hoverBorderWidth: 20,
        title: {
          display: false,
          text: 'Actividad en la próxima'
        },
                pieceLabel: {
            mode: 'value'
        },
        legend:{display:true, position:'right'},

      },


      optionsLinea: {
            maintainAspectRatio: false,
            height: 70,
            responsive: true,
            title:{
                display: false,
                text: "Chart.js Time Scale"
            },
            scales:{
                xAxes: [{
                    type: "time",
                    time: {unit: 'day', displayFormats: {day: 'MMM DD' }
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Día'
                }
                }],
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                  },
                    scaleLabel: {
                        display:     true,
                        labelString: 'Monto'
                    }
                }]
            }
      },

      

    }
  },
  methods:{
    ...mapActions(['loginAdmin']),

    groupBy (xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },

    cambiarEstado(e){
        axios.post(window.hostname+'/api/admin/cambiarEstado', 
        {orden_id:this.id_orden, estado_id:e})
        .then(response => 
        {
          this.get_ordenes()
          this.popup = false;
        })

        .catch(e=>{
          if (e.response.data.message)
            this.resultado = e.response.data.message           
        })

    },

    descargarCSVPago(){
        axios({
          url: window.hostname+'/api/admin/get_reporte',
          method: 'POST',
          responseType: 'blob',
          data: {
            dia:this.desde.d, mes:this.desde.m, año:this.desde.a,
            dia2:this.hasta.d, mes2:this.hasta.m, año2:this.hasta.a
          }
        })
        .then(response =>{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'exportar_pago.csv'); 
            document.body.appendChild(link);
            link.click();          
        })
        .catch( (e) =>{
          console.log(e)
        })

    },

    marcar_pagado(e){
        axios.post(window.hostname+'/api/admin/marcar_pagado', 
        {orden_id:this.id_orden})
        .then(response => 
        {
          this.get_ordenes()
          this.popup = false;
        })

        .catch(e=>{
          if (e.response.data.message)
            this.resultado = e.response.data.message           
        })

    },
    timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      return (rhours < 10 ? '0':'')+rhours + ":"+ (rminutes < 10 ? '0':'') + rminutes + "";
    },
      mouseEnter(event, order_id) {

          this.popup = true;
          this.id_orden = order_id
          //this.$el.addEventListener('mousemove', this.mouseMove, false);
          this.mouseX = event.clientX;
          this.mouseY = event.clientY;
      },
      mouseLeave(event) {
        if (!(event.clientX >= this.mouseX && event.clientY >= this.mouseY))
        {

          this.popup = false;
        }

      },
    close (e){
      /*Si clickeo (e.target) fuera de este componente (this.$el), se cierra*/
      if (!(e.target.classList.contains('dontClose')))
        this.showinFuncionesList = false
    },
    get_ordenes(){      
      if (this.desde.a != 0 && this.hasta.a != 0)
      {
        axios.post(window.hostname+'/api/admin/get_reporte', 
        {dia:this.desde.d, mes:this.desde.m, año:this.desde.a,
         dia2:this.hasta.d, mes2:this.hasta.m, año2:this.hasta.a})
        .then(response => 
        {
          this.ordenes = response.data.ordenes
          this.ordenes = this.ordenes.filter(orden => orden.orden_propia == true)
          function compare(a, b) {
            let da = new Date(a.fecha.a, a.fecha.m, a.fecha.d)
            let db = new Date(b.fecha.a, b.fecha.m, b.fecha.d)
            if ((a.urgente && a.estado == 1)  && !(b.urgente && b.estado == 1)){
              return -1                
            }
            if (!(a.urgente && a.estado == 1) && (b.urgente && b.estado == 1)){
              return 1
            }
            if(da > db){
              return 1
            }
            if (da < db){       
              return -1
            }
            return 0

          }
          this.ordenes_calculated = []
          var ins = {}
          var hoy = new Date();
          var dia = new Date();
          for (var i = 0; i < this.ordenes.length; i++){
            ins = this.ordenes[i]
            dia = new Date(ins.fecha.a, ins.fecha.m-1, ins.fecha.d)
            if ((((dia.getTime()+(1000 * 60 * ins.desde))-hoy.getTime())/(1000 * 60 * 60)) < 2*24)
              ins.urgente = true;
            else
              ins.urgente = false;
            this.ordenes_calculated.push(ins)
          }
          this.ordenes_calculated.sort(compare)
        })
        .catch(e=>{
          console.log(e)        
        })
    }
    }

  },
  computed:{
    ...mapGetters(['comunas', 'regiones', 'categorias', 'prestaciones', 'tipos', 'admin_datos', 'examenes']),

      graficoComunas(){
      var ret = this.groupBy(this.ordenes, 'comuna_id')

      let keys = Object.keys(ret)
      var labs = []
      var data = []
      for(var k = 0; k< keys.length; k++){
        labs.push(this.comunas.filter(x=>{return x.id == keys[k]})[0].Nombre)
        data.push(ret[keys[k]].reduce((a, b)=>{return (a+b.monto || 0) }, 0))
      }
    
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labs,
        datasets: [
          {
            label: "Data One",
                        backgroundColor: [
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(255, 205, 86, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(201, 203, 207, 0.8)'
    ].reverse(),
            data: data
          }
        ]
      }

      return graph;
    },
      graficoCategoriasVenta(){
      var labels = []
      var data = []
      for(var i = 0; i < this.categorias.length; i++){
        data.push(
          this.ordenes.reduce((a, b)=>{
                return (a+b.examenes.filter(z=>{
                  return this.examenes.filter(m=>{return m.id == z.examen_id}).length > 0 ? (this.examenes.filter(m=>{return m.id == z.examen_id})[0].categoria_id == this.categorias[i].id) : false}).reduce((c, d)=>{return c+d.precio || 0}, 0) || 0) }, 0))
        labels.push(this.categorias[i].nombre)
      }
    
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labels,
        datasets: [
          {
            label: "Data One",
                        backgroundColor: [
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(255, 205, 86, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(201, 203, 207, 0.8)'
    ].reverse(),
            data: data
          }
        ]
      }

      return graph;
    },
      graficoCategorias(){
      var labels = []
      var data = []
      for(var i = 0; i < this.categorias.length; i++){
        data.push(this.ordenes.reduce((a, b)=>{return (a+b.examenes.filter(z=>{return this.examenes.filter(m=>{return m.id == z.examen_id}).length > 0 ? this.examenes.filter(m=>{return m.id == z.examen_id})[0].categoria_id == this.categorias[i].id : false}).length || 0) }, 0))
        labels.push(this.categorias[i].nombre)
      }
    
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labels,
        datasets: [
          {
            label: "Data One",
                        backgroundColor: [
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(255, 205, 86, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(201, 203, 207, 0.8)'
    ].reverse(),
            data: data
          }
        ]
      }

      return graph;
    },
      graficoEstados(){
      let nCanceladas = this.ordenes.filter(x=>{return x.pagado == 0}).length
      let nAgendadas = this.ordenes.filter(x=>{return x.pagado == 1}).length

      let labels = this.estados
      var data = []
      for(var i = 0; i < this.estados.length; i++){
        data.push(this.ordenes.filter(x=>{return x.estado == i}).length)

      }          
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labels,
        datasets: [
          {
            label: "Data One",
                        backgroundColor: [
      'rgba(255, 99, 132, 0.8)',
      'rgba(255, 159, 64, 0.8)',
      'rgba(255, 205, 86, 0.8)',
      'rgba(75, 192, 192, 0.8)',
      'rgba(54, 162, 235, 0.8)',
      'rgba(153, 102, 255, 0.8)',
      'rgba(201, 203, 207, 0.8)'
    ],
            data: data
          }
        ]
      }

      return graph;
    },
    
      graficoPagadoNoPagadoVenta(){
      let nCanceladas = this.ordenes.filter(x=>{return x.pagado == 0}).reduce((a,b) =>{return a+b.monto || 0 }, 0)
      let nAgendadas = this.ordenes.filter(x=>{return x.pagado == 1}).reduce((a,b) =>{return a+b.monto || 0 }, 0)

      let labels = ['Pagadas', 'No Pagadas']


          
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labels,
        datasets: [
          {
            label: "Data One",
            backgroundColor: ["#3D9970", "#FF4136"],
            data: [nAgendadas, nCanceladas]
          }
        ]
      }

      return graph;
    },
      graficoPagadoNoPagado(){
      let nCanceladas = this.ordenes.filter(x=>{return x.pagado == 0}).length
      let nAgendadas = this.ordenes.filter(x=>{return x.pagado == 1}).length

      let labels = ['Pagadas', 'No Pagadas']


          
     
      let graph = {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: labels,
        datasets: [
          {
            label: "Data One",
            backgroundColor: ["#3D9970", "#FF4136"],
            data: [nAgendadas, nCanceladas]
          }
        ]
      }

      return graph;
    },
    
    graficoLineaSumaPagos(){

      var ret = this.groupBy(this.ordenes, 'fecha_dt')
      var ret_fecha_creado = this.groupBy(this.ordenes, 'fecha_creado_dt')
      console.log('ret_fecha_creado')
      console.log(ret_fecha_creado)
      let labels1 = []
      let labels2 = []
      let ventasProgramadasTotal = []
      let fecha;
      let desde_ = new Date(this.desde.a, this.desde.m-1, this.desde.d)
      let hasta_ = new Date(this.hasta.a, this.hasta.m-1, this.hasta.d)
      for(var itDia = new Date(desde_.getFullYear(), desde_.getMonth(), desde_.getDate()); itDia <= hasta_; itDia.setDate(itDia.getDate() + 1)){
        fecha = itDia.toLocaleDateString("en-US", {year:"numeric", month:'2-digit', day:'2-digit'})
        if (ret.hasOwnProperty(fecha)) {
          labels1.push({x:fecha, y:ret[fecha].filter(x=>{return x.estado != 0}).reduce((a, b)=>{return  (a+b.monto || 0) }, 0)})
          labels2.push({x:fecha, y:ret[fecha].filter(x=>{return x.pagado == 1 && x.estado != 0}).reduce((a, b)=>{return  (a+b.monto || 0) }, 0)})
        } else {
          labels1.push({x:fecha, y:0})
          labels2.push({x:fecha, y:0})
        }
        if (ret_fecha_creado.hasOwnProperty(fecha)) {
          ventasProgramadasTotal.push({x:fecha, y:ret_fecha_creado[fecha].reduce((a, b)=>{return  (a+b.monto || 0) }, 0)})
        } else {
          ventasProgramadasTotal.push({x:fecha, y:0})
        }
      }
      let graph = {          
        datasets: [
          {
            label: "Ingreso diario total", 
            data: labels1,
            fill: false,
            borderColor: 'rgba(255, 10, 13, 0.5)',
            backgroundColor: "rgba(255, 10, 13, 0.2)"
          },
          {
            label: "Ingreso diario cobrado", 
            data: labels2,
            fill: false,
            backgroundColor: 'rgba(46, 204, 64, 0.2)',
            borderColor: 'rgba(46, 204, 64, 0.5)'
          },
          {
            label: "Ventas programadas", 
            data: ventasProgramadasTotal,
            fill: false,
            backgroundColor: 'rgba(246, 237, 116, 0.2)',
            borderColor: 'rgba(246, 237, 116, 0.5)'
          }
        ]
      }

      return graph;
    },

  },
  watch:{
    dt_dia(val){

        var datos = val.split('-')
        this.desde = {a:Number(datos[0]), m:Number(datos[1]), d:Number(datos[2])}
        this.get_ordenes()
      
    },
    dt_dia2(val){

        var datos = val.split('-')
        this.hasta = {a:Number(datos[0]), m:Number(datos[1]), d:Number(datos[2])}
        this.get_ordenes()
      
    },
    ordenes(){
      this.key +=1; 
    }

  },
  mounted() {
    document.addEventListener('click', this.close)   
    var hoy = new Date()
    this.dt_dia = hoy.toISOString().split('T')[0]
    hoy.setDate(hoy.getDate() + 7);
    this.dt_dia2 = hoy.toISOString().split('T')[0]
  },
  beforeUnmount () {
    document.removeEventListener('click',this.close)
  }

}
</script>


<style scoped>
.cardSIconDiv{
  position:absolute;
  top:43px;
  right:20px;
}
.cardSIcon{
  height:35px;
  width:35px;
}
.cardContainer{
  width:25%;
  display:inline-block;
  padding:10px;
}
.cardSText{
  padding-top:10px;
  line-height: 35px;
}
.cardS{
  border-radius: 5px;
  color:#fff;
  padding:20px;
  height:120px;
  position:relative
}
.graphContainerTop{
padding-left:10px; padding-right:10px; padding-bottom:20px; width:33%;  display:inline-block; 
}
.graphContainerMain{
padding-left:10px; padding-right:10px; padding-bottom:20px; width:100%;  display:inline-block; 
}

td{
  padding: 5px 5px 0px;
  text-align: left;
}
/*
 tr:nth-child(odd){
  background-color: rgba(1, 98, 153, 0.8);
  color: #fff;
}
*/
table
{ 
    margin-left: auto;
    margin-right: auto;
}

.shadow-object{
  background-color: white;
  /*margin: 10px 0px 10px 10px;*/
  border-radius: 5px;
  height:auto;
  box-shadow: rgba(0, 0, 0, 0.28) 0px 3px 8px;
  padding:2px;
}

</style>

