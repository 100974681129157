<template>
  <div style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; background-color:white; width:600px; margin:0 auto; padding:40px 0px 60px 0px; border-radius:40px;">
    <img
      class="wave"
      :src="$ENV.getAsset('curva.svg')"
    >

    <div :style="`background-color:${$ENV.primaryColor}; width:80px; height:80px; margin: 0 auto; border-radius:50px;`">
      <b-icon
        style="height:60px; width:60px; margin-top:8px; color:white;"
        icon="person"
      />
    </div>
    <h2 style="margin-top:30px;">
      Iniciar sesión
    </h2>

    <hr>
    <div class="form">
      <div class="username">
        <div class="insider">
          <div class="iconwrapper">
            <div class="icon-center">
              <svg
                class="svg-icon"
                viewBox="0 0 20 20"
              >
                <path d="M12.075,10.812c1.358-0.853,2.242-2.507,2.242-4.037c0-2.181-1.795-4.618-4.198-4.618S5.921,4.594,5.921,6.775c0,1.53,0.884,3.185,2.242,4.037c-3.222,0.865-5.6,3.807-5.6,7.298c0,0.23,0.189,0.42,0.42,0.42h14.273c0.23,0,0.42-0.189,0.42-0.42C17.676,14.619,15.297,11.677,12.075,10.812 M6.761,6.775c0-2.162,1.773-3.778,3.358-3.778s3.359,1.616,3.359,3.778c0,2.162-1.774,3.778-3.359,3.778S6.761,8.937,6.761,6.775 M3.415,17.69c0.218-3.51,3.142-6.297,6.704-6.297c3.562,0,6.486,2.787,6.705,6.297H3.415z" />
              </svg>
            </div>
       
            <input
              v-model="login_correo_electronico"
              type="text"
              placeholder="Email"
              class="laforma1"
              autocomplete="off"
            >
          </div>
        </div>
      </div>
      <div class="password">
        <div class="insider">
          <div class="iconwrapper">
            <div class="icon-center">
              <svg
                class="svg-icon"
                style=""
                viewBox="0 0 20 20"
              >
                <path d="M17.308,7.564h-1.993c0-2.929-2.385-5.314-5.314-5.314S4.686,4.635,4.686,7.564H2.693c-0.244,0-0.443,0.2-0.443,0.443v9.3c0,0.243,0.199,0.442,0.443,0.442h14.615c0.243,0,0.442-0.199,0.442-0.442v-9.3C17.75,7.764,17.551,7.564,17.308,7.564 M10,3.136c2.442,0,4.43,1.986,4.43,4.428H5.571C5.571,5.122,7.558,3.136,10,3.136 M16.865,16.864H3.136V8.45h13.729V16.864z M10,10.664c-0.854,0-1.55,0.696-1.55,1.551c0,0.699,0.467,1.292,1.107,1.485v0.95c0,0.243,0.2,0.442,0.443,0.442s0.443-0.199,0.443-0.442V13.7c0.64-0.193,1.106-0.786,1.106-1.485C11.55,11.36,10.854,10.664,10,10.664 M10,12.878c-0.366,0-0.664-0.298-0.664-0.663c0-0.366,0.298-0.665,0.664-0.665c0.365,0,0.664,0.299,0.664,0.665C10.664,12.58,10.365,12.878,10,12.878" />
              </svg>
            </div>
            <input
              v-model="login_password"
              type="password"
              class="laforma2"
              placeholder="Contraseña"
            >
          </div>
        </div>
      </div>

      <div class="opciones">
        <span class="errormsg">{{ error_msg_login }}</span>
      </div>
      <div class="entrarbtn">
        <button
          class="signin-btn"
          :style="`background-image: linear-gradient(to right, ${$ENV.primaryColor}, ${$ENV.secondaryColor}, ${$ENV.secondaryColor});`"
          @click="entrar()"
        >
          Entrar  <div
            v-if="cargando"
            style="display:inline-block;"
          >
            <div style="position:aboluste; transform:translate(6px, -3px);">
              <div
                class="spinner-border spinner-border-sm"
                role="status"
              />
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapActions} from 'vuex';

export default {
  name: 'HelloWorld',
  data() {
    return {
      login_correo_electronico:'',
      login_password:'',
      cargando:false,
      error_msg_login:'',
    }
  },
  created(){
    if (this.admin_datos.length != 0){
      this.$router.push('/Admin')
    }
  },
  computed: {
  ...mapGetters(['admin_datos', 'isLoggedIn']),
  },
  methods:{
    ...mapActions(['loginAdmin']),
      entrar(){
       this.cargando = true;
       this.loginAdmin({email: this.login_correo_electronico, password:this.login_password})
       .then(resp=>{
         this.cargando = false;
         this.$router.push('/Admin')
       })
       .catch(err=>{
         this.cargando = false;
         this.error_msg_login = err.response.data

       })
     },

  },

}
</script>


<style scoped>
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css);
.wave {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}
.errormsg{
  display: block;
  color:red;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.formu select {
  width: 130px;
  padding: 12px 7px;
  margin: 0 10px 0 0px;
  box-sizing: border-box;
  border-radius: 20px;
  border:1px solid rgba(0,0,0,0.2);
  outline: none;
  color:#111111;
}
.formu input[type=text] {
  width: 90%;
  padding: 12px 20px;
  margin: 0 0 14px 0;
  box-sizing: border-box;
  border-radius: 20px;
  border:1px solid rgba(0,0,0,0.2);
  outline: none;
  color:rgba(0,0,0,0.6);
}
.formu input[type=text]:focus, .formu input[type=password]:focus {
  width: 90%;
  padding: 12px 20px;
  margin: 0 0 14px 0;
  box-sizing: border-box;
  border-radius: 20px;
  border:2px solid #00B4CC;
  outline: none;
}
.formu input[type=password] {
  width: 90%;
  padding: 12px 20px;
  margin: 0 0 14px 0;
  box-sizing: border-box;
  border-radius: 20px;
  border:1px solid rgba(0,0,0,0.2);
  outline: none;
}
.formu label {
  display: block;
}
.formu{
  text-align: left;
  margin-left:20px;
  max-height: 100vh;
  overflow: scroll;
  overflow-x: hidden;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #00B4CC; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;  
}

.loader-wrapper{
  position: absolute;
  margin: auto;
  width: 50%;
  padding: 10px;
  top: 40%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.linky{
  color:#00B4CC;
  cursor: pointer;
}
.linky:hover{
  text-decoration: underline;
}

.spacing{
    height:60px;
}

.form{
  
  margin-top:30px;
}
.logotop{
  max-height:90px;
   margin-top:120px;
}
.form input{
  width: calc(100% - 55px);
  color:#111111;
  font-size:18px;
  padding: 10px 20px 10px 5px;
  border:none;
  outline: none;
  background:none;

}
button.signin-btn{
  width: 400px;
  padding: 12px 10px;
  border: none;
  font-size: 18px;
  border-radius: 30px;
  background-image: linear-gradient(to right, rgb(20, 134, 231), rgb(105, 191, 255), rgb(105, 191, 255));
  background-size: 200%;
  color: #ffffff;
  margin-bottom: 5px;
  outline:none;
  transition: 100ms;
}
.signin-btn:hover{
  background-position: right;
}

button.clean-btn{
  width: 400px;
  padding: 12px 10px;
  border: none;
  font-size: 18px;
  border-radius: 30px 0px 0px 30px;
  margin-right:10px;
  background-color: #111111;
  color: #ffffff;
  margin-bottom: 5px;
  outline:none;
  transition: 100ms;
}
.clean-btn:hover{
  background-color: rgba(17, 17, 17, 0.8);
}

button.register-btn{
  width: 400px;
  padding: 12px 10px;
  border: none;
  font-size: 18px;
  border-radius: 30px 30px 30px 30px;
  background-color: rgb(1, 98, 153);
  color: #ffffff;
  margin-bottom: 5px;
  outline:none;
  transition: 100ms;
}
.register-btn:hover{
  background-color: rgb(1, 98, 153);
}

button.notregister-btn{
  width: 400px;
  padding: 12px 10px;
  border: none;
  font-size: 18px;
  border-radius: 30px 30px 30px 30px;
  background-color: #FF4136;
  color: #ffffff;
  margin-bottom: 5px;
  outline:none;
  transition: 100ms;
}
.iconwrapper{
  width: calc(100% - 25px);
  display:inline-block;
  text-align: left;
  vertical-align: middle;
  line-height: 30px;

}

.username, .password {
  
  height:80px;
  display:block;
  border-radius: 30px;
  border: 1px solid rgba(0,0,0,0.2);
  padding:15px;
  margin: 20px auto;
  width:400px;
}
.username:focus-within, .password:focus-within{
  border: 1px solid rgb(1, 98, 153, 0.8);
}
.form svg{
  height: 20px;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 5px;
 
}

.opciones{
  margin: 0 auto;
  text-align: center;
  margin-bottom:30px;
}

path{
    stroke: white;
    stroke-width: 0.5px;
    fill:#111111;
}

.conta {
    position: relative;
    height:100vh;
}

.left {
    width: 500px;
    position: absolute;
    top: 0;
    left: 0px;
    height:100vh;
    background-color: white;
    
}

.right {
    margin-left: 500px;
    height:100vh;
}

.icon-center{
  display:inline-block; vertical-align: middle; height:35px;
}
@media only screen and (max-width: 765px) {

.icon-center{
  display:inline; 
}
.left{
  width:100%;
}
.username, .password{
  width:90%;
}
.form input{
  width: calc(100% - 55px);
  color:#111111;
  font-size:18px;
  padding: 10px 20px 10px 5px;
  border:none;
  outline: none;
  background:none;

}
.entrarbtn{
  
  padding: 0 40px 0 40px;
}
.logotop{
   margin-top:70px;
}

button.signin-btn{
  width: 100%;
  padding: 12px 10px;
  border: none;
  font-size: 18px;
  border-radius: 30px;
  background-image: linear-gradient(to right, rgb(20, 134, 231), rgb(105, 191, 255), rgb(105, 191, 255));
  color: #ffffff;
  margin-bottom: 5px;
  outline:none;
}

.logo{
  margin-left:0px;
  margin-top:20px;
  float:left;
}
.desktop{
  display:none;
  visibility: hidden;
}

.logoabajo{
  display:none;
}
}
@media only screen and (min-width: 1001px) {
.mobile{
  display:none;
  max-height:100px;
}
}


</style>

